<template>
  <div class="about-part3-wrap content-width middle about-main">
    <h2>
      <span>专家团队</span>
    </h2>
    <ul class="zoom">
      <li
        class="left"
        style="width: 300px"
        v-for="(item, index) in data"
        :key="index"
      >
        <div class="expert-img">
          <img :src="item.iconc | imgSrc" />
        </div>
        <div class="expert-name">{{ item.yjbt }}</div>
        <div class="expert-description">{{ item.jj }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { functionData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
import weightSort from "../../../utils/weightSort";
export default {
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    async getFunction() {
      const res = await functionData({
        functionId: "42e6d6e2e2ef4d9e922d81a2f2efef3a",
        current: 1,
        pageSize: 999,
        organizationId: config.funOrganizationId,
      });
      if (res.state == 200) {
        this.data = res.results.list.sort((a, b) => {
          return parseInt(b.qzz) - parseInt(a.qzz); //根据权重进行排序
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
//
.about-part3-wrap {
  padding-top: 110px;
  h2 {
    width: 1200px;
    display: block;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 64px;
    font-size: 100%;
    font-weight: 500;
    width: 100%;
    span {
      font-size: 32px;
      display: inline-block;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;
    li {
      height: 450px;
      margin-bottom: 44px;
      margin-right: 126px;
      // color: @font-color;
      vertical-align: top;
      text-align: center;
      width: 300px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .expert-img {
        width: 240px;
        height: 240px;
        margin: 0 30px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .expert-name {
        font-size: 20px;
        font-weight: bold;
        padding-top: 40px;
        padding-bottom: 24px;
        margin: 0 auto;
        display: inline-block;
      }
      .expert-description {
        width: 76%;
        margin: 0 auto;
        text-align: left;
        line-height: 1.5;
      }
      & > div {
        font-size: 15px;
      }
    }
  }
}
</style>
