const db = {};
const ajax = require('../../utils/ajax');
const api = require('../../api');


//获取所有行业
exports.getallIndustry = async () => {
  if (!db.industrys) {
    const { res } = await ajax.get(api.industry.GET_ALL_INDUSTRY, { current: 1, pageSize: 9999 });
    db.industrys = res.results.list;
  }
  return db.industrys;
  
};