<template>
    <div class="stepStyle">
      <el-steps :active="active" align-center direction="vertical">
        <el-step title="企业信息认证" :icon="active > 0 ? 'el-icon-circle-check' : ''"></el-step>
        <el-step title="企业信息核验" :icon="active > 1 ? 'el-icon-circle-check' : ''"></el-step>
        <el-step title="对公打款认证" :icon="active > 2 ? 'el-icon-circle-check' : ''"></el-step>
        <el-step title="完成" :icon="active > 3 ? 'el-icon-circle-check' : ''"></el-step>
      </el-steps>
    </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props:["active"],
  mounted(){
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.stepStyle{
  height: 200px;
}
</style>
