<template>
  <div class="product-platform">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>产品的超级身份证-六码合一</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      产品身份证监管平台全行业、全场景产品应用数字化赋能，为每个产品赋予独一无二的“数字孪生”身份，
      通过多个系列子平台，横向覆盖一物一码应用全场景，全方位赋能企业产线自动化、品牌管理、包装印刷、
      互动营销、打假维权、企业舆情、产品评价等；纵向打通企业产品全流程品质供应链，提升管理效率、支持智能生产、
      提供新零售与精准数据营销支持，最终解决企业在信息管理过程中重复建设、低水平建设的问题，大力降低企业数据管理操
      作成本，以产品为核心，以数据为驱动，打通企业全生命周期、产业链闭环数字化应用通路，助力企业数字化发展。
    </div>
    <div class="provideCont">
      <div class="provideCont-top">
        <h1>为品牌企业提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">从商品生产到商品流通全流程数字化管理流程</div>
      </div>
      <img src="static/images/product/plat1.png" />
    </div>
    <div class="modelMain">
      <div class="provideContNew">
        <div class="provideCont-top">
          <h1>为品牌企业提供</h1>
          <img src="static/images/product/blueBg.png" class="blueBg" />
          <div class="topText">
            防伪、溯源、防窜、营销、电子质保、一键维权等多码合一服务
          </div>
        </div>
        <img src="static/images/product/plat2.png" class="sixModel" />
        <img src="static/images/product/plat3.png" />
      </div>
    </div>
    <div class="tree">
      <img src="static/images/product/plat4.png" />
    </div>
    <div class="carShow">
      <div class="carShow-top">
        <h1>可视化驾驶舱展示</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <div><img src="static/images/product/plat5.png" class="platImg" /></div>
      <div><img src="static/images/product/plat6.png" class="platImg" /></div>
      <div><img src="static/images/product/plat7.png" class="platImg" /></div>
      <div><img src="static/images/product/plat8.png" class="platImg" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less"></style>
