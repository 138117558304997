<template>
  <div class="caseShow-carbon">
    <div class="caseSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>基于产品一物一码标识结合碳汇应用的碳标识</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      碳标签(Carbon Labelling)是为了缓解气候变化，减少温室气体排放，
      推广低碳排放技术，把商品在生产过程中所排放的温室气体排放量在产品标签上用量化的指数标示出来，
      以标签的形式告知消费者产品的碳信息。
    </div>
    <div class="carbonCont">
      <div class="carbonCont-top">
        <h1>项目介绍</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          2021年10月国务院发布《2030年前碳达峰行动方案》，为深入贯彻落实党中央、国务院关于碳达峰、碳中和的重大战略决策，
          扎实推进碳达峰行动，舟山市定海区农业农村局，积极响应国家政策，建立规范的碳排放统计核算体系。
        </div>
      </div>
    </div>
    <div class="carbonCont">
      <div class="carbonCont-top">
        <h1>客户诉求</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          为实现“双碳”目标在农业农村领域的落地，梳理核查农业经营主体在生产中的碳排放总量，量化性体现能源消耗、
          主要碳源等各类信息，有效解决农业主体散化经营，碳排放监管难的现状。
        </div>
      </div>
    </div>
    <div class="carbonCont">
      <div class="carbonCont-top">
        <h1>解决方案</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          建立农业碳管家数字化平台，内容包括农业碳管家PC
          端、农业碳管家浙政钉手机端、农业主体数据直报端系统。
          内置符合舟山的农业碳排放核算标准，包括种植、畜牧、家禽、水产四类行业碳排放因子及碳排放核算标准；
          主体通过数据直报系统自主上传数据，基于收集到的碳排放源数据结合碳排放核算标准算法自动核算碳排放量，
          对主体碳排放数据进行分析分级评价并形成报告，形成整个流程的闭环处理，满足政府对农业经营主体碳排放状况进行大数据监管与运营。
        </div>
      </div>
    </div>
    <div class="threePic">
      <div class="threePic-top">
        <div class="threePic-left">
          <div class="picTitle">浙江省舟山市定海区农业碳管家大数据驾驶舱</div>
          <img src="static/images/caseShow/carbon1.png" />
        </div>
        <div class="threePic-right">
          <div class="picTitle">直报生产信息数据</div>
          <img src="static/images/caseShow/carbon2.png" />
        </div>
      </div>
      <div class="threePic-bot">
        <div class="picTitle">一键生成信息报告</div>
        <img src="static/images/caseShow/carbon3.png" />
      </div>
    </div>
    <div class="bot-carbon">
      <div class="carbonCont">
        <div class="carbonCont-top">
          <h1>碳标签应用</h1>
          <img src="static/images/product/blueBg.png" class="blueBg" />
        </div>
        <img src="static/images/caseShow/carbon4.png" />
      </div>

      <div class="carbonDesc">
        <div class="carbonDesc-only">
          <img src="static/images/product/numLeft.png" />
          <div class="onlyTitle">碳标签标识说明</div>
          <img src="static/images/product/numRight.png" />
        </div>
        <img src="static/images/caseShow/carbon5.png" class="signDesc" />
        <div class="carbonDesc-only">
          <img src="static/images/product/numLeft.png" />
          <div class="onlyTitle">碳标签应用-产品数字身份证</div>
          <img src="static/images/product/numRight.png" />
        </div>
        <img src="static/images/caseShow/carbon6.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@h: 1200px;
.caseShow-carbon {
  h1 {
    position: relative;
    z-index: 9;
  }
  .caseSuper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
    h1 {
      padding: 0 15px;
    }
  }
  .codeText {
    font-size: 18px;
    color: #8e8e8e;
    line-height: 34px;
    text-align: left;
    padding-bottom: 40px;
    width: @h;
    margin: 0 auto;
  }

  .carbonCont {
    text-align: center;
    position: relative;
    width: @h;
    margin: 0 auto;
    h1 {
      padding-bottom: 22px;
    }
    .blueBg {
      position: absolute;
      top: 24px;
      left: 517px;
    }
    .topText {
      color: #666;
      font-size: 18px;
      padding-bottom: 30px;
      text-align: left;
      line-height: 34px;
    }
  }
  .threePic {
    width: @h;
    margin: 0 auto;
    padding: 40px 0;
    .picTitle {
      font-size: 20px;
      padding-bottom: 28px;
    }

    .threePic-top {
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
    .threePic-bot {
      .picTitle {
        padding-left: 150px;
      }
    }
  }
  .bot-carbon {
    background: #fff;
    padding-top: 50px;
    .blueBg {
      position: absolute;
      top: 24px;
      left: 500px;
    }
    .carbonDesc {
      width: @h;
      margin: 0 auto;
      > img {
        padding: 70px 0;
      }
      .carbonDesc-only {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 16px;
        }
        .onlyTitle {
          font-size: 26px;
          padding: 0 15px;
        }
      }
    }
    .signDesc {
      margin-bottom: 80px;
    }
  }
}
</style>
