<template>
  <div class="openCertification">
    <el-form
      :model="form"
      class="demo-form-inline"
      label-position="right"
      label-width="140px"
      :rules="rules"
      ref="form"
    >
      <el-form-item label="开通人姓名" prop="openName">
        <el-input
          :maxlength="6"
          v-model="form.openName"
          placeholder="请输入开通人姓名"
          class="size"
        ></el-input>
      </el-form-item>
      <el-form-item label="开通人手机号" prop="openTel">
        <el-input
          :maxlength="11"
          v-model="form.openTel"
          placeholder="请输入开通人手机号"
          class="size"
        />
      </el-form-item>
      <el-form-item label="验证码" prop="openCode" class="send-code">
        <el-input :maxlength="6" v-model="form.openCode" placeholder="请输入验证码" class="size" />
        <el-button class="send-btn" :disabled="beingSent" @click="sendCodeFunc()">{{
          sendText
        }}</el-button>
      </el-form-item>
      <el-checkbox class="checked-box" v-model="checked">
        同意并遵守
        <a :href="protocolUrl" target="_blank" class="agreement">
          《甲骨文超级码开放平台服务协议》
        </a>
      </el-checkbox>
      <div class="twoBtn">
        <el-button type="primary" :disabled="disabled" :loading="disabled" @click="next('form')">
          完成认证
        </el-button>
        <el-button @click="goBack()">上一步</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { sendCode, userAtpAuth, openStatus, cancelOrder } from "src/static/services/website";
import { skipFunc } from "src/static/utils/webskipUtils.js";
export default {
  data() {
    return {
      checked: false,
      timer: null,
      time: 60,
      sendText: "获取验证码",
      beingSent: false,
      disabled: false,
      form: {
        openName: "",
        openTel: "",
        openCode: "",
      },
      rules: {
        openName: [{ required: true, message: "该项为必填项", trigger: "blur" }],
        openTel: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请填写正确的手机号", trigger: "blur" },
        ],
        openCode: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^.{6}$/, message: "请输入6位验证码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  computed: {
    protocolUrl: function () {
      return "http://mzt315.com/protocol";
      return "agreement.html";
    },
  },
  methods: {
    validItem(formName, itemName) {
      return new Promise((resolve) => {
        this.$refs[formName].validateField(itemName, (valid) => {
          resolve(valid);
        });
      });
    },
    async sendCodeFunc() {
      const valid = await this.validItem("form", "openTel");
      if (valid) {
        return;
      }
      this.beingSent = true;
      this.sendText = "倒计时" + this.time + "s";
      this.timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.beingSent = false;
          this.time = 60;
          this.sendText = "获取验证码";
          clearInterval(this.timer);
        } else {
          this.sendText = "倒计时" + this.time + "s";
        }
      }, 1000);
      let res = await sendCode({ phoneNumber: this.form.openTel });
      if (res.state != 200) {
        this.beingSent = false;
        this.time = 60;
        this.sendText = "获取验证码";
        clearInterval(this.timer);
      }
    },

    async loginFunc() {
      let propsData = {
        userName: this.form.openName,
        code: this.form.openCode,
        phone: this.form.openTel,
      };
      const res = await userAtpAuth(propsData);
      if (res.state == 200) {
        localStorage.setItem('super-token', res.results.token);
        localStorage.setItem('phone', this.form.openTel);
        this.getStatus();
      } else {
        this.disabled = false;
      }
      this.beingSent = false;
      this.time = 60;
      this.sendText = "获取验证码";
      clearInterval(this.timer);
    },
    async getStatus() {
      const res = await openStatus();
      if (res.state == 200) {
        if (res.results.status == "-1") {
          window.location.href = "openContent.html";
        } else if (res.results.status == "1000") {
          ELEMENT.MessageBox.confirm("您的手机号已开通系统，可直接进入系统", {
            closeOnClickModal: false,
            cancelButtonText: "更换手机号",
            confirmButtonText: "进入我的超级码®云",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              this.form.openName = "";
              this.form.openTel = "";
              this.form.openCode = "";
            });
        } else if (res.results.status == "700" || res.results.status == "800") {
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", {
            closeOnClickModal: false,
            cancelButtonText: "重新选择套餐",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              this.cancelOrderFunc(res.results);
            });
        } else if (res.results.status == "999") {
          skipFunc(res.results);
        } else if (res.results.status == "a" || res.results.status == "600") {
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", {
            closeOnClickModal: false,
            confirmButtonText: "继续完成",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {});
        } else if (res.results.status == "900") {
          ELEMENT.MessageBox.confirm("您的系统已过期，是否续费？", {
            closeOnClickModal: false,
            cancelButtonText: "暂时不需要",
            confirmButtonText: "续费",
            showClose: false,
            center: true,
          })
            .then(() => {
              window.location.href = "renewalSystem.html";
            })
            .catch(() => {});
        } else if (res.results.status == "3") {
          ELEMENT.MessageBox.confirm("实名认证流程已冻结,请联系客服人员：18958077257", {
            closeOnClickModal: false,
            confirmButtonText: "我知道啦",
            showCancelButton: false,
            center: true,
          })
            .then(() => {})
            .catch(() => {});
        } else {
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", {
            closeOnClickModal: false,
            cancelButtonText: "重新选择套餐",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              window.location.href = "chooseDeloy.html?type=changePackage";
            });
        }
        this.disabled = false;
      }
    },
    // 取消订单
    async cancelOrderFunc(results) {
      const res = await cancelOrder({ orderNo: results.value });
      if (res.state == 200) {
        window.location.href = "chooseDeloy.html?type=changePackage";
      }
    },
    // 返回上一步
    goBack() {
      window.location.href = "chooseDeloy.html";
    },
    validForm(formInline) {
      return new Promise((resolve) => {
        this.$refs[formInline].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async next(formInline) {
      const valid = await this.validForm(formInline);
      if (!valid) {
        return;
      }
      if (!this.checked) {
        ELEMENT.Message.warning("请阅读并同意《甲骨文超级码开放码平台服务条款》");
        return;
      }
      this.disabled = true;
      if (localStorage.getItem("super-token") && localStorage.getItem("phone")) {
        this.getStatus();
      } else {
        this.loginFunc();
      }
    },
  },
  watch: {
    //监听输入
    form: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        this.form.openTel = val.openTel.replace(/\D/g, "");
        this.form.openCode = val.openCode.replace(/\D/g, "");
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.openCertification {
  padding-top: 30px;
  width: 700px;
  margin: 0 auto;
  font-size: 14px;
  .twoBtn {
    margin-left: 140px;
  }
  .size {
    width: 500px;
  }
  .checked-box {
    margin-top: 14px;
    margin-bottom: 16px;
    margin-left: 140px;
    .agreement {
      text-decoration: none;
      color: #76c0ff;
    }
  }
  .send-code {
    position: relative;
    .send-btn {
      border: none;
      position: absolute;
      background: none;
      right: 76px;
      top: 0;
      padding: 0;
      line-height: 40px;
      cursor: pointer;
      color: #008aff;
      user-select: none;
    }
  }
}
</style>
