<template>
  <div class="about-part2-wrap about-main">
    <div class="about-item1 common-item">
      <div class="content-width middle">
        <div class="item-title">发展历程</div>
        <div class="item-detail">
          <div class="part-box" v-for="(item, index) in data" :key="index">
            <div class="part-img"><img :src="item.iconc | imgSrc" /></div>
            <div class="part-content">
              <span class="part-time">{{ item.yjbt }}</span>
              <span class="part-circle"></span>
              <div v-html="item.nr"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { functionData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
import weightSort from "../../../utils/weightSort";
export default {
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    async getFunction() {
      const res = await functionData({
        functionId: "4d86463ceb9f447caf342411c7a6aaad",
        current: 1,
        pageSize: 999,
        organizationId: config.funOrganizationId,
      });
      if (res.state == 200) {
        this.data = res.results.list.sort((a, b) => {
          return parseInt(b.qzz) - parseInt(a.qzz); //根据权重进行排序
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
//
.about-part2-wrap {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  .common-item {
    .item-title {
      padding: 80px 0 64px;
      font-size: 32px;
      color: #151515;
    }
    .item-detail {
      .part-box {
        width: 100%;
        padding-top: 70px;
        margin-bottom: 70px;
        position: relative;
        overflow: hidden;
        img {
          width: 200px;
          position: absolute;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          top: 50%;
        }

        .part-content {
          width: 50%;
          padding-left: 40px;
          padding-right: 40px;
          padding-bottom: 50px;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          position: relative;
          color: #7f7f7f;
          box-sizing: border-box;
          .part-time {
            width: 100px;
            font-size: 30px;
            line-height: 62px;
          }
          .part-circle {
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            bottom: 0px;
          }
        }
        &:nth-child(2n + 1) {
          display: flex;
          flex-direction: row-reverse;
          .part-content {
            border-left: 1px solid #ffa800;
          }
          .part-time {
            display: block;
            color: #ffa800;
          }
          img {
            width: 200px;
            position: absolute;
            left: 25%;
          }
          .part-circle {
            background: #ffa800;
            left: -4px;
          }
        }
        &:nth-child(2n) {
          .part-content {
            border-right: 1px solid #00a4ff;
          }
          .part-time {
            display: block;
            color: #00a4ff;
          }
          img {
            width: 200px;
            position: absolute;
            left: 75%;
          }
          .part-circle {
            background: #00a4ff;
            right: -4px;
          }
        }
        &:last-child {
          .part-time {
            width: 200px;
          }
        }
      }
    }
  }
  .about-item1 {
    .middle {
      margin-top: 110px;
      .item-detail {
        p {
          font-size: 15px;
          span {
            color: #8e8e8e !important;
          }
        }
      }
    }
  }
  .about-item2 {
    // background: url(../../../images/about/honor-bg.png) no-repeat;
    background-size: 100% 100%;
    margin-top: 193px;
    .middle {
      width: 976px;
      text-align: justify;
      line-height: 26px;
      .item-info {
        padding-top: 116px;
        font-size: 15px;
        color: #151515;
      }
    }
  }
}
</style>
