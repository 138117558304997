<template>
  <div class="about-part5-wrap content-width middle about-main">
    <div class="main-block">
      <div class="honor-item" v-for="(item, index) in data" :key="index">
        <div class="item-title">
          <img src="static/images/about/about-part5-span.png" />
          <span>{{ item.yjbt }}</span>
        </div>
        <div class="item-img">
          <img :src="item.iconc | imgSrc" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { functionData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
export default {
  data() {
    return {
      data: "",
    };
  },
  mounted() {
    this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    async getFunction() {
      const res = await functionData({
        functionId: "b0f469311d4943de9ea0cf5855f946dc",
        current: 1,
        pageSize: 999,
        organizationId: config.funOrganizationId,
      });
      if (res.state == 200) {
        this.data = res.results.list.sort((a, b) => {
          return parseInt(b.qzz) - parseInt(a.qzz); //根据权重进行排序
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
//
.about-part5-wrap {
  margin-bottom: 50px;
  & > div {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 1200px;
    margin-bottom: 53px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(227, 229, 232, 0.75);
    box-shadow: 0px 0px 50px 0px rgba(227, 229, 232, 0.75);
    &:first-child {
      margin: 222px auto 0;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .main-block {
    margin: 0 auto;
    padding: 50px;
    padding-bottom: 0;
    box-sizing: border-box;
  }
  .honor-item {
    padding-right: 70px;
    margin-bottom: 58px;
    vertical-align: top;
    display: inline-block;
    &:nth-child(3n) {
      padding-right: 0;
    }
    .item-img {
      width: 310px;
      height: 310px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-title {
      margin-bottom: 20px;
      height: 45px;
      img {
        display: inline;
        vertical-align: middle;
      }
      span {
        font-size: 18px;
        display: inline-block;
        position: relative;
        width: 295px;
        vertical-align: middle;
        line-height: 25px;
      }
    }
  }
}
</style>
