<template>
  <el-cascader
      :key="inited?'1':'2'"
      :value="cascValue"
      class="cjm-area-select"
      @change="onChange"
      clearable
      ref="elcascader"
      :show-all-levels="true"
      :props="props" />
</template>

<script>
const region = require('../../services/common/region');
//
export default {
  props: {
    value: String
  },
  data() {
    return {
      inited: false,
      cascValue: [],
      provinces: [],
      props: {
        lazy: true,
        checkStrictly: true,
        lazyLoad: (node, resolve) => this.lazyLoad(node, resolve)
      }
    }
  },

  methods: {
    
    area2option(area) {
      return { value: area.areaCode, label: area.cityName, leaf: false };
    },
    area3option(area) {
      return { value: area.areaCode, label: area.cityName, leaf: true };
    },
    async lazyLoad(node, resolve) {
      if (!this.inited) {
        resolve([]);
      }
      const { level } = node;
      if (level > 0) {
        const children = await region.getChildAreas(node.data.value);
        children.length == 0 ? (this.$refs.elcascader.dropDownVisible = false) : '' ;
        node.data.leaf = children.length === 0;
        let options = null;
        if(level < 4){
          options = children.map(this.area2option);
        }else{
          options = children.map(this.area3option);
        }
        resolve(options);
      } else {
        const provinces = await region.getProvinces();
        const options = provinces.map(this.area2option);
        resolve(options);
      }
    },
    async setCascValue(value) {
      const parents = await region.getParentAreas(value);
      const { province, city, county, street, village } = parents || {};
      this.cascValue = [province, city, county, street, village].filter(Boolean);
    },
    onChange(values) {
      const value = Array.isArray(values) ? (values[values.length - 1]) : '';
      this.$emit('input', value);
      this.setCascValue(value);
      // this.$refs.elcascader.dropDownVisible = true;
    },
    async init() {
      if (this.value) {
        await this.setCascValue(this.value);
      }
      this.inited = true;
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>
.cjm-area-select {
  display: block;
}


</style>