<template>
  <div class="product-system">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>产品评价、企业信用评价</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      产品评价/信用评价：链接各数字化系统应用，以申报+数字化采集+核查评价，覆盖产品的全生命周期，从农安信用、社会信用、公共服务、产业监管、
      金融信用、合同履约等多个维度，建立产品评价/供应商信用的数字化评价模型。实现信用分级监管，加强产品/供应商信用风险预警，建立信用红黑榜单，
      实现精准决策。
    </div>
    <div class="provideCont">
      <div class="provideCont-top">
        <h1>为品牌企业提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          信用评价标准体系，在应用支持体系能力中心建立信用模型
        </div>
      </div>
      <img src="static/images/product/sys1.png" />
    </div>
    <div class="provideCont">
      <div class="provideCont-top peopleProvide">
        <h1>为品牌企业提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">以信用评价体系为中心的八大服务体系</div>
      </div>
      <img src="static/images/product/sys2.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
