const uuid = require('./uuid');
const imageCompress = require('./imageCompress');
const imageFormPost = require('./imageFormPost');

const sizeLimit = { width: 1800, height: 1800 };

function createInput() {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const style = [
    'position:absolute',
    'left:0',
    'top:0',
    'width:1px',
    'height:1px',
    'opacity:0'
  ].join(';') + ';'
  div.setAttribute('style', style);
  const inputId = `input-${uuid()}`;
  div.innerHTML = `<input id="${inputId}" type="file" accept="image/*"/>`;
  return document.getElementById(inputId);
}

function getInputFile(fileInput) {
  return fileInput.files[0];
}

module.exports = function () {
  return new Promise(resolve => {
    const input = createInput();
    input.onchange = () => {
      (async () => {
        const imgFile = getInputFile(input);
        const imgData = await imageCompress(imgFile, sizeLimit);
        const imgId = await imageFormPost(imgData);
        resolve(imgId);
        document.body.removeChild(input.parentElement);
      })();
    };
    input.click();
  });
}