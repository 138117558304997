<template>
  <div class="logo-box">
    <div class="logo-main">
      <a hid="logo" class="logo-btn">
        <img src="static/images/platformLogo.png">
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.logo-box{
  width: 100%;
  height: 72px;
  border-bottom: 10px solid #F0F1F5;
  .logo-main{
    width: 1200px;
    margin: 0 auto;
    .logo-btn{
      text-indent: -9999px;
      display: block;
      width: 158px;
      height: 40px;
      padding-top: 14px;
      img{
        display: block;
        width: 100%;
      }
    }
  }
}
</style>
