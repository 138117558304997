<template>
  <div class="login-main">
    <h2>免费体验</h2>
    <el-alert
      v-if="showError"
      :title="loginError"
      type="error"
      class="errorText"
      :closable="false"
      show-icon
    >
    </el-alert>
    <el-form
      :model="form"
      class="demo-form-inline"
      :rules="rules"
      ref="form"
      key="form"
      v-if="!isLogin"
    >
      <el-form-item prop="openTel">
        <el-input
          :maxlength="11"
          v-model="form.openTel"
          placeholder="手机号"
          @input="clearFunc"
          class="size"
        />
      </el-form-item>
      <el-form-item prop="openCode" class="send-code">
        <el-input
          :maxlength="6"
          v-model="form.openCode"
          placeholder="验证码"
          @input="clearFunc"
          class="size"
        />
        <el-button class="send-btn" :disabled="beingSent" @click="sendCodeFunc()">{{
          sendText
        }}</el-button>
      </el-form-item>
      <p class="go-login">
        已有账号?
        <a @click="jumpPage('/login.html')" style="cursor: pointer" class="agreement"> 立即登录 </a>
      </p>
      <div class="login-btn">
        <el-button type="primary" :loading="bstop" :disabled="bstop" @click="next('form')">
          登录
        </el-button>
      </div>
      <el-checkbox class="checked-box" v-model="checked">
        <p class="checked-style">
          同意并遵守
          <a :href="protocolUrl" target="_blank" class="agreement">
            <!-- <a href="agreement.html" target="_blank" class="agreement"> -->
            《甲骨文超级码开放平台服务协议》
          </a>
        </p>
      </el-checkbox>
    </el-form>
    <el-form :model="form1" class="demo-form-inline" :rules="rules1" ref="form1" key="form1" v-else>
      <el-form-item prop="orgName">
        <el-input
          :maxlength="30"
          v-model="form1.orgName"
          placeholder="请输入企业名称"
          class="size"
        />
      </el-form-item>
      <el-form-item prop="userName">
        <el-input :maxlength="6" v-model="form1.userName" placeholder="请输入姓名" class="size" />
      </el-form-item>
      <el-form-item prop="mailbox">
        <el-input :maxlength="30" v-model="form1.mailbox" placeholder="请输入邮箱" class="size" />
      </el-form-item>
      <div class="login-btn login-system">
        <el-button type="primary" :loading="bstop" :disabled="bstop" @click="next('form1')">
          进入我的超级码<sup>®</sup>云
        </el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { sendCode, webLogin, userStatus, orgRegister } from "src/static/services/website";
import { ClickTrack, PVTrack } from "src/static/utils/eventTrack";
import { jumpPage } from "src/static/utils/utils";
export default {
  data() {
    return {
      bstop: false,
      checked: false,
      isLogin: false,
      timer: null,
      time: 60,
      sendText: "获取验证码",
      beingSent: false,
      showError: false,
      loginError: "",
      form: {
        openTel: "",
        openCode: "",
      },
      rules: {
        openTel: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请填写正确的手机号", trigger: "blur" },
        ],
        openCode: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^.{6}$/, message: "请输入6位验证码", trigger: "blur" },
        ],
      },
      form1: {
        orgName: "",
        userName: "",
        mailbox: "",
      },
      rules1: {
        orgName: [{ required: true, message: "该项为必填项", trigger: "blur" }],
        userName: [{ required: true, message: "该项为必填项", trigger: "blur" }],
        mailbox: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "邮箱格式有误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    protocolUrl: function () {
      return "http://mzt315.com/protocol";
      return "agreement.html";
    },
  },
  mounted() {
    PVTrack("/experienceLogin.html");
    if (localStorage.getItem("super-token") && localStorage.getItem("phone")) {
      this.getUserStatus();
    }
  },
  methods: {
    jumpPage,
    clearFunc() {
      this.showError ? (this.showError = false) : "";
    },
    validForm(formInline) {
      return new Promise((resolve) => {
        this.$refs[formInline].validate((valid) => {
          resolve(valid);
        });
      });
    },
    validItem(formName, itemName) {
      return new Promise((resolve) => {
        this.$refs[formName].validateField(itemName, (valid) => {
          resolve(valid);
        });
      });
    },
    async sendCodeFunc() {
      const valid = await this.validItem("form", "openTel");
      if (valid) {
        return;
      }
      this.beingSent = true;
      this.sendText = "倒计时" + this.time + "s";
      this.timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.beingSent = false;
          this.time = 60;
          this.sendText = "获取验证码";
          clearInterval(this.timer);
        } else {
          this.sendText = "倒计时" + this.time + "s";
        }
      }, 1000);
      let res = await sendCode({ phoneNumber: this.form.openTel });
      if (res.state != 200) {
        this.beingSent = false;
        this.time = 60;
        this.sendText = "获取验证码";
        clearInterval(this.timer);
      }
    },
    async next(formInline) {
      ClickTrack("免费体验-登录", "点击", "登录");
      const valid = await this.validForm(formInline);
      if (!valid) {
        return;
      }
      this.bstop = true;
      if (formInline == "form") {
        if (!this.checked) {
          ELEMENT.Message.warning("请阅读并同意《甲骨文超级码开放码平台服务条款》");
          this.bstop = false;
          return;
        }
        this.loginFunc();
      } else {
        this.registerFunc();
      }
    },
    async loginFunc() {
      let propsData = {
        code: this.form.openCode,
        account: this.form.openTel,
        sendSms: false,
      };
      const res = await webLogin(propsData);
      if (res.state == 200) {
        localStorage.setItem('super-token', res.results.token);
        localStorage.setItem('phone', this.form.openTel);
        this.getUserStatus();
      } else {
        this.loginError = res.msg;
        this.showError = true;
        this.bstop = false;
      }
      this.beingSent = false;
      this.time = 60;
      this.sendText = "获取验证码";
      clearInterval(this.timer);
    },
    async getUserStatus() {
      const res = await userStatus();
      if (res.state == 200) {
        if (res.results.status == -1) {
          this.loginError = "";
          this.showError = false;
          this.isLogin = true;
        } else if (res.results.status == 9) {
          ELEMENT.MessageBox.confirm(
            "您的测试时间已结束，您可以更换手机号重新体验，或购买正式版系统",
            {
              closeOnClickModal: false,
              cancelButtonText: "更换手机号",
              confirmButtonText: "购买正式系统",
              center: true,
            }
          )
            .then(() => {
              window.location.href = "chooseDeloy.html";
            })
            .catch(() => {
              this.form.openCode = "";
              this.form.openTel = "";
            });
        } else if (res.results.status == 1000) {
          ELEMENT.MessageBox.confirm("您已开通测试系统，可直接进入系统", {
            closeOnClickModal: false,
            showCancelButton: false,
            confirmButtonText: "进入我的超级码®云",
            center: true,
          })
            .then(() => {
              window.open(
                `${
                  res.results.value
                }/apiInterface/interface/hydra-user/api/v1/user/refresh-and-redirect?token=${localStorage.getItem(
                  "super-token"
                )}`
              );
              if (window.top !== window.self) return;
              setTimeout(() => {
                window.location.href = "platform.html";
              }, 1000);
            })
            .catch(() => {});
        }
      }
      this.bstop = false;
    },
    async registerFunc() {
      let propsData = {
        orgName: this.form1.orgName,
        userName: this.form1.userName,
        mailBox: this.form1.mailbox,
      };
      const res = await orgRegister(propsData);
      this.bstop = false;
      if (res.state == 200) {
        window.open(
          `${res.results.sysUrl}/apiInterface/interface/hydra-user/api/v1/user/refresh-and-redirect?token=${res.results.tk}`
        );
        if (window.top !== window.self) return;
        setTimeout(() => {
          window.location.href = "platform.html";
        }, 1000);
      }
    },
  },
  watch: {
    //监听输入
    form: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        this.form.openTel = val.openTel.replace(/\D/g, "");
        this.form.openCode = val.openCode.replace(/\D/g, "");
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.login-main {
  width: 402px;
  height: 440px;
  box-sizing: border-box;
  padding: 0 33px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 111;
  transform: translate(192px, -50%);
  background: rgba(255, 255, 255, 0.96);
  border-radius: 4px;
  .errorText {
    height: 41px;
    box-sizing: border-box;
    border: 1px solid #f05a3c;
    margin-top: -20px;
    margin-bottom: 16px;
  }
  .send-code {
    position: relative;
    .send-btn {
      border: none;
      position: absolute;
      background: none;
      right: 16px;
      top: 0;
      padding: 0;
      line-height: 40px;
      cursor: pointer;
      color: #008aff;
      user-select: none;
    }
  }
  h2 {
    font-size: 26px;
    color: #333;
    margin: 50px 0;
    text-align: center;
    font-weight: normal;
  }
  .go-login {
    font-size: 14px;
    color: #999;
    a {
      text-decoration: none;
      color: #008aff;
    }
  }
  .login-btn {
    margin: 18px auto;
    button {
      width: 100%;
      height: 50px;
      font-size: 16px;
    }
  }
  .login-system {
    margin-top: 40px;
  }
  .checked-box {
    margin-left: 22px;
  }
  .checked-style {
    font-size: 13px;
    a {
      text-decoration: none;
      color: #76c0ff;
    }
  }
}
</style>
