<template>
  <div class="totalStyle">
    <div class="dataStyle">
      <a id="logo">
        <img src="/static/images/platformLogo.png" class="dataLogo" />
      </a>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="160px"
        class="experienceData"
      >
        <el-form-item label="企业名称" prop="orgName">
          <cjm-remote-select
            v-model="ruleForm.orgName"
            @input="handleOrgInput"
            remoteUrl="/apiInterface/interface/hydra-open-thirdpart-service/business/open/thrid/like"
            searchKey="keyWord"
            labelKey="name"
            valueKey="name"
            class="size"
          />
          <p>*您所填写的企业名称将作为开具发票的唯一名称</p>
        </el-form-item>

        <el-form-item label="社会统一信用代码" prop="creditCode">
          <el-input
            v-model="ruleForm.creditCode"
            class="size"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="注册地址" prop="regLocation">
          <el-input
            v-model="ruleForm.regLocation"
            class="size"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="所在地" prop="location">
          <cjm-area-select class="size" v-model="ruleForm.location" />
        </el-form-item>
        <el-form-item label="所属行业" prop="oneIndustryName">
          <cjm-industry-select class="size" v-model="industry" />
        </el-form-item>
        <el-button
          type="primary"
          style="margin-left: 160px"
          @click="submit('ruleForm')"
        >
          提交</el-button
        >
        <el-button @click="goBack()"> 返回</el-button>
      </el-form>
    </div>
  </div>
</template>
    
<script>
import ajax from "../../../utils/ajax";
import { delCookie } from "../../../utils/cookie";
export default {
  data() {
    return {
      bstop: true,
      ruleForm: {
        orgName: "",
        creditCode: "",
        regLocation: "",
        location: "",
        // oneIndustryCode: "",
        oneIndustryName: "",
        region: 1,
      },
      rules: {
        orgName: [{ required: true, trigger: "blur", message: "该项为必填项" }],
        creditCode: [
          { required: true, trigger: "change", message: "该项为必填项" },
        ],
        location: [
          { required: true, trigger: "change", message: "该项为必填项" },
        ],
        oneIndustryName: [
          { required: true, trigger: "change", message: "该项为必填项" },
        ],
      },
    };
  },
  computed: {
    industry: {
      get() {
        const {
          oneIndustryCode,
          twoIndustryCode,
          threeIndustryCode,
        } = this.ruleForm;
        return [oneIndustryCode, twoIndustryCode, threeIndustryCode];
      },
      set(industrys) {
        const [oneIndustry, twoIndustry, threeIndustry] = industrys;
        this.ruleForm.oneIndustryCode = oneIndustry ? oneIndustry.value : "";
        this.ruleForm.oneIndustryName = oneIndustry ? oneIndustry.label : "";
        //
        this.ruleForm.twoIndustryCode = twoIndustry ? twoIndustry.value : "";
        this.ruleForm.twoIndustryName = twoIndustry ? twoIndustry.label : "";
        //
        this.ruleForm.threeIndustryCode = threeIndustry
          ? threeIndustry.value
          : "";
        this.ruleForm.threeIndustryName = threeIndustry
          ? threeIndustry.label
          : "";
      },
    },
  },
  methods: {
    // 返回上一页
    goBack() {
      window.history.back();
    },
    //人工认证弹层
    serviceTelFunc() {
      ELEMENT.MessageBox.confirm("请联系客服人员：18958077257", {
        closeOnClickModal: false,
      })
        .then(() => {})
        .catch(() => {});
    },
    handleOrgInput(value, option) {
      this.ruleForm.creditCode = option.creditCode;
      this.ruleForm.regLocation = option.base;
    },
    validForm(ruleForm) {
      return new Promise((resolve) => {
        this.$refs[ruleForm].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async submit(ruleForm) {
      let $this = this;
      const valid = await this.validForm(ruleForm);
      if (!valid) {
        return;
      }
      if (!this.bstop) {
        return;
      }
      this.bstop = false;
      const materialsUrl =
        "/apiInterface/interface/hydra-user/api/v1/atp-package/open";
      const materialsParams = {
        orgName: this.ruleForm.orgName,
        creditCode: this.ruleForm.creditCode,
        regLocation: this.ruleForm.regLocation,
        packageId: 1,
        productLine: [6],
        region: this.ruleForm.region,
        location: this.ruleForm.location,
        oneIndustryCode: this.ruleForm.oneIndustryCode,
        oneIndustryName: this.ruleForm.oneIndustryName,
        packageTime: localStorage.getItem("packageTime"),
      };
      ajax.post(materialsUrl, materialsParams).then((data) => {
        if (data.res.state == 200) {
          $this.creatFunc();
        } else {
          $this.bstop = true;
          ELEMENT.MessageBox.confirm(data.res.msg, {
            closeOnClickModal: false,
            confirmButtonText: "申请人工认证",
            confirmButtonClass: "affirmRenewal",
          })
            .then(() => {
              this.serviceTelFunc();
            })
            .catch(() => {});
        }

        if (data.res.internalErrorCode == 2003000) {
          if (window.top !== window.self) return;
          setTimeout(() => {
            window.location.href = "platform.html";
          }, 2000);
          return;
        } else if (
          data.res.internalErrorCode == 2003003 ||
          data.res.internalErrorCode == 2003004
        ) {
          //您选择的企业已开通
          let msgText = "";
          data.res.internalErrorCode == 2003003
            ? (msgText = "您选择的企业已开通，请更换企业或用该企业关联账号登陆")
            : (msgText =
                "您选择的企业已存在认证信息，请更换企业或用该企业关联账号登陆");
          ELEMENT.MessageBox.confirm(msgText, {
            closeOnClickModal: false,
            cancelButtonText: "重新选择",
            confirmButtonText: "退出登陆",
            confirmButtonClass: "affirmRenewal",
          })
            .then(async () => {
              const checkUrl =
                "/apiInterface/interface/hydra-user/api/v1/user/web/logout";
              await ajax.get(checkUrl);
              localStorage.clear();
              delCookie();
              window.location.href = "login.html";
            })
            .catch(() => {});
        }
      });
    },
    creatFunc() {
      let $this = this;
      ajax
        .post(
          "/apiInterface/interface/hydra-user/api/v1/atp-package/create-order",
          {}
        )
        .then((data) => {
          $this.bstop = true;
          if (data.res.state == 200) {
            localStorage.setItem("orderNo", data.res.results.orderNo);
            window.location.href = "confirmOrder.html";
          } else {
            ELEMENT.Message.warning(data.res.msg);
          }
        });
    },
  },
};
</script>

<style scoped>
.experienceData {
  margin: 0 360px;
}
.size {
  width: 920px;
}
.dataLogo {
  margin-top: 41px;
  margin-left: 84px;
}
.submit {
  color: white;
  text-decoration: none;
}
</style>