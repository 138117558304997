<template>
  <el-carousel
    class="el-carousel-box"
    trigger="click"
    height="100%"
    arrow="never"
    interval="6000"
  >
    <el-carousel-item
      class="el-carousel-item"
      v-for="item in lists"
      :key="item"
    >
      <img class="carousel-img" :src="item.imgUrl | imgSrc" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { carouselList } from "src/static/services/website";
import imgSrc from "src/static/utils/imgSrc";
module.exports = {
  data() {
    return {
      lists: [],
      current: 1,
      pageSize: 999,
      flag: 1,
    };
  },
  filters: {
    imgSrc,
  },
  methods: {
    async getBannerList() {
      let propsData = {
        current: this.current,
        pageSize: this.pageSize,
        flag: this.flag,
        useType: "1",
      };
      const res = await carouselList(propsData);
      if (res.state == 200) {
        this.lists = res.results.list;
      }
    },
  },
  mounted() {
    this.getBannerList();
  },
};
</script>

<style lang="less" scoped>
.el-carousel-box {
  width: 100%;
  height: 100%;
  .el-carousel-item {
    .carousel-img {
      position: absolute;
      left: -100%;
      right: -100%;
      top: 0;
      bottom: 0;
      margin: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }
}
</style>
