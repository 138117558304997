<template>
  <div class="about-part8-wrap content-width middle about-main">
    <div class="main-block">
      <h2>
        <span>人事招聘</span>
      </h2>
      <div class="main-block-content">
        <div class="list-item-wrap">
          <ul class="list-item">
            <li
              :class="index == num ? 'active' : 'list'"
              v-for="(item, index) in data"
              :key="index"
              @click="num = index"
            >
              {{ item.zwmc }}
            </li>
          </ul>
        </div>
        <div class="list-content-wrap">
          <span class="list-shade"></span>
          <div
            class="list-block4 block-item part7-block-item"
            v-for="(items, indexs) in data"
            v-show="indexs == num"
            :key="indexs"
          >
            <div class="block1">
              <span class="title1">职位描述：</span>
              <div
                class="item-content1 item-content2"
                v-html="items.zwms"
              ></div>
            </div>
            <div class="block2">
              <span class="title2">任职要求：</span>
              <div
                class="item-content1 item-content2"
                v-html="items.rwyq"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { functionData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
export default {
  data() {
    return {
      data: "",
      num: 0,
    };
  },
  mounted() {
    this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    async getFunction() {
      const res = await functionData({
        functionId: "c7480a26eab94439952b8ad845177b8b",
        current: 1,
        pageSize: 999,
        organizationId: config.funOrganizationId,
      });
      if (res.state == 200) {
        this.data = res.results.list;
      }
    },
  },
};
</script>

<style lang="less" scoped>
//
.about-part8-wrap {
  .main-block {
    display: block;
    margin: 0 auto 0;
    width: 1200px;
    text-align: center;
    padding-top: 110px;
    padding-bottom: 50px;
    position: relative;
    .main-block-content {
      display: flex;
    }
  }
  h2 {
    width: 1200px;
    display: block;
    margin-top: 80px;
    margin-bottom: 64px;
    font-size: 100%;
    text-align: center;
    font-weight: 500;
    span {
      font-size: 32px;
      display: inline-block;
    }
  }
  .list-item-wrap {
    padding-left: 30px;
    width: 280px;
    height: 450px;
    overflow: auto;
    max-width: 110%;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }
    .list-item {
      // padding-top: 40px;
      position: relative;
    }
    .list,
    .active {
      padding-right: 30px;
      line-height: 30px;
      font-size: 18px;
      margin-top: 40px;
      text-align: left;
      position: relative;
      z-index: 10;
      cursor: pointer;
      &.first {
        margin-top: 0;
      }
    }
    .active {
      border-right: 5px solid #00a4ff;
      color: #00a4ff;
    }
  }
  .list-shade {
    position: absolute;
    top: 0;
    display: block;
    width: 1px;
    height: 450px;
    left: -3px;
    background: #788496;
  }
  .list-content-wrap {
    position: relative;
    .block-item {
      // display: none;
      margin-top: 40px;
      margin-left: 20px;
      text-align: left;
      width: 900px;
      .block1,
      .block2 {
        margin-bottom: 80px;
        // div {
        //   // padding-top: 30px;
        // }
      }
      .title1,
      .title2 {
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &.block {
        display: block;
      }
    }
  }
}
</style>
