<template>
  <div class="caseShow-numIndus">
    <div class="caseSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>产品生命周期数字化产线应整体解决方案</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      对每个产品（单品）在其生命周期内进行数字信息化管理，帮助产品提升掌控能力的智能信息化管理
      系统；系统以生产管理为基础，溯源管理为平台，营销管理为重点，实现仓储物流和信息流有机统一。
    </div>
    <div class="caseCont">
      <div class="caseCont-top">
        <h1>客户案例</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
    </div>
    <div class="caseText" v-for="(item, index) in caseList" :key="index">
      <div class="caseText-top">
        <h1 class="caseText-topTitle">{{ item.bigTitle }}</h1>
      </div>
      <div>
        <!-- 项目介绍 -->
        <div>
          <img src="static/images/caseShow/icon1.png" class="icon" />
          <span class="itemIntro">项目介绍</span>
          <div class="introText">{{ item.text1 }}</div>
        </div>
        <!-- 企业诉求 -->
        <div>
          <img src="static/images/caseShow/icon2.png" class="icon" />
          <span>企业诉求</span>
          <div class="introText">{{ item.text2 }}</div>
        </div>
        <!-- 解决方案 -->
        <div>
          <img src="static/images/caseShow/icon2.png" class="icon" />
          <span>解决方案</span>
          <div class="introText">{{ item.text3 }}</div>
        </div>
        <img :src="item.imgs" class="imgs" />
      </div>
    </div>

    <div class="caseText">
      <div class="caseText-top">
        <h1 class="caseText-topTitle">毛戈平一物一码产品数字身份管理平台</h1>
      </div>
      <div>
        <div class="introText">
          毛戈平化妆品实现对单个产品赋予“身份证”，实现一件一码精细化管理，有效监督产品流通和管理渠道经销商窜货，
          通过隐形码与标识码的关联，有效降低标识或二维码被恶意破坏的风险，以达到稽查窜货能有据可查的目的
        </div>
        <div class="introText2">
          利用一物一码追溯产品的生产批次，跟踪产品流向（销往经销渠道路径），提升生产管理水平，构建企业生产经营中的仓储、
          销售、服务等关键环节之间的协调联动系统，为构建企业信息化互联互通打下坚实的基础
        </div>
        <img src="static/images/caseShow/indus3.png" />
      </div>
    </div>

    <div class="caseText antifake">
      <div class="caseText-top">
        <h1 class="caseText-topTitle">一体化集成管理，斯凯孚码上防伪</h1>
      </div>
      <div>
        <!-- 项目介绍 -->
        <div>
          <img src="static/images/caseShow/icon1.png" class="icon" />
          <span class="itemIntro">项目介绍</span>
          <div class="introText">
            斯凯孚集团是全球领先的滚动轴承、密封件、机电一体化、润滑系统产品、解决方案和服务的供应商。
            斯凯孚(SKF)于1912年进入中国,现演进为一家知识、技术和数据驱动，以交付可靠运转为使命的公司。
          </div>
        </div>
        <!-- 企业诉求 -->
        <div>
          <img src="static/images/caseShow/icon2.png" class="icon" />
          <span>企业诉求</span>
          <div class="introText">
            近年来，假冒斯凯孚品牌的伪劣产品充斥市场，且越演越烈。制假售假危害着包括轴承在内的工业制
            品的性能及安全性。这些假冒轴承在安装到机器上后，根本无法满足机器的性能需求，很容易导致周边机器的大规模损坏，引起计划外停产，并对终端用户造成财产损失。
          </div>
        </div>
        <!-- 解决方案 -->
        <div class="caseOk">
          <div>
            <img src="static/images/caseShow/icon2.png" class="icon" />
            <span>解决方案</span>
            <div class="introText">
              超级码科技股份为斯凯孚搭建了一套基于区块链技术、一品一码数字化管理的斯凯孚SAAS防伪追溯系统，
              应用到到斯凯孚的轴承产品上。防伪追溯系统与斯凯孚现有的企业ERP/WMS系统、商城系统等实现功能高度集成和数据融合，进一步整合物流资源，
              实现大数据整合和可视化管理，构建斯凯孚总部基于产品防伪追溯二维码的客户服务体系，为斯凯孚提供全方位的解决方案，为每一件产品赋予正品身份。
            </div>
          </div>
          <img src="static/images/caseShow/indusCode.png" />
        </div>
        <div style="text-align: center">
          <img src="static/images/caseShow/indus4.png" />
        </div>
      </div>
    </div>
    <div class="caseSuper dataShow">
      <img src="static/images/product/leftTri.png" />
      <h1>数据屏展示</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="showImgBot">
      <img src="static/images/caseShow/indus5.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseList: [
        {
          bigTitle: "公牛产线数字化，一站式赋码新体验",
          // icon1: "static/images/caseShow/icon2.png",
          // icon2: "static/images/caseShow/icon2.png",
          // icon3: "static/images/caseShow/icon2.png",
          text1:
            "公牛集团股份有限公司创办于1995年，公司专注于以转换器、墙壁开关插座为核心的民用电工产品的研发、生产和销售。",
          text2:
            "国内插座产品整体质量水平偏低，行业内充斥着假冒伪劣产品，高档插座产品与低档产品的价格往往能相差几十倍，受利益驱使，经销商之间互相窜货，缭乱市场价格体系，最严重的情况是质量问题引发的消费者信任度缺失，导致正宗品牌声誉受损。",
          text3:
            "公牛集团数字化管理平台为公牛集团旗下产品的每一个开关插座提供唯一身份码，建立起产品数字化管理，实现从产品生产到消费终端的全生命周期监控和管理，严控假冒伪劣及窜货现象，提高渠道管控能力，增强经销商信心，同时结合企业自身自动化生产、仓储无人化操作，为其定制自动化产品赋码、数据采集、仓储系统无缝对接，实现产品数字化管理，为企业降低生产成本，增加产品的附加值，提升企业软实力，实现互联网企业转型。",
          imgs: "static/images/caseShow/indus1.png",
        },
        {
          bigTitle: "外资品牌本土化管控，岡本码上防窜",
          // icon1: "static/images/caseShow/icon2.png",
          // icon2: "static/images/caseShow/icon2.png",
          // icon3: "static/images/caseShow/icon2.png",
          text1:
            "冈本株式会社创立于昭和九年（1934年），是世界上最早开始生产安全套的厂家之一。公司总部设在日本东京，分别在日本的静冈、茨城、福岛三大城市设有产品研发和生产基地。随着公司的不断发展，冈本先後成立了香港（岡本）有限公司、美国（岡本）有限公司，将业务领域拓展到了亚洲及北美地区。1949年6月，岡本株式会社在日本挂牌上市，经过70余年的不懈努力，现今的岡本株式会社已发展成为日本最大的橡胶乳胶制品生产商。",
          text2:
            "近年来，假冒岡本品牌的伪劣产品充斥市场，且越演越烈。严重影响到了消费者对岡本产品的信任。",
          text3:
            "岡本高度重视，2021年岡本联合超级码科技股份搭建一套基于区块链、一物一码数字化管理平台，对产品进行全流程追溯，并和企业的ERP系统对接，和原有营销系统进行无缝对接，实现大数据整合和可视化管理，构建岡本基于产品一物一码全流程监管体系。",
          imgs: "static/images/caseShow/indus2.png",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@h: 1200px;
.caseShow-numIndus {
  h1 {
    position: relative;
    z-index: 9;
  }
  .caseSuper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
    h1 {
      padding: 0 15px;
    }
  }
  .codeText {
    font-size: 18px;
    color: #8e8e8e;
    line-height: 34px;
    text-align: left;
    padding-bottom: 40px;
    width: @h;
    margin: 0 auto;
  }

  .caseCont {
    text-align: center;
    position: relative;
    width: @h;
    margin: 0 auto;
    h1 {
      padding-bottom: 22px;
    }
    .blueBg {
      position: absolute;
      top: 24px;
      left: 518px;
    }
  }
  .caseText {
    width: @h;
    margin: 0 auto;
    .caseText-top {
      background: url("static/images/caseShow/caseTitle.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-content: center;
      box-sizing: border-box;
      padding: 10px;
      h1 {
        margin: 0;
        padding-left: 55px;
      }
      .caseText-topCont {
        display: inline-block;
        padding-top: 12px;
        font-size: 20px;
      }
    }
    .caseText-intro {
      font-size: 18px;
      color: #8e8e8e;
      line-height: 34px;
      text-align: left;
      padding: 44px 0;
    }
    .caseText-bg {
      color: #fff;
      margin-bottom: 40px;
      .caseText-bgTitle {
        font-size: 30px;
        font-weight: bold;
        padding-left: 15px;
        padding-bottom: 35px;
      }
      .iconText {
        display: flex;
        margin-bottom: 30px;
        > img {
          height: 26px;
        }
        > div {
          width: 269px;
          line-height: 60px;
          line-height: 26px;
          padding-left: 20px;
        }
      }
    }
    .icon {
      vertical-align: middle;
      margin-top: -3px;
    }
    .itemIntro {
      font-size: 20px;
      padding-bottom: 10px;
      padding-top: 30px;
      display: inline-block;
    }
    .introText,
    .introText2 {
      color: #949494;
      line-height: 30px;
      padding-top: 15px;
      padding-bottom: 34px;
    }
    .introText2 {
      padding-top: 0;
    }
    .imgs {
      margin-bottom: 60px;
    }
    .caseOk {
      display: flex;
      justify-content: space-between;
      .introText {
        width: 840px;
      }
    }
  }
  .antifake {
    margin-top: 60px;
  }
  .dataShow {
    background: #fff;
  }
  .showImgBot {
    background: #fff;
    text-align: center;
    padding-bottom: 50px;
  }
}
</style>
