<template>
  <div class="openCertification">
    <div class="unfinished-box" v-show="!isSucceed">
      <div class="skip-msg">
        <p class="skip-btn" @click="skipAuthFunc()">跳过企业认证>></p>
        <p class="skip-text">(生码前需通过认证，可开通后在系统中认证)</p>
      </div>
      <div class="flow-main">
        <div class="form-box">
          <!-- 企业信息认证 -->
          <el-form
            :model="form1"
            class="demo-form-inline"
            label-position="right"
            label-width="140px"
            :rules="rules1"
            ref="form1"
            key="form1"
            v-if="activeIndex == 0"
          >
            <el-form-item label="企业名称" prop="orgName">
              <cjm-remote-select
                v-model="form1.orgName"
                @input="handleOrgInput"
                remoteUrl="/apiInterface/interface/hydra-open-thirdpart-service/business/open/thrid/like"
                searchKey="keyWord"
                labelKey="name"
                valueKey="name"
                class="size"
              />
            </el-form-item>
            <el-form-item label="社会统一信用代码" prop="creditCode">
              <el-input
                v-model="form1.creditCode"
                class="size"
                disabled
              ></el-input>
            </el-form-item>
            <div class="twoBtn">
              <el-button
                type="primary"
                :disabled="disabled"
                :loading="disabled"
                @click="next('form1')"
                >下一步</el-button
              >
              <el-button @click="goBack()">上一步</el-button>
            </div>
          </el-form>

          <!-- 企业信息核验 -->
          <el-form
            :model="form2"
            class="demo-form-inline"
            label-position="right"
            label-width="140px"
            :rules="rules2"
            ref="form2"
            key="form2"
            v-if="activeIndex == 1"
          >
            <el-form-item label="企业名称" prop="orgName">
              <el-input
                v-model="form2.orgName"
                class="size"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="社会统一信用代码" prop="creditCode">
              <el-input
                v-model="form2.creditCode"
                class="size"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="法定代表人姓名">
              <el-input
                :maxlength="6"
                v-model="form2.legalPersonName"
                placeholder="请输入法定代表人姓名"
                class="size"
              ></el-input>
            </el-form-item>
            <el-form-item label="法定代表人身份证号" prop="legalPersonId">
              <el-input
                :maxlength="18"
                v-model="form2.legalPersonId"
                placeholder="请输入法定代表人身份证号"
                class="size"
              ></el-input>
            </el-form-item>
            <div class="twoBtn">
              <el-button
                type="primary"
                :disabled="disabled"
                :loading="disabled"
                @click="next('form2')"
                >下一步</el-button
              >
              <el-button @click="goBack()">上一步</el-button>
            </div>
          </el-form>

          <!-- 对公打款认证 -->
          <div class="msg-box" v-if="activeIndex == 2">
            <div class="msg-label">认证信息</div>
            <div class="msg-main">
              <p>{{ form1.orgName }}</p>
              <p>{{ form1.creditCode }}</p>
              <span @click="anewFunc()">重新认证</span>
            </div>
          </div>
          <el-form
            :model="form3"
            class="demo-form-inline"
            label-position="right"
            label-width="140px"
            :rules="rules3"
            ref="form3"
            key="form3"
            v-if="activeIndex == 2"
            v-show="!playVerify"
          >
            <el-form-item label="对公银行" prop="bank">
              <el-select
                v-model="form3.bank"
                class="size"
                clearable
                placeholder="请输入银行关键字"
                filterable
                remote
                :remote-method="getBankSearch"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item"
                  :label="item.bankName"
                  :value="item.bankName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="对公银行卡卡号" prop="bankCardId">
              <el-input
                :maxlength="20"
                v-model="form3.bankCardId"
                placeholder="请输入单位对公银行账户"
                class="size"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户支行名称" prop="bankOfOpenBranchName">
              <el-input
                :maxlength="50"
                v-model="form3.bankOfOpenBranchName"
                placeholder="请输入银行全称，如：中国工商银行常州分行"
                class="size"
              />
            </el-form-item>
            <div class="twoBtn">
              <el-button
                type="primary"
                :disabled="disabled"
                :loading="disabled"
                @click="next('form3')"
                >下一步</el-button
              >
              <el-button @click="goBack()">返回变更企业</el-button>
            </div>
          </el-form>
          <PlayVerify
            v-if="activeIndex == 2 && playVerify"
            @cutState="cutState"
            @isFreezeFunc="isFreezeFunc"
          />
        </div>
        <CertificationSteps :active="activeIndex" />
      </div>
    </div>
    <div class="succeed-box" v-if="isSucceed">
      <p class="succeed-msg"><em></em>恭喜您！</p>
      <p class="succeed-text">已完成企业认证</p>
      <el-button
        type="primary"
        :disabled="disabled"
        :loading="disabled"
        @click="createOrderFunc()"
        >前往查看订单</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  skipAuth,
  createOrder,
  authStatus,
  yourselfDetail,
  authOrg,
  authOrgCheck,
  authOrgPayment,
  bankSearch,
} from "src/static/services/website";
import CertificationSteps from "./certificationSteps.vue";
import PlayVerify from "./playVerify.vue";
import { setOrderStorage } from "src/static/utils/orderStorage";
import { ClickTrack } from "src/static/utils/eventTrack";
export default {
  data() {
    let validLegalPersonId = (rule, value, callback) => {
      let _IDRe18 =
        /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let _IDre15 =
        /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
      if (
        this.form2.legalPersonId == "" ||
        this.form2.legalPersonId == null ||
        _IDRe18.test(this.form2.legalPersonId) ||
        _IDre15.test(this.form2.legalPersonId)
      ) {
        callback();
      } else {
        callback(new Error("请输入正确的身份证号"));
      }
    };
    return {
      activeIndex: -1,
      playVerify: false,
      isSucceed: false,
      disabled: false,
      isFreeze: false,
      loading: false,
      options: [],
      form1: {
        orgName: "",
        creditCode: "",
      },
      form2: {
        orgName: "",
        creditCode: "",
        legalPersonName: "",
        legalPersonId: "",
      },
      form3: {
        bank: "",
        bankCardId: "",
        bankOfOpenBranchName: "",
      },
      rules1: {
        orgName: [{ required: true, message: "该项为必填项", trigger: "blur" }],
        creditCode: [{ required: true, message: "该项为必填项", trigger: "blur" }],
      },
      rules2: {
        legalPersonId: [{ validator: validLegalPersonId, trigger: "blur" }],
      },
      rules3: {
        bank: [{ required: true, message: "该项为必填项", trigger: "change" }],
        bankCardId: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          {
            pattern: /^([1-9]{1})(\d{11}|\d{15}|\d{16}|\d{18}|\d{19})$/,
            message: "请填写正确的银行卡号",
            trigger: "blur",
          },
        ],
        bankOfOpenBranchName: [
          { required: true, message: "该项为必填项", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    CertificationSteps,
    PlayVerify,
  },
  mounted() {
    this.getAuthStatus();
    this.getDetail();
  },
  methods: {
    // 获取银行名称列表
    async getBankSearch(search) {
      this.loading = true;
      let propsData = {
        current: 1,
        flag:1,
        search: search
      };
      const res = await bankSearch(propsData);
      this.loading = false;
      if (res.state == 200) {
        this.options = res.results.list;
      }
    },
    // 获取认证状态
    async getAuthStatus() {
      const res = await authStatus();
      if (res.state == 200) {
        this.selectState(res.results);
      }
    },
    // 获取已填写的认证信息
    async getDetail() {
      const res = await yourselfDetail();
      if (res.state == 200) {
        const _ = require("lodash");
        this.form2.orgName = this.form1.orgName =
          _.get(res, "results.orgName").indexOf("企业-") == 0
            ? ""
            : _.get(res, "results.orgName");
        this.form2.creditCode = this.form1.creditCode =
          _.get(res, "results.orgName").indexOf("企业-") == 0
            ? ""
            : _.get(res, "results.creditCode");
        this.form2.legalPersonName = _.get(res, "results.legalPersonName");
        this.form2.legalPersonId = _.get(res, "results.legalPersonIdCard");
        this.form3.bank = _.get(res, "results.bank");
        this.form3.bankCardId = _.get(res, "results.bankCardId");
        this.form3.bankOfOpenBranchName = _.get(
          res,
          "results.bankOfOpenBranchName"
        );
      }
    },
    selectState(results) {
      let stateObj = {
        "-1": () => {
          this.activeIndex = 0;
        },
        0: () => {
          this.activeIndex = 0;
        },
        1: () => {
          this.isSucceed = true;
        },
        3: () => {
          this.activeIndex = 2;
          this.playVerify = true;
        },
        4: () => {
          this.activeIndex = 2;
          this.playVerify = true;
        },
        5: () => {
          this.activeIndex = 1;
        },
        6: () => {
          this.activeIndex = 2;
          this.playVerify = true;
        },
        8: () => {
          this.activeIndex = 1;
        },
        9: () => {
          this.activeIndex = 2;
          this.playVerify = false;
        },
        a: () => {
          this.createOrderFunc();
        },
      };
      if (results.status in stateObj) {
        stateObj[results.status]();
      } else {
        this.activeIndex = 0;
      }
    },
    //显示冻结信息
    freezeMsg() {
      ELEMENT.MessageBox.confirm("您输入的打款金额有误，实名认证流程已冻结", {
        closeOnClickModal: false,
        confirmButtonText: "申请人工审核",
        center: true,
      })
        .then(() => {
          this.artificialFunc();
        })
        .catch(() => {});
    },
    //人工认证弹层
    artificialFunc() {
      ELEMENT.MessageBox.confirm("请联系客服人员：18958077257", {
        closeOnClickModal: false,
        confirmButtonText: "我知道啦",
        showCancelButton: false,
        center: true,
      })
        .then(() => {})
        .catch(() => {});
    },
    // 跳过企业认证
    async skipAuthFunc() {
      if (this.isFreeze) {
        this.freezeMsg();
        return;
      }
      this.disabled = true;
      const res = await skipAuth({});
      this.disabled = false;
      if (res.state == 200) {
        //创建套餐开通订单
        this.createOrderFunc();
      }
    },
    async createOrderFunc() {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      },1000)
      setOrderStorage();
      return ;
      const res = await createOrder({});
      if (res.state == 200) {
        localStorage.setItem("orderNo", res.results.orderNo);
        window.location.href = "confirmOrder.html";
      }
      this.disabled = false;
    },
    // 返回上一步
    goBack() {
      if (this.activeIndex == 0) {
        window.location.href = "openContent.html?type=changePackage";
      } else {
        this.activeIndex = 0;
      }
    },
    anewFunc() {
      if (this.isFreeze) {
        this.freezeMsg();
        return;
      }
      ELEMENT.MessageBox.confirm(
        "重新认证会返回企业信息认证页面，终止当前认证流程，是否确认重新认证？",
        {
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.activeIndex = 0;
        })
        .catch(() => {});
    },
    handleOrgInput(value, option) {
      this.form1.creditCode = option.creditCode;
      this.$refs.form1.validate();
    },
    validForm(formInline) {
      return new Promise((resolve) => {
        this.$refs[formInline].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async next(formInline) {
      ClickTrack('立即购买-下一步','点击','下一步')
      const valid = await this.validForm(formInline);
      if (!valid) {
        return;
      }
      this.disabled = true;
      if (formInline == "form1") {
        this.form2.orgName = this.form1.orgName;
        this.form2.creditCode = this.form1.creditCode;
        this.authOrgFunc();
      } else if (formInline == "form2") {
        this.authOrgCheckFunc();
      } else if (formInline == "form3") {
        this.authOrgPaymentFunc();
      }
    },
    // 企业信息认证
    async authOrgFunc() {
      let propsData = {
        orgName: this.form1.orgName,
        creditCode: this.form1.creditCode,
      };
      const res = await authOrg(propsData);
      this.disabled = false;
      if (res.state == 200) {
        this.activeIndex++;
      }else if (
        res.internalErrorCode == "2003003" ||
        res.internalErrorCode == "2003004" ||
        res.internalErrorCode == "2003008"
      ) {
        let msg = '您选择的企业已开通系统，请更换企业或使用该企业关联的账号登陆';
        res.internalErrorCode == "2003004" && (msg = '您选择的企业已存在认证信息，请更换企业或使用该企业关联的账号登陆！');
        ELEMENT.MessageBox.confirm(
          msg,
          {
            closeOnClickModal: false,
            confirmButtonText: "登录",
            cancelButtonText: "更换认证企业",
          }
        )
          .then(() => {
            window.location.href = "login.html";
          })
          .catch(() => {});
        return;
      }else if (res.internalErrorCode == "2006003") {
        ELEMENT.Message.warning("套餐暂未选择，跳转套餐选择页面");
        setTimeout(() => {
          window.location.href = "chooseDeloy.html";
        }, 3000);
        return;
      }else if(res.internalErrorCode == "2003013"){
        ELEMENT.MessageBox.confirm("该账号实名认证已冻结!", { 
          closeOnClickModal: false,
          showCancelButton: false,
          center: true })
            .then(() => {})
            .catch(() => {})
      }else{
        ELEMENT.Message.warning(res.msg);
      }
    },
    // 法人信息认证
    async authOrgCheckFunc() {
      let propsData = {
        legalPersonName: this.form2.legalPersonName,
        legalPersonIdCard: this.form2.legalPersonId,
      };
      const res = await authOrgCheck(propsData);
      if (res.state == 200) {
        this.playVerify = false;
        this.activeIndex++;
      }
      this.disabled = false;
    },
    warningFunc(msg) {
      ELEMENT.MessageBox.confirm(msg, {
        closeOnClickModal: false,
        confirmButtonText: "我知道啦",
        showCancelButton: false,
        center: true,
      })
        .then(() => {})
        .catch(() => {});
    },
    //企业对公打款信息核验
    async authOrgPaymentFunc() {
      let propsData = {
        bank: this.form3.bank,
        bankCardId: this.form3.bankCardId,
        bankOfOpenBranchName: this.form3.bankOfOpenBranchName,
      };
      const res = await authOrgPayment(propsData);
      let errorCodeObj = {
        2003013: () => {
          ELEMENT.Message.warning("该账号实名认证已冻结!");
        },
        2006003: () => {
          ELEMENT.MessageBox.confirm("套餐暂未选择，请跳转套餐选择页面", {
            closeOnClickModal: false,
            confirmButtonText: "去选择套餐",
            center: true,
          })
            .then(() => {
              window.location.href = "chooseDeloy.html?type=changePackage";
            })
            .catch(() => {});
        },
        2010000: () => {
          this.warningFunc("网络异常，请联系客服人员：18958077257");
        },
        2003007: () => {
          this.warningFunc(
            "您的对公打款次数已达上限，请联系客服人员：18958077257"
          );
        },
        2003015: () => {
          ELEMENT.Message.warning("请输入正确的银行卡号!");
        },
        2003016: () => {
          this.warningFunc(
            "暂不支持该银行对公打款，请联系客服人员：18958077257"
          );
        },
        2003017: () => {
          ELEMENT.Message.warning("您输入的银行账户信息与企业信息不匹配，请重试!");
        },
        2003012: () => {
          this.warningFunc(
            "您的账号存在风险，请联系客服人员：18958077257"
          );
        },
      };
      if (res.internalErrorCode in errorCodeObj) {
        errorCodeObj[res.internalErrorCode]();
      } else if (res.state != 200) {
        res.msg && ELEMENT.Message.warning(res.msg);
      }

      if (res.state == 200) {
        this.playVerify = true;
      }
      this.disabled = false;
    },
    cutState() {
      this.isSucceed = true;
    },
    isFreezeFunc() {
      this.isFreeze = true;
    },
  },
};
</script>

<style lang="less" scoped>
.openCertification {
  padding-top: 30px;
  width: 800px;
  margin: 0 auto;
  font-size: 14px;
  p {
    margin: 0;
    line-height: 1.8;
  }
  .skip-msg {
    width: 100%;
    height: 70px;
    text-align: center;
    background: rgba(0, 138, 255, 0.05);
    box-sizing: border-box;
    padding-top: 12px;
    .skip-btn {
      width: 160px;
      margin: 0 auto;
      color: #008aff;
      cursor: pointer;
    }
    .skip-text {
      color: #bfbfbf;
    }
  }
  .flow-main {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .form-box {
      width: 626px;
    }
    .demo-form-inline {
      width: 100%;
    }
    .size {
      width: 486px;
    }
    .twoBtn {
      margin-left: 140px;
    }
  }
  .msg-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .msg-label {
      width: 140px;
      box-sizing: border-box;
      padding-right: 12px;
      text-align: right;
      color: #606266;
    }
    .msg-main {
      width: 486px;
      height: 88px;
      background: #fbfbfb;
      color: #333;
      box-sizing: border-box;
      padding-left: 33px;
      padding-top: 16px;
      position: relative;
      p {
        line-height: 2;
      }
      span {
        position: absolute;
        right: 25px;
        top: 40px;
        color: #008aff;
        cursor: pointer;
      }
    }
  }
  .succeed-box {
    text-align: center;
    .succeed-msg {
      font-size: 28px;
      color: #6dd56e;
      font-weight: 600;
      margin-top: 10px;
      em {
        vertical-align: -36%;
        display: inline-block;
        margin-right: 18px;
        width: 61px;
        height: 66px;
        background: url("static/images/icon/succeed_icon.png") center no-repeat;
      }
    }
    .succeed-text {
      font-size: 18px;
      margin-top: 16px;
      margin-bottom: 26px;
      color: #333;
      font-weight: 600;
    }
  }
}
</style>
