const db = {};
const ajax = require('../../utils/ajax');
const api = require('../../api');
//获取所有省份
exports.getProvinces = async () => {
  if (!db.provinces) {
    const { res } = await ajax.get(api.area.GET_PROVINCES);
    db.provinces = res.results;
  }
  return db.provinces;
};

//获取某个地区的下级地区
exports.getChildAreas = async (areaCode) => {
  if (!db.children) {
    db.children = {};
  }
  if (!db.children[areaCode]) {
    const { res } = await ajax.get(`${api.area.GET_CHILDREN}/${areaCode}`);
    db.children[areaCode] = res.results;
  }
  return db.children[areaCode];
};

//根据地区编码获取详细信息
exports.getAreaDetail = async (areaCode) => {
  if (!db.detail) {
    db.detail = {};
  }
  if (!db.detail[areaCode]) {
    const { res } = await ajax.get(api.area.GET_DETAIL, { areaCode });
    db.detail[areaCode] = res.results;
  }
  return db.detail[areaCode];
};

//获取某个地区编码的所有父级地区信息
exports.getParentAreas = async (areaCode) => {
  if (!db.parents) {
    db.parents = {};
  }
  if (!db.parents[areaCode]) {
    const { res } = await ajax.get(api.area.GET_ALL_PARENTS, { areaCode });
    db.parents[areaCode] = res.results;
  }
  return db.parents[areaCode];
};