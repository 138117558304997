<template>
  <div class="about-part7-wrap middle about-main">
    <h2>
      <span>联系我们</span>
    </h2>
    <div class="part7-main-block1 content-width">
      <ul>
        <li>
          <img src="static/images/about/contact-ing1.png" />
          <span class="main-block2-title">统一客服热线</span>
          <span class="main-block2-info">{{ servicesTelphone }}</span>
        </li>
        <li>
          <img src="static/images/about/contact-ing2.png" />
          <span class="main-block2-title">直线咨询</span>
          <span class="main-block2-info">0571-88265513</span>
        </li>
        <li>
          <img src="static/images/about/contact-ing3.png" />
          <span class="main-block2-title">地址</span>
          <span class="main-block2-info">{{ companyAddress }}</span>
        </li>
        <li>
          <img src="static/images/about/contact-ing4.png" />
          <span class="main-block2-title">产品代理及合作</span>
          <span class="main-block2-info">0571-88265513</span>
        </li>
        <li style="margin-right: 0">
          <img src="static/images/about/contact-ing5.png" />
          <span class="main-block2-title">邮箱</span>
          <span class="main-block2-info">31500@chaojima.com</span>
        </li>
      </ul>
    </div>
    <div class="part7-main-block2">
      <h2>
        <span>分公司办事处联系方式</span>
      </h2>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">北京分公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：北京朝阳区金台西路2号人民日报新媒体大楼24层</span>
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">西南分公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：广西南宁市青秀区东方国际B座906</span>
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">西北分公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：陕西西安市未央区凤城八路保亿风景御园18号楼1115室</span>
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">贵州分公司</span>
        </div>
        <div class="part7-content-right">
          <span
            >地址：贵阳市云岩区延安中路56号世贸广场B座（贵州省商务厅内）</span
          >
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">江苏分公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：江苏省句容市白兔镇现代农业产业示范园</span>
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">安徽分公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：安徽省巢湖市 合肥市巢湖市汤山村三瓜公社</span>
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">河北分公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：河北省河间市澳林托斯卡纳小镇2号楼2层</span>
        </div>
      </div>
      <div class="part7-content-item">
        <div class="part7-content-left">
          <i></i>
          <span class="part7-companyName">湖州子公司</span>
        </div>
        <div class="part7-content-right">
          <span>地址：浙江湖州安吉县天荒坪南路99号4幢4楼</span>
        </div>
      </div>
    </div>
    <div class="part7-main-block3">
      <img src="static/images/about/map.png" />
    </div>
  </div>
</template>

<script>
import imgSrc from "src/static/utils/imgSrc";
export default {
  data() {
    return {
      // data: "",
    };
  },
  mounted() {
    // this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    // async getFunction() {
    //   const res = await functionData({
    //     functionId: "b0f469311d4943de9ea0cf5855f946dc",
    //     current: 1,
    //     pageSize: 999,
    //     organizationId: config.funOrganizationId,
    //   });
    //   if (res.state == 200) {
    //     this.data = res.results.list.sort((a, b) => {
    //       return parseInt(b.qzz) - parseInt(a.qzz); //根据权重进行排序
    //     });
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
//

.about-part7-wrap {
  width: 100%;
  padding-top: 110px;
  h2 {
    display: block;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 64px;
    font-size: 100%;
    font-weight: 500;
    span {
      font-size: 32px;
      display: inline-block;
    }
  }
  .part7-main-block1 {
    padding-bottom: 110px;
    margin: 0 auto;
    width: 1200px;
    ul {
      display: flex;
      li {
        &:first-child {
          background: url("static/images/about/contact-ing6.png") no-repeat;
        }
        &:nth-child(2) {
          background: url("static/images/about/contact-ing7.png") no-repeat;
        }
        &:nth-child(3) {
          background: url("static/images/about/contact-ing8.png") no-repeat;
        }
        &:nth-child(4) {
          background: url("static/images/about/contact-ing9.png") no-repeat;
        }
        &:nth-child(5) {
          background: url("static/images/about/contact-ing10.png") no-repeat;
        }
        width: 224px;
        height: 260px;
        margin-right: 20px;
        background: #000;
        text-align: center;
        padding: 0 10px;
        img {
          margin-top: 60px;
          height: 60px;
        }
        .main-block2-title {
          font-size: 20px;
          color: #fff;
          display: block;
          line-height: 50px;
          margin-top: -14px;
          text-align: center;
          margin: 25px auto 0;
        }
        .main-block2-info {
          font-size: 16px;
          color: #fff;
          display: block;
          line-height: 20px;
          margin: 0 auto;
        }
      }
    }
  }
  .part7-main-block2 {
    background-color: #f5f6f7;
    padding-bottom: 80px;
    h2 {
      display: block;
      text-align: center;
      padding-top: 80px;
      margin-top: 0;
      margin-bottom: 64px;
      font-size: 100%;
      font-weight: 500;
      span {
        font-size: 32px;
        display: inline-block;
      }
    }
    .part7-content-item {
      background: #fff;
      width: 1200px;
      margin: 0 auto;
      height: 123px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .part7-content-left {
        width: 30%;
        display: inline-block;
        padding: 30px 0;
        padding-left: 30px;
        text-align: left;
        i {
          display: inline-block;
          width: 50px;
          height: 42px;
          background: url("static/images/about/part7-content-left-icon.png");
        }
        span {
          width: 180px;
          border-right: 1px solid rgba(227, 229, 232, 0.75);
          margin-left: 10px;
          padding-right: 15px;
          display: inline-block;
          height: 50px;
          position: relative;
          bottom: 10px;
          line-height: 50px;
          font-family: "微软雅黑";
          font-weight: bold;
          font-size: 20px;
        }
      }
      .part7-content-right {
        display: inline-block;
        span {
          margin-right: 20px;
          margin-bottom: 10px;
          font-size: 15px;
          color: #737373;
        }
      }
    }
  }
  .part7-main-block3 {
    img {
      width: 100%;
    }
    //   height: 400px;
    //   margin: 0 auto;
    //   background: #fff;
    //   #container {
    //     height: 100%;
    //     width: 100%;
    //   }
  }
}
</style>
