const _ = require('lodash');

function weightSort(list) {
  const newList = _.sortBy(list, (item) => {
    const weight = (item.weight || 0) * -1;
    return weight;
  });
  return newList;
}

module.exports = weightSort;
//
// let arr = [];
// arr = weightSort(arr);
// console.log(arr);
