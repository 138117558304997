<template>
  <div class="product-feelings">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>品牌企业线上线下全方位舆情信息聚合</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      基于大数据爬虫团队及依托合作的专业第三方实时信息数据，对品牌企业进行线上
      线下全方位品味舆情信息聚合，形成品牌舆情监控、电商平台打假维权、一键下架、
      线下打假维权一站式服务。
    </div>
    <div class="feelingsOne">
      <div class="provideCont">
        <div class="provideCont-top">
          <h1>为品牌企业提供</h1>
          <img src="static/images/product/blueBg.png" class="blueBg" />
          <div class="topText">全方位舆情监控</div>
        </div>
        <img src="static/images/product/feelings1.png" />
      </div>
    </div>
    <div class="provideCont">
      <div class="provideCont-top">
        <h1>为品牌企业提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">线上线下全方位打假维权品牌保护一站式服务</div>
      </div>
      <div class="numCont-only">
        <img src="static/images/product/numLeft.png" />
        <div>实现路径</div>
        <img src="static/images/product/numRight.png" />
      </div>
      <div class="roll"></div>
    </div>
    <div class="numCont-only">
      <img src="static/images/product/numLeft.png" />
      <div>服务内容</div>
      <img src="static/images/product/numRight.png" />
    </div>
    <div class="yqImg"><img src="static/images/product/feelings3.png" /></div>
    <div class="numCont-only">
      <img src="static/images/product/numLeft.png" />
      <div>能力优势</div>
      <img src="static/images/product/numRight.png" />
    </div>
    <div class="serviceAdv">
      <div class="advOnly" v-for="(item, index) in dataList" :key="index">
        <img :src="item.icon" />
        <div class="advRight">
          <div class="rightTitle">{{ item.title }}</div>
          <div class="rightCont">
            {{ item.cont }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        {
          icon: "static/images/home/service1.png",
          title: "数据获取能力",
          cont: "资深大数据团队提供技术支持，确保数据获取的全面性，及时性以及准确性",
        },
        {
          icon: "static/images/home/service2.png",
          title: "数据分析能力",
          cont: "资深算法团队支撑，自研数据分析模型，保障分析结果的可靠性",
        },
        {
          icon: "static/images/home/service3.png",
          title: "处置能力",
          cont: "合作多家专业律所，公证处以及打假团队，提高处置效率，缩短处置周期",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
