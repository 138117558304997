<template>
  <div class="deloyCont" v-show="!!detailsList">
    <div>
      <p class="name function">功能特权</p>
      <div class="functionCont">
        <p>数字防伪</p>
        <p>品质溯源</p>
        <p>动态营销</p>
        <p>一键维权</p>
        <p>智能防窜货</p>
        <p class="platform"><img src="static/images/icon/recommend.png"><span>发码平台</span></p>
        <p>自定义logo/域名</p>
        <p>企业开通数</p>
        <p>码量</p>
        <p>企业开通数超出收费</p>
        <p>码超出收费</p>
        <p class="sale">售价(内部优惠版88)</p>
      </div>
    </div>
    <div class="detailsBox" v-for="(item,index) in detailsList" :key="index">
      <p class="name">{{item.productName}}</p>
      <div class="packageIdCont">
        <p v-for="(funItem,index2) in item.sysFunVOs" :key="index2">
          <img :src="funItem.validDays !=0 ? 'static/images/icon/ensure.png' : 'static/images/icon/error.png'" :class="funItem.validDays !=0 ? 'ensure' : ''">
          {{(item.type == 0 && funItem.validDays !=0) ? `(${funItem.validDays}天有效)` : ''}}
        </p>
        <p>
          <img :src="item.customDomain ? 'static/images/icon/ensure.png' : 'static/images/icon/error.png' " :class="item.customDomain ? 'ensure' : ''">
        </p>
        <p>
          <img src="static/images/icon/error.png" v-if="item.openOrg == 0">
          {{item.openOrg > 0 ? `${item.openOrg}家` : ''}}
        </p>
        <p>
          <img src="static/images/icon/error.png" v-if="item.codeTotal == 0">
          {{item.codeTotal != 0 ? `${parseInt(item.codeTotal/10000)}万${item.type == 0 ? '个免费体验码' : ''}` : ''}}
        </p>
        <p v-if="!item.showRealData && item.orgType != '003002'">详询客服</p>
        <p v-else>
          <img src="static/images/icon/error.png" v-if="item.openOrgUnitPrice == 0">
          {{item.openOrgUnitPrice != 0 ? `${item.openOrgUnitPrice}元 / 家` : ''}}
        </p>
        <p v-if="!item.showRealData && item.orgType != '003002'">详询客服</p>
        <p v-else>
          <img src="static/images/icon/error.png" v-if="item.codeUnitPrice == 0">
          {{item.codeUnitPrice != 0 ? `${item.codeUnitPrice}元 / 个` : ''}}
        </p>
      </div>
      <div class="experienceNow">
        <p v-if="!item.showRealData">
          <span class="enquiryMsg">详询客服</span><br />
          <span class="nowColor" @click="buyFunc(item)">{{item.type == 0 ? '免费体验' : '立即购买'}}</span>
        </p>
        <p v-else>
          <span class="money">￥</span>
          <span class="price">{{item.price}}</span> / {{item.unit}}<br />
          <span class="nowColor" @click="buyFunc(item)">{{item.type == 0 ? '免费体验' : '立即购买'}}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import {openStatus,cancelOrder,userStatus,PackageDetails} from "src/static/services/website";
import { skipFunc } from "src/static/utils/webskipUtils.js";
import { GetUrlParam } from "src/static/utils/getUrl.js";
import { ClickTrack,PVTrack } from "src/static/utils/eventTrack.js";
import { jumpPage } from "src/static/utils/utils.js";

export default {
  data() {
    return {
      bStop: false,
      detailsList: null,
    };
  },
  mounted() {
    PVTrack('/chooseDeloy.html');
    this.getPackageDetails();
    if(GetUrlParam('super-token') && GetUrlParam('aid')){
      localStorage.setItem('super-token', GetUrlParam('super-token'));
      localStorage.setItem('phone', GetUrlParam('aid'));
    };
  },
  methods: {
    async getPackageDetails(){
      const res = await PackageDetails();
      if(res.state == 200){
        this.detailsList = res.results.list;
      }
    },
    buyFunc(item) {
      const label = item.type == 0 ? '免费体验' : '立即购买';
      console.log(`${item.productName}-${label}`,' =4=================')
      ClickTrack(`${item.productName}-${label}`, '点击', label);
      setTimeout(() => {
        if (item.productName === '体验版') {
          this.getUserStatus();
        } else {
          this.openFunc(item.packageId);
        }
      },1000)
    },
    async getUserStatus() {
      if(this.bStop) return;
      this.bStop = true;
      setTimeout(()=>{
        this.bStop = false;
      }, 1000);
      const res = await userStatus();
      if(res.state == 200){
        if (res.results.status == -1) {
          window.open('/experienceLogin.html');
          // window.location.href="experienceLogin.html";
        }else if(res.results.status == 9){
          ELEMENT.MessageBox.confirm("您的测试时间已结束，请购买正式版系统", { 
            closeOnClickModal: false,
            confirmButtonText: "购买正式系统",
            center: true })
              .then(() => {})
              .catch(() => {})
        }else if(res.results.status == 1000){
          ELEMENT.MessageBox.confirm("您已开通测试系统，可直接进入系统", { 
            closeOnClickModal: false,
            showCancelButton: false,
            confirmButtonText: "进入我的超级码®云",
            center: true })
              .then(() => { 
                window.open(
                  `${res.results.value}/apiInterface/interface/hydra-user/api/v1/user/refresh-and-redirect?token=${localStorage.getItem('super-token')}`
                );
              })
              .catch(() => {})
        }
      }
    },

    openFunc(packageId){
      localStorage.setItem("packageId", packageId);
      if (localStorage.getItem("super-token") && localStorage.getItem("phone")) {
        const search = window.location.search;
        if(search.indexOf("reselect") >= 0) {
          window.location.href = "openContent.html?type=reselect&keyWord=newId"
        }if(search.indexOf("changePackage") >= 0) {
          window.location.href = "openContent.html?type=changePackage&keyWord=newId"
        }else{
          if(this.bStop) return;
          this.bStop = true;
          setTimeout(()=>{
            this.bStop = false;
          },1000);
          this.getStatus();
        }
      } else {
        jumpPage("/openIdentification.html");
      }
    },
    // 取消订单
    async cancelOrderFunc(results){
      const res = await cancelOrder({orderNo:results.value})
      if(res.state == 200){
        window.location.href = "openContent.html?type=reselect&keyWord=newId"
      }
    },
    //显示冻结信息
    freezeMsg() {
      ELEMENT.MessageBox.confirm("实名认证流程已冻结,请联系客服人员：18958077257", { 
        closeOnClickModal: false,
        confirmButtonText: "我知道啦",
        showCancelButton: false,
        center: true })
          .then(() => {})
          .catch(() => {})
    },
    async getStatus() {
      const res = await openStatus();
      if (res.state == 200) {
        if(res.results.status == "-1"){
          window.location.href = "openContent.html"
        }else if(res.results.status == "1000"){
          ELEMENT.MessageBox.confirm("您的手机号已开通系统，可直接进入系统", { 
            closeOnClickModal: false,
            cancelButtonText: "取消",
            confirmButtonText: "进入我的超级码®云",
            showClose: false,
            center: true })
              .then(() => { skipFunc(res.results); })
              .catch(() => {})
        }else if(res.results.status == "3"){
          this.freezeMsg();
        }else if(res.results.status == "700" || res.results.status == "800"){
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", { 
            closeOnClickModal: false,
            cancelButtonText: "重新选择套餐",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true })
              .then(() => { skipFunc(res.results); })
              .catch(() => { this.cancelOrderFunc(res.results)})
        }else if(res.results.status == "999"){
          skipFunc(res.results);
        }else if(res.results.status == "a" || res.results.status == "600"){
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", { 
            closeOnClickModal: false,
            confirmButtonText: "继续完成",
            showClose: false,
            center: true })
              .then(() => { skipFunc(res.results); })
              .catch(() => {})
        }else if(res.results.status == "900"){
          ELEMENT.MessageBox.confirm("您的系统已过期，是否续费？", { 
            closeOnClickModal: false,
            cancelButtonText: "暂时不需要",
            confirmButtonText: "续费",
            showClose: false,
            center: true })
              .then(() => { window.location.href = "renewalSystem.html" })
              .catch(() => {})
        }else if(res.results.status == "400"){
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", { 
            closeOnClickModal: false,
            cancelButtonText: "重新选择套餐",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true })
              .then(() => { window.location.href = "openContent.html?type=reselect&keyWord=newId" })
              .catch(() => { window.location.href = "openContent.html?type=changePackage&keyWord=newId"})
        }else{
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", { 
            closeOnClickModal: false,
            cancelButtonText: "重新选择套餐",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true })
              .then(() => { skipFunc(res.results); })
              .catch(() => { window.location.href = "openContent.html?type=changePackage&keyWord=newId"})
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.deloyCont {
  display: flex;
  width: 1206px;
  // height: 540px;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;

  > div {
    flex: 1;
  }
  
  > div:nth-child(2) {
    border-left: 1px solid #DBDBDB;
    border-right: 1px solid #DBDBDB;
  }
  
  > div:nth-child(3) {
    border-right: 1px solid #DBDBDB;
  }
  > div:nth-child(4) {
    border-right: 1px solid #DBDBDB;
  }

  .detailsBox:hover{
    box-shadow: 0px 0px 21px 0px rgba(0, 8, 43, 0.19);
    position: relative;
    z-index: 999;
  }
  
  .name {
    width: 240px;
    height: 90px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
    line-height: 90px;
  }
  
  .function {
    background: linear-gradient(to right, #E0E0E0, #C7C4C4);
  }
  
  .detailsBox:nth-of-type(2) .name{
    background: linear-gradient(to right, #ADC7EC, #125BD7);
  }
  
  .detailsBox:nth-of-type(3) .name {
    background: linear-gradient(to right, #8CA7E1, #0E2D7D);
  }

  .detailsBox:nth-of-type(4) .name {
    background: linear-gradient(to right, #A29DD9, #280C62);
  }

  .detailsBox:nth-of-type(5) .name {
    background: linear-gradient(to right, #E4D69C, #B07E06);
  }
  
  .packageIdCont > p {
    height: 42px;
    margin: 0;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    color: #666666;
  }
  .packageIdCont p:nth-child(even) {
    background-color: #FBF9F8;
  }

  
  .experienceNow {
    height: 117px !important;
    text-align: center;
    font-size: 14px;
    color: #666666;
    background-color: #FBF9F8;
  }
  
  .experienceNow > p {
    margin: 0;
    padding: 0;
  }
  
  .nowColor {
    width: 160px;
    height: 42px;
    background: #008AFF;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    display: inline-block;
    line-height: 42px;
    cursor: pointer;
    transition: all .2s;
  }
  .nowColor:hover{
    background: #66b1ff;
  }

  .money, .price {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FF6254;
  }
  
  .money {
    font-size: 14px;
  }
  
  .price {
    font-size: 30px;
    height: 22px;
    display: inline-block;
    line-height: 22px;
    padding-top: 13px;
    padding-bottom: 20px;
  }
  
  .sale {
    height: 117px !important;
    line-height: 117px !important;
  }
  
  .ensure {
    width: 20.3px;
    height: 20.3px;
    vertical-align: middle;
  }
  
  .platform {
    > img {
      float: left;
    }
    
    > span {
      margin-left: -52px;
      color: #FEBD17;
    }
  }
}
</style>
