<template>
  <div class="about-part1-wrap about-main">
    <div class="about-item2 common-item">
      <div class="item-title">{{ data[0].fields[0].bta }}</div>
      <div class="item-detail-first">
        <div>
          <img :src="data[0].fields[0].gss | imgSrc" alt="" class="firstImg" />
        </div>
        <div class="item-details" v-html="data[0].fields[0].gsjjfw"></div>
      </div>
    </div>
    <!-- 第二部分 -->
    <div class="about-item2-1">
      <div class="advTitle">{{ data[3].fields[0].gsys }}</div>
      <img :src="data[3].fields[0].gsysnr | imgSrc" style="width: 1200px" />
    </div>
    <!-- 第三部分 -->
    <div class="about-item3">
      <div class="content-width">
        <div class="item-title">
          {{ data[1].fields[0].btb }}
        </div>
        <div v-html="data[1].fields[0].wbcp" class="thirdCont"></div>
        <div class="item-detail">
          <img :src="data[1].fields[0].hygxt | imgSrc" />
        </div>
      </div>
    </div>
    <!-- 第四部分 -->
    <div class="about-item4 common-item">
      <div class="content-width">
        <div class="item-title">{{ data[2].fields[0].jsbt }}</div>
        <div class="item-detail">
          <div v-html="data[2].fields[0].fwbbb" class="leftCont"></div>
          <div>
            <img :src="data[2].fields[0].jslltp | imgSrc" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 第五部分 -->
    <div class="about-item5">
      <div class="item-title">{{ data[4].fields[0].ylyftdb }}</div>
      <div class="item-detail">
        <div v-html="data[4].fields[0].ylyfnr" class="bottomCont"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { functionData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
export default {
  data() {
    return {
      data: "",
      num: 0,
    };
  },
  mounted() {
    this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    async getFunction() {
      const res = await functionData({
        functionId: "5c253a58864e40d0b2f1ea07acd2f74b",
        current: 1,
        pageSize: 999,
        organizationId: config.funOrganizationId,
      });
      if (res.state == 200) {
        this.data = res.results.list[0].components;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.about-part1-wrap {
  text-align: center;
  padding-top: 120px;
  .common-item {
    min-height: 700px;
    .item-title {
      padding: 80px 0 64px;
      font-size: 32px;
      color: #151515;
    }
    .item-detail-first {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // img {
      //   display: inline-block;
      //   width: 580px;
      //   height: 500px;
      // }
      .item-details {
        width: 550px;
        text-align: left;
      }
      // p {
      //   width: 600px;
      //   font-size: 15px;
      //   line-height: 1.75;
      //   text-align: justify;
      //   color: #8e8e8e;
      // }
    }
  }
  .about-item2 {
    background: #fff;
    padding-bottom: 50px;

    .content-width {
      margin: 110px auto 0;
      .item-detail {
        p {
          font-size: 15px;
          color: #8e8e8e;
        }
      }
    }
  }
  .firstImg {
    width: 442px;
  }
  .about-item2-1 {
    // background: #f2f4fa;
    background: url("static/images/about/gsBg.png") no-repeat;
    background-size: 100% 100%;
    .advTitle {
      padding: 80px 0 64px;
      font-size: 32px;
      color: #fff;
    }
    img {
      height: 549px;
      width: 1200px;
      padding-bottom: 50px;
    }
    .item-title {
      padding: 80px 0 64px;
      font-size: 32px;
      color: #151515;
      font-weight: 500;
    }
    .item-detail {
      height: 510px;
      width: 1200px;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0 70px;
      background: #fff;
      -webkit-box-shadow: 0px 10px 20px 0px rgba(227, 229, 232, 0.75);
      box-shadow: 0px 10px 20px 0px rgba(227, 229, 232, 0.75);
      .item-width {
        width: 1059px;
        margin: 0 auto;
      }
      &-content {
        padding: 56px 19px 0;
        border-bottom: 1px solid #e8e8e8;
        overflow: hidden;
        display: flex;
        justify-content: space-around;
        .product-dec-div {
          height: 139px;
          text-align: center;
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
        .product-dec-list {
          cursor: pointer;
          width: 130px;
          display: inline-block;
          padding-bottom: 16px;
          border-bottom: 3px solid transparent;
          img {
            display: inline-block;
            width: 70px;
            height: 70px;
            margin-bottom: 26px;
          }
          .product-dec-li-title {
            font-size: 18px;
            color: #151515;
            width: 250px;
            text-align: center;
            margin-left: -60px;
          }
          &.active {
            border-bottom: 3px solid #00a4ff;
            color: #418bff;
          }
        }
      }
      &-text {
        padding-top: 37px;
        font-size: 15px;
        line-height: 26px;
        color: #8e8e8e;
        text-align: justify;
        display: none;
      }
    }
    .block {
      display: block;
    }
  }
  .about-item3 {
    // height: 880px;
    // background: url("static/images/about/about-item3-bg.png") no-repeat;
    // background-size: 100% 100%;
    // color: #fff;
    background: #f2f4fa;
    padding-bottom: 74px;
    img {
      width: 887px;
    }
    .item-title {
      padding: 80px 0 54px;
      font-size: 32px;
      color: #151515;
      font-weight: 500;
    }
    .thirdCont {
      width: 1200px;
      margin: 0 auto;
      text-align: left;
      padding-bottom: 50px;
      span {
        background: none;
      }
    }
  }
  .about-item4 {
    background: #fff;
    .item-title {
      padding: 80px 0 64px;
    }
    .leftCont {
      width: 550px;
      text-align: left;
    }
    img {
      width: 472px;
    }
    .item-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
    }
  }
  .about-item5 {
    height: 354px;
    color: #fff;
    background: url("static/images/about/yfBg.png") no-repeat;
    background-size: 100% 100%;
    .item-title {
      padding: 80px 0 64px;
      font-size: 32px;
      font-weight: 500;
    }
    .bottomCont {
      text-align: left;
      line-height: 30px;
    }
    .item-detail {
      width: 1200px;
      box-sizing: border-box;
      margin: 0 auto;
      // .item-width {
      //   width: 1059px;
      //   margin: 0 auto;
      //   .item-detail-content {
      //     padding: 56px 19px 0;
      //     border-bottom: 1px solid #e8e8e8;
      //     overflow: hidden;
      //     display: flex;
      //     justify-content: space-between;
      //     li {
      //       cursor: pointer;
      //       width: 165px;
      //       padding-bottom: 16px;
      //       border-bottom: 4px solid transparent;
      //       i {
      //         display: inline-block;
      //         // width: 57px;
      //         height: 57px;
      //         margin-bottom: 26px;
      //       }
      //       div {
      //         font-size: 18px;
      //         color: #151515;
      //       }
      //       &.active {
      //         border-bottom: 4px solid #00a4ff;
      //         color: #418bff;
      //       }
      //     }
      //   }
      //   .item-detail-text {
      //     padding-top: 37px;
      //     font-size: 15px;
      //     line-height: 26px;
      //     color: #a1a1a1;
      //     text-align: justify;
      //   }
      // }
    }
    .block {
      display: block !important;
    }
  }
  .about-item6 {
    height: 715px;
    background: url("static/images/about/about-item6-bg.png");
    color: #fff;
    .content-width {
      width: 1200px;
      margin: 0 auto;
    }
    .item-title {
      padding: 80px 0 64px;
      font-size: 32px;
    }
    .item-detail {
      display: block;
      font-size: 15px;
      line-height: 26px;
      text-align: left;
      margin: 30px 0;
    }
  }
}

.showModule {
  display: block;
}
</style>
