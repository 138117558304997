<template>
  <div class="image-item">
    <el-dropdown placement="top-end" @command="handleCommand">
      <img :src="src" alt="" />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="upload">重新上传</el-dropdown-item>
        <el-dropdown-item command="remove">删除</el-dropdown-item>
        <el-dropdown-item command="open">查看</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      center
    >
      <img class="magnifying" :src="src" alt=""/>
    </el-dialog>
  </div>
</template>

<script>
const imgSrc = require("../../utils/imgSrc");
const imageChoose = require("./imageChoose");
module.exports = {
  data() {
    return {
      dialogVisible: false,
    };
  },
  props: {
    value: {
      type: String,
    },
  },
  computed: {
    src() {
      return imgSrc(this.value);
    },
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    async upload() {
      const imgId = await imageChoose();
      this.$emit("change", imgId);
    },
    handleCommand(cmd) {
      cmd === "remove" && this.$emit("remove", this.value);
      cmd === "upload" && this.upload();
      cmd === "open" && this.open();
    },
  },
};
</script>

<style scoped lang="less">
.image-item {
  display: inline-block;
  vertical-align: top;
  width: 86px;
  height: 86px;
  line-height: 86px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 0;
  text-align: center;

  img {
    max-width: 86px;
    max-height: 86px;
    display: inline-block;
    vertical-align: middle;
  }
  .magnifying {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
}
</style>