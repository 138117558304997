<template>
  <div class="change-password">
    <div class="main-box">
      <div class="logo-main" style="display:none">
        <a id="logo" class="logo-btn">
          <img src="static/images/platformLogo.png" />
        </a>
      </div>
      <div class="form-box">
        <div class="stepStyle">
          <el-steps :active="active" align-center>
            <el-step title="填写手机号" :icon="active > 0 ? 'el-icon-circle-check' : ''"></el-step>
            <el-step title="设置新密码" :icon="active > 1 ? 'el-icon-circle-check' : ''"></el-step>
            <el-step title="完成设置" :icon="active > 2 ? 'el-icon-circle-check' : ''"></el-step>
          </el-steps>
        </div>
        <el-form
          :model="form"
          class="demo-form-inline"
          :rules="rules"
          ref="form"
          key="form"
          v-if="active == 0"
        >
          <el-form-item prop="openTel">
            <el-input :maxlength="11" v-model="form.openTel" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item prop="numCode" class="canvas-code">
            <el-input
              :maxlength="4"
              v-model="form.numCode"
              placeholder="请输入验证码"
              class="send-code-input"
            />
            <div class="code-img" @click="getCodeUrl">
              <img v-if="codeImgUrl" :src="codeImgUrl" />
            </div>
          </el-form-item>
          <el-form-item prop="openCode" class="send-code">
            <el-input :maxlength="6" v-model="form.openCode" placeholder="请输入短信验证码" />
            <el-button class="send-btn" :disabled="beingSent" @click="sendCodeFunc()">{{
              sendText
            }}</el-button>
          </el-form-item>
          <div class="operating-btn">
            <el-button type="primary" :disabled="disabled" :loading="disabled" @click="next('form')"
              >下一步</el-button
            >
          </div>
        </el-form>
        <el-form
          :model="form1"
          class="demo-form-inline"
          :rules="rules1"
          ref="form1"
          key="form1"
          v-if="active == 1"
        >
          <el-form-item prop="password">
            <el-input
              :maxlength="16"
              v-model="form1.password"
              placeholder="输入密码"
              show-password
            />
            <p class="psw-msg">设置6-16个字符，包含数字和英文字母组合，不能包含空格</p>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <el-input
              :maxlength="16"
              v-model="form1.confirmPassword"
              placeholder="确认密码"
              show-password
            />
          </el-form-item>
          <div class="operating-btn">
            <el-button
              type="primary"
              :disabled="disabled"
              :loading="disabled"
              @click="next('form1')"
              >提交</el-button
            >
          </div>
        </el-form>
        <div class="succeed-box" v-if="active == 2">
          <p class="succeed-msg"><em></em>重置密码成功!</p>
          <el-button type="primary" :disabled="disabled" :loading="disabled" @click="passwordFunc()"
            >进入我的超级码<sup>®</sup>云</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  sendCode,
  checkPhoneCode,
  forgotPassword,
  webLogin,
  openStatus,
  codeToken,
  codeUrl,
  checkAbcCode,
  checkPhone,
} from "src/static/services/website";
import md5 from "md5";
import { skipFunc } from "src/static/utils/webskipUtils.js";
export default {
  data() {
    let validNumCode = async (rule, value, callback) => {
      let propsData = {
        codeResult: this.form.numCode,
        codeToken: this.codeToken,
      };
      let res = await checkAbcCode(propsData);
      if (res.state == 200) {
        callback();
      } else {
        callback(new Error("验证码不正确"));
      }
    };
    let validPhone = async (rule, value, callback) => {
      let res = await checkPhone(this.form.openTel);
      if (res.state == 200) {
        if (res.results.value == 1001) {
          callback();
        } else {
          callback(new Error("账号还未开通系统，无法修改密码"));
        }
      } else {
        callback();
      }
    };
    let validPassword = (rule, value, callback) => {
      if (this.form1.password == this.form1.confirmPassword) {
        callback();
      } else {
        callback(new Error("两次密码输入不一致"));
      }
    };
    return {
      codeToken: "",
      codeImgUrl: "",
      active: 0,
      timer: null,
      time: 60,
      sendText: "获取验证码",
      beingSent: false,
      disabled: false,
      form: {
        openTel: "",
        numCode: "",
        openCode: "",
      },
      rules: {
        openTel: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^1[3-9]\d{9}$/, message: "请填写正确的手机号", trigger: "blur" },
          { validator: validPhone, trigger: "blur" },
        ],
        numCode: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^.{4}$/, message: "请输入4位验证码", trigger: "blur" },
          { validator: validNumCode, trigger: "blur" },
        ],
        openCode: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^.{6}$/, message: "请输入6位验证码", trigger: "blur" },
        ],
      },
      form1: {
        password: "",
        confirmPassword: "",
      },
      rules1: {
        password: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
            message: "密码格式不正确",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { validator: validPassword, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getCodeToken();
  },
  methods: {
    async getCodeToken() {
      const res = await codeToken();
      if (res.state == 200) {
        this.codeToken = res.results.value;
        this.getCodeUrl();
      }
    },
    getCodeUrl() {
      this.codeImgUrl = codeUrl(this.codeToken);
    },
    validForm(formInline) {
      return new Promise((resolve) => {
        this.$refs[formInline].validate((valid) => {
          resolve(valid);
        });
      });
    },
    validItem(formName, itemName) {
      return new Promise((resolve) => {
        this.$refs[formName].validateField(itemName, (valid) => {
          resolve(valid);
        });
      });
    },
    async sendCodeFunc() {
      const valid = await this.validItem("form", "openTel");
      const valid2 = await this.validItem("form", "numCode");
      if (valid || valid2) {
        return;
      }
      this.beingSent = true;
      this.sendText = "倒计时" + this.time + "s";
      this.timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.beingSent = false;
          this.time = 60;
          this.sendText = "获取验证码";
          clearInterval(this.timer);
        } else {
          this.sendText = "倒计时" + this.time + "s";
        }
      }, 1000);
      let res = await sendCode({ phoneNumber: this.form.openTel });
      if (res.state != 200) {
        this.beingSent = false;
        this.time = 60;
        this.sendText = "获取验证码";
        clearInterval(this.timer);
      }
    },
    async next(formInline) {
      const valid = await this.validForm(formInline);
      if (!valid) {
        return;
      }
      this.disabled = true;
      if (formInline == "form") {
        let propsData = {
          phone: this.form.openTel,
          code: this.form.openCode,
        };
        const res = await checkPhoneCode(propsData);
        if (res.state == 200) {
          this.active++;
        }
        this.disabled = false;
      } else {
        let propsData = {
          account: this.form.openTel,
          password: md5(this.form1.password),
          code: this.form.openCode,
          codeResult: this.form.numCode,
          codeToken: this.codeToken,
        };
        const res = await forgotPassword(propsData);
        if (res.state == 200) {
          this.active++;
        }
        this.disabled = false;
      }
    },

    //获取开通状态
    async getStatus() {
      const res = await openStatus();
      if (res.state == 200) {
        skipFunc(res.results);
      }
      this.disabled = false;
    },
    // 进入系统
    async passwordFunc() {
      this.disabled = true;
      let propsData = {
        password: md5(this.form1.password),
        account: this.form.openTel,
      };
      const res = await webLogin(propsData);
      this.bstop = false;
      if (res.state == 200) {
        localStorage.setItem('super-token', res.results.token);
        localStorage.setItem('phone', res.results.token);
        this.getStatus();
      } else {
        ELEMENT.Message.warning(res.msg);
        this.disabled = false;
      }
    },
  },
  watch: {
    //监听输入
    form: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        this.form.openTel = val.openTel.replace(/\D/g, "");
        this.form.openCode = val.openCode.replace(/\D/g, "");
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.change-password {
  min-height: 100vh;
  background: #f0f1f5;
  .main-box {
    width: 1200px;
    padding-top: 36px;
    padding-bottom: 56px;
    margin: 0 auto;
    .logo-main {
      margin-bottom: 33px;
    }
    .form-box {
      height: 750px;
      background: #fff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 40px 350px 0;
    }
    .send-code {
      position: relative;
      .send-btn {
        border: none;
        position: absolute;
        background: none;
        right: 16px;
        top: 0;
        padding: 0;
        line-height: 40px;
        cursor: pointer;
        color: #008aff;
        user-select: none;
      }
    }
    .canvas-code {
      position: relative;
      .send-code-input {
        width: 390px;
      }
      .code-img {
        display: block;
        width: 100px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        overflow: hidden;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .stepStyle {
      margin-bottom: 58px;
    }
    .operating-btn {
      margin-top: 40px;
      button {
        width: 100%;
      }
    }
  }
  .succeed-box {
    text-align: center;
    .succeed-msg {
      font-size: 28px;
      color: #6dd56e;
      font-weight: 600;
      margin-top: 10px;
      em {
        vertical-align: -36%;
        display: inline-block;
        margin-right: 18px;
        width: 61px;
        height: 66px;
        background: url("static/images/icon/succeed_icon.png") center no-repeat;
      }
    }
  }
  .psw-msg {
    font-size: 13px;
    margin: 16px 0 4px 10px;
    line-height: 1;
    color: #bfbfbf;
  }
}
</style>
