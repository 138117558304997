Vue.use(require("../components/index.js"));

function renderComponent(id, Component) {
  document.getElementById(id) &&
    new Vue({
      render: (h) => h(Component),
    }).$mount(`#${id}`);
}

$(function () {
  renderComponent("productService", require("./modules/chooseDeloy/productService.vue"));
  renderComponent("materials", require("./modules/chooseDeloy/Materials.vue"));
  renderComponent("slideshow", require("./modules/chooseDeloy/slideshow.vue"));
  renderComponent("experienceLogin", require("./modules/chooseDeloy/experienceLogin.vue"));
  renderComponent("loginBox", require("./modules/chooseDeloy/login.vue"));
  renderComponent("changePassword", require("./modules/chooseDeloy/changePassword.vue"));
  renderComponent("openIdentification", require("./modules/chooseDeloy/openIdentification.vue"));
  renderComponent("certification", require("./modules/chooseDeloy/openCertification.vue"));
  renderComponent("topLogo", require("./modules/chooseDeloy/topLogo.vue"));
  renderComponent("renewalSystem", require("./modules/chooseDeloy/renewalSystem.vue"));
  renderComponent("renewalSucceed", require("./modules/chooseDeloy/renewalSucceed.vue"));
  renderComponent("steps", require("./modules/chooseDeloy/Steps.vue"));
  renderComponent("homeSlide", require("./modules/chooseDeloy/homeSlide.vue")); //首页轮播
  // 了解超级码
  renderComponent("aboutOne", require("./modules/chooseDeloy/aboutOne.vue"));
  // 发展历程
  renderComponent("aboutTwo", require("./modules/chooseDeloy/aboutTwo.vue"));
  renderComponent("aboutThree", require("./modules/chooseDeloy/aboutThree.vue"));
  renderComponent("aboutFour", require("./modules/chooseDeloy/aboutFour.vue"));
  renderComponent("aboutFive", require("./modules/chooseDeloy/aboutFive.vue"));
  renderComponent("aboutSix", require("./modules/chooseDeloy/aboutSix.vue"));
  renderComponent("aboutSeven", require("./modules/chooseDeloy/aboutSeven.vue"));
  renderComponent("aboutEight", require("./modules/chooseDeloy/aboutEight.vue"));
  // 产品服务
  renderComponent("productServiceNew", require("./modules/chooseDeloy/productServiceNew.vue"));
  // 应用案例
  renderComponent("caseShowNew", require("./modules/chooseDeloy/caseShowNew.vue"));
});

require("./modules/index/footer");

//禁止右击事件
function rightClick() {
  window.event.returnValue = false;
}
document.oncontextmenu = rightClick;

document.addEventListener("copy", function (e) {
  e.preventDefault();
});

console.log(
  "%c了解我们：https://www.chaojima.com",
  "color: #2776F9;font-size: 14px;text-decoration: underline;"
);

$(function () {
  $("#logo").css("cursor", "pointer");
  $("#logo").click(() => {
    const origin = window.location.origin;
    if (origin.includes("localhost") || origin.includes("kf315.net")) {
      window.open("https://mzt315-02.kf315.net");
    } else {
      window.open("https://mzt315.com")
    }
  });
});

$(function () {
  const origin = window.location.origin;
  const script = document.createElement("script");
  const sParent = document.getElementsByTagName("script")[0];
  if (origin.includes("localhost") || origin.includes("kf315.net")) {
    script.src = "https://v1.cnzz.com/z_stat.php?id=1281282466&web_id=1281282466&async=1"
  } else {
    script.src = "https://v1.cnzz.com/z.js?id=1281298219&web_id=1281298219&async=1";
  }
  
  var _czc = _czc || [];
  script.onload = () => {
    // 关闭自动PV统计，防止页面的流量被统计双倍。
    window._czc && window._czc.push(['_setAutoPageview', false]);
  }
  sParent.parentNode.insertBefore(script, sParent);
});