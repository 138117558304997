<template>
  <div class="cjm-image-uploader">
    <image-item
        v-for="(item,index) in value"
        @remove="handleRemove(index)"
        @change="handleChange($event,index)"
        :value="item"
        :key="`${item}-${index}`" />
    <upload-item v-if="allowUpload" @success="handleUploadSuccess" />
  </div>
</template>

<script>
const uploadItem = require('./UploadItem.vue');
const imageItem = require('./ImageItem.vue');
module.exports = {
  components: { uploadItem, imageItem },
  props: {
    maxLength: {
      type: Number,
      default: 1
    },
    value: {
      type: Array,
      default: []
    }
  },
  data() {
    return {};
  },
  computed: {
    allowUpload() {
      return this.value.length < this.maxLength;
    }
  },
  methods: {
    handleUploadSuccess(imgId) {
      const newValue = [].concat(this.value).concat(imgId);
      this.$emit('input', newValue);
    },
    handleRemove(index) {
      let newValue = [].concat(this.value);
      newValue[index] = null;
      newValue = newValue.filter(Boolean);
      this.$emit('input', newValue);
    },
    handleChange(imgId, index) {
      const newValue = [].concat(this.value);
      newValue[index] = imgId;
      this.$emit('input', newValue);
    }
  }
}
</script>

<style scoped lang="less">
.cjm-image-uploader {
  font-size: 0;
}
</style>