<template>
  <div class="about-part6-wrap middle about-main">
    <div class="main-block-wrap main-block11-wrap content-width">
      <div class="main-block">
        <h1>
          <span>企业文化</span>
        </h1>
        <ul class="main-block-wrap-content">
          <li>
            <span class="main-block1-icon"></span>
            <span class="main-block1-title">核心价值观</span>
            <span class="main-block1-info">敬业、有信、创新</span>
            <span class="main-block1-info">快乐工作、享受生活、勇于折腾</span>
          </li>
          <li>
            <span class="main-block1-icon"></span>
            <span class="main-block1-title">我们的使命</span>
            <span class="main-block1-info">让信任变得简单</span>
          </li>
          <li>
            <span class="main-block1-icon"></span>
            <span class="main-block1-title">我们的愿景</span>
            <span class="main-block1-info">用数字赋能产业，助区域经济发展</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-block-wrap main-block2-wrap">
      <div class="main-block content-width">
        <h1>
          <span>折腾甲骨文</span>
        </h1>
        <div class="main-block2-details">
          <span>企业文化核心-折腾，如何适应日新月异的变化，唯有折腾；</span>
          <span>折腾是企业保持激情、创新和活力的关键</span>
          <span>“情趣用品”是折腾文化的延伸</span>
        </div>
        <ul class="main-block-wrap-content">
          <li>
            <img src="static/images/about/about-ing4.png" />
            <span class="main-block2-title">有 “情”</span>
            <span class="main-block2-info"
              >对工作有激情、对团队有感情、对客户有真情</span
            >
          </li>
          <li>
            <img src="static/images/about/about-ing5.png" />
            <span class="main-block2-title">有 “趣”</span>
            <span class="main-block2-info">好玩、娱乐化、互动化的企业氛围</span>
          </li>
          <li>
            <img src="static/images/about/about-ing6.png" />
            <span class="main-block2-title">有 “用”</span>
            <span class="main-block2-info"
              >做一个有价值，对社会、团队和家庭有用的人</span
            >
          </li>
          <li style="margin-right: 0">
            <img src="static/images/about/about-ing7.png" />
            <span class="main-block2-title">有 “品”</span>
            <span class="main-block2-info"
              >不仅要人品好，产品也要有品牌，有品位，引领行业应用潮流</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="main-block-wrap main-block4-wrap">
      <div class="main-block content-width">
        <h1>
          <span>甲骨文的六脉神剑</span>
        </h1>
        <ul>
          <li class="main-block-item">
            <img src="static/images/about/about-ing13.png" />
            <p>
              <span class="main-block-item-line"></span>
              <span class="main-block-item-title">敬业</span>
              <span class="main-block-item-info">爱岗敬业，爱司如家</span>
            </p>
          </li>
          <li class="main-block-item">
            <img src="static/images/about/about-ing14.png" />
            <p>
              <span class="main-block-item-line"></span>
              <span class="main-block-item-title">有信</span>
              <span class="main-block-item-info">诚实守信，言行坦荡</span>
            </p>
          </li>
          <li class="main-block-item" style="margin-right: 0">
            <img src="static/images/about/about-ing12.png" />
            <p>
              <span class="main-block-item-line"></span>
              <span class="main-block-item-title">创新</span>
              <span class="main-block-item-info">创造创新，终身学习</span>
            </p>
          </li>
          <li class="main-block-item">
            <img src="static/images/about/about-ing15.png" />
            <p>
              <span class="main-block-item-line"></span>
              <span class="main-block-item-title">快乐工作</span>
              <span class="main-block-item-info">热爱工作，积极乐观</span>
            </p>
          </li>
          <li class="main-block-item">
            <img src="static/images/about/about-ing18.png" />
            <p>
              <span class="main-block-item-line"></span>
              <span class="main-block-item-title">享受生活</span>
              <span class="main-block-item-info">身常锻炼，心有山海</span>
            </p>
          </li>
          <li class="main-block-item" style="margin-right: 0">
            <img src="static/images/about/about-ing16.png" />
            <p>
              <span class="main-block-item-line"></span>
              <span class="main-block-item-title">勇于折腾</span>
              <span class="main-block-item-info">拥抱变化，乐于创新</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-block-wrap xiaomage">
      <div class="main-block">
        <h1>
          <span>小甲的形象代言人</span>
        </h1>
        <div>
          <span
            >集智慧、帅气于一身，人见人爱、花见花开的小码哥闪亮登场！<br />
            身着超人披风，一路披荆斩棘勇往直前，开疆拓土；<br />
            头顶扫码仪器，每日火眼金睛一丝不苟，肃清市场；<br />
            面画二维脸谱，誓为优质产品赋予身份，保驾护航。<br />
            时而活泼，思维开阔；时而严苛，心细如发；<br />
            时而颜控，爱美如命；时而内涵，深沉大气。<br />
            我就是有情有趣有用有品的小码哥，<br />
            我为自己代言，我为小甲代言。</span
          >
          <img src="static/images/about/xiaomage.png" />
        </div>
      </div>
    </div>
    <div class="main-block-wrap main-block5-wrap">
      <div class="main-block content-width">
        <h1>
          <span>甲骨文PGS员工成长阶梯</span>
        </h1>
        <span
          >除了拥有透明直观的PM双通道职业发展体系外，甲骨文还有着独特的PGS员工成长阶梯模式。甲骨文PGS体系借鉴军队军衔模式，从列兵、班级、排级、连级一直到将军，面向全体员工有效，与职责权限无关，面向全体员工有效，代表员工在甲骨文的成长历程、员工贡献、自我价值。</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { functionData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
export default {
  data() {
    return {
      // data: "",
    };
  },
  mounted() {
    // this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    // async getFunction() {
    //   const res = await functionData({
    //     functionId: "b0f469311d4943de9ea0cf5855f946dc",
    //     current: 1,
    //     pageSize: 999,
    //     organizationId: config.funOrganizationId,
    //   });
    //   if (res.state == 200) {
    //     this.data = res.results.list.sort((a, b) => {
    //       return parseInt(b.qzz) - parseInt(a.qzz); //根据权重进行排序
    //     });
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
//
.about-part6-wrap {
  .main-block-wrap {
    width: 100%;
    background: #fff;
    padding: 20px 0 80px;
    .main-block {
      display: block;
      margin: 0 auto;
      width: 1200px;
      text-align: center;
      h1 {
        width: 1200px;
        display: block; // height: 130px;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 64px;
        font-size: 100%;
        font-weight: 500;
        span {
          font-size: 32px; // line-height:130px;
          display: inline-block;
        }
      }
      .main-block-text {
        display: block;
        padding-left: 50px;
        font-size: 16px;
        line-height: 30px;
        width: 728px;
        text-align: justify;
      }
    }
  }
  .main-block-item {
    // border: 1px solid #dddddd;
    width: 380px;
    height: 198px;
    margin-right: 30px;
    background: #fff;
    margin-bottom: 40px;
    padding: 49px 0 0 34px;
    box-shadow: 0px 0px 20px 0px rgba(222, 222, 233, 0.75);
    img {
      margin-top: 7px;
      width: 70px;
      height: 70px;
      margin-right: 35px;
    }
    span {
      display: block;
    }
    .main-block-item-line {
      width: 20px;
      height: 3px;
      background: #ffa800;
      margin-bottom: 6px;
    }
    .main-block-item-title {
      font-size: 18px;
      color: #000;
      line-height: 42px;
      text-align: left;
    }
    .main-block-item-info {
      font-size: 15px;
      line-height: 20px;
      color: #8e8e8e;
    }
  }
  .main-block11-wrap {
    margin-top: 110px;
    ul {
      display: flex;
      justify-content: space-around;
      li {
        text-align: center;
        margin-top: 20px;
        .main-block1-title {
          font-size: 18px;
          line-height: 48px;
          display: block;
        }
        .main-block1-info {
          font-size: 15px;
          line-height: 22px;
          display: block;
          color: #8e8e8e;
        }
        .main-block1-icon {
          margin-top: 90px;
          display: block;
          width: 70px;
          height: 70px;
          margin: 0 auto;
        }
        &:nth-of-type(1) {
          .main-block1-icon {
            background: url(static/images/about/about-ing1.png) no-repeat;
          }
          .main-block1-icon:hover {
            background: url(static/images/about/about-ing1-hover.png) no-repeat;
          }
        }
        &:nth-of-type(2) {
          .main-block1-icon {
            background: url(static/images/about/about-ing2.png) no-repeat;
          }
          .main-block1-icon:hover {
            background: url(static/images/about/about-ing2-hover.png) no-repeat;
          }
        }
        &:nth-of-type(3) {
          .main-block1-icon {
            background: url(static/images/about/about-ing3.png) no-repeat;
          }
          .main-block1-icon:hover {
            background: url(static/images/about/about-ing3-hover.png) no-repeat;
          }
        }
      }
    }
  }
  .main-block2-wrap {
    background: #f8fafe;
    .main-block2-details {
      font-size: 15px;
      line-height: 20px;
      color: #8e8e8e;
      span {
        display: block;
      }
    }
    ul {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      li {
        &:first-child {
          background: url("static/images/about/about-ing8.png") no-repeat;
        }
        &:nth-child(2) {
          background: url("static/images/about/about-ing9.png") no-repeat;
        }
        &:nth-child(3) {
          background: url("static/images/about/about-ing10.png") no-repeat;
        }
        &:nth-child(4) {
          background: url("static/images/about/about-ing11.png") no-repeat;
        }
        width: 280px;
        height: 350px;
        margin-right: 20px;
        background: #000;
        text-align: center;
        img {
          margin-top: 90px;
        }
        .main-block2-title {
          font-size: 20px;
          color: #fff;
          display: block;
          line-height: 50px;
          margin-top: -14px;
          text-align: center;
          margin: 25px auto 0;
        }
        .main-block2-info {
          font-size: 15px;
          color: #fff;
          display: block;
          line-height: 28px;
          width: 226px;
          margin: 0 auto;
        }
      }
    }
  }
  .main-block4-wrap {
    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      li {
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box;
      }
    }
  }
  .xiaomage {
    background: #f8fafe;
    .main-block {
      width: 1200px;
      margin: 0 auto;
      h1 {
        span {
          font-size: 32px;
        }
      }
      div {
        display: flex;
        justify-content: space-around;
        span {
          font-size: 16px;
          line-height: 28px;
          text-align: left;
        }
        img {
          margin-top: -40px;
        }
      }
    }
  }
  .main-block5-wrap {
    height: 432px;
    background: url(static/images/about/about-ing17.png) no-repeat;
    background-size: 100% 100%;
    .content-width {
      h1 {
        span {
          margin-top: 15px;
        }
      }
      span {
        font-size: 16px;
        line-height: 28px;
        color: #fff;
        width: 770px;
        display: block;
        margin: 0 auto;
      }
    }
    h1 {
      color: #fff;
      font-size: 32px;
    }
  }
}
</style>
