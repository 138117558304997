const prefix = "/apiInterface/interface";
module.exports = {
  common: {
    FILE_UPLOAD: `${prefix}/hydra-open-thirdpart-service/hydra-open-third-party/api/v1/file/open/upload`,
  },
  area: {
    //获取所有省份信息
    GET_PROVINCES: `${prefix}/hydra-base-data/api/v1/address/first`,
    // 根据父级行政编码 获取所有子级行政区
    GET_CHILDREN: `${prefix}/hydra-base-data/api/v1/address/child`,
    // 根据行政区编码 获取行政区信息
    GET_DETAIL: `${prefix}/hydra-base-data/api/v1/address`,
    // 获取所有父级行政区
    GET_ALL_PARENTS: `${prefix}/hydra-base-data/api/v1/address/all-regions`,
  },
  industry: {
    GET_ALL_INDUSTRY: `${prefix}/hydra-base-data/api/v1//business/all`,
  },
};
