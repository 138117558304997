<template>
  <div class="caseShow-wine">
    <div class="caseSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>一粒粮食到一瓶美酒的全生命周期数字化解决方案</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      该框架以白酒溯源基因为核心脉络，以物联网、区块链、数据支撑平台以及人工智能分析等领
      先的数字化技术为支撑，完成从一滴美酒向一粒种子的反向追溯，此外，公司对农业、包材、
      生产、流通四大模块中全流程的信息和数据进行采集，并用信息化手段进行监控、可视化与分析，
      以点至线，以线及面，对质量溯源进行全方位管控。同时，公司建立企业级溯源可视化门户，实现
      企业级溯源分析与可视化，同时可提供公众查询服务。
    </div>
    <div class="carbonCont">
      <div class="carbonCont-top">
        <h1>项目介绍</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          结合《白酒质量安全追溯体系规范》要求，借助数字化推动白酒质量安全可追溯，实现顺向可
          追踪、逆向可溯源、风险可管控，发生质量安全问题时，产品可召回、原因可查清、责任可追究，
          切实落实质量安全主体责任，保障白酒质量安全。
        </div>
      </div>
    </div>
    <div class="carbonCont">
      <div class="carbonCont-top">
        <h1>企业诉求</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          公司整体质量系统存在追溯过程断点、信息孤岛现象、质量数据应用不足的问题。缺少统一的信息化平台；记录留存的数据密度与精度不足，难以运用数据科学进行深度分析。<br />
          企业计划建立一个统一的数字化产品溯源平台，对内支撑从粮食栽种、包装生产、酿造生产、营销渠道到市场消费的产品全生命周期溯源管理，对外面向政府与公众，成为该品牌走向世界的坚实保障。
        </div>
      </div>
    </div>
    <div class="carbonCont">
      <div class="carbonCont-top">
        <h1>解决方案</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          实施开展“产品全生命周期溯源项目”，搭建以该品牌白酒溯源基因为核心脉络，以物联网、区块链、
          数据支撑平台以及人工智能分析等领先的数字化技术为支撑，完成从一滴美酒向一粒种子的反向追溯，
          此外，公司对农业、包材、生产、流通四大模块中全流程的信息和数据进行采集，并用信息化手段进行
          监控、可视化与分析，以点至线，以线及面，对质量溯源进行全方位管控。同时，公司建立企业级溯源
          可视化门户，实现企业级溯源分析与可视化，同时可提供公众查询服务。
        </div>
      </div>
    </div>
    <div class="bot-carbon">
      <div class="carbonDesc">
        <div class="carbonDesc-only">
          <img src="static/images/product/numLeft.png" />
          <div class="onlyTitle">产品质量全生命周期</div>
          <img src="static/images/product/numRight.png" />
        </div>
        <img src="static/images/caseShow/wine1.png" />
      </div>

      <div class="supplyChain">
        <div class="carbonDesc-only">
          <img src="static/images/product/numLeft.png" />
          <div class="onlyTitle">供应链管理</div>
          <img src="static/images/product/numRight.png" />
        </div>
        <img src="static/images/caseShow/wine2.png" />
      </div>

      <div class="ascend">
        <div class="carbonDesc-only">
          <img src="static/images/product/numLeft.png" />
          <div class="onlyTitle">可追溯体系</div>
          <img src="static/images/product/numRight.png" />
        </div>
        <img src="static/images/caseShow/wine3.png" />
      </div>
      <div class="projectIntro">
        <div class="carbonCont">
          <div class="carbonCont-top">
            <h1>项目介绍</h1>
            <img src="static/images/product/blueBg.png" class="blueBg" />
          </div>
          <img src="static/images/caseShow/wine4.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@h: 1200px;
.caseShow-wine {
  h1 {
    position: relative;
    z-index: 9;
  }
  .caseSuper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
    h1 {
      padding: 0 15px;
    }
  }
  .codeText {
    font-size: 18px;
    color: #8e8e8e;
    line-height: 34px;
    text-align: left;
    padding-bottom: 40px;
    width: @h;
    margin: 0 auto;
  }

  .carbonCont {
    text-align: center;
    position: relative;
    width: @h;
    margin: 0 auto;
    h1 {
      padding-bottom: 22px;
    }
    .blueBg {
      position: absolute;
      top: 24px;
      left: 517px;
    }
    .topText {
      color: #666;
      font-size: 16px;
      padding-bottom: 30px;
      text-align: left;
      line-height: 34px;
    }
  }
  .bot-carbon {
    background: #fff;
    padding-top: 50px;
    .blueBg {
      position: absolute;
      top: 24px;
      left: 500px;
    }
    .supplyChain {
      background: #f6f9ff;
      text-align: center;
      padding: 50px 0;
      > img {
        padding-top: 30px;
      }
    }
    .ascend {
      text-align: center;
      padding: 60px 0;
    }
    .carbonDesc {
      width: @h;
      margin: 0 auto;
      > img {
        padding: 70px 0;
      }
    }
    .carbonDesc-only {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 16px;
      }
      .onlyTitle {
        font-size: 26px;
        padding: 0 15px;
      }
    }
  }
  .projectIntro {
    background: #f6f9ff;
    padding: 80px 0;
  }
}
</style>
