<template>
    <div>
      <div class="msg-box playVerify-msg">
        <div class="msg-label">输入到账金额</div>
        <div class="msg-main">
          <p class="playVerify-text"><em>√</em>打款已受理！预计5秒内到账</p>
          <div class="playVerify-main">
            <p class="playVerify-label">您收到的打款金额</p>
            <div class="playVerify-box">
              <input v-model="verifyNum" class="playVerify-price" maxlength="2">
              <b>￥0.</b>
            </div>
          </div>
        </div>
      </div>
      <p class="playVerifyMsg">完成认证后将无法更改认证企业信息</p>
      <div class="twoBtn">
        <el-button type="primary" :disabled="disabled" @click="playVerify()">完成认证</el-button>
      </div>
    </div>
</template>
<script>
import {authCheckAmount,authCheckAmountState,authFinish} from "src/static/services/website";
export default {
  data() {
    return {
      verifyNum:"",
      disabled:true,
      isFreeze: false,
    };
  },
  mounted() {
    this.getCheckState();
  },
  methods: {
    async getCheckState(){
      const res = await authCheckAmountState()
      if(res.state == 200 && res.internalErrorCode == 2001000){
        this.$emit("isFreezeFunc");
      }
    },
    async playVerify(){
      if(this.disabled){
        return ;
      }
      this.disabled = true;
      const res = await authCheckAmount({amount:this.verifyNum.length == 1? this.verifyNum/10 : this.verifyNum/100});
      this.verifyNum = "";
      if(res.state == 200){
        let internalErrorCodeObj = {
          '2001001':()=>{this.showMsg('您输入的打款金额有误，请重新提交，您还有2次机会');},
          '2001002':()=>{this.showMsg('您输入的打款金额有误，请重新提交，您还有1次机会');},
          '2001000':()=>{this.freezeMsg();},
          '2003002':()=>{this.showMsg('企业认证不存在');}
        }
        if (res.internalErrorCode in internalErrorCodeObj) {
          internalErrorCodeObj[res.internalErrorCode]();
        }else{
          this.authFinishFunc();
        }
      }
    },
    // 完成认证
    async authFinishFunc(){
      const res = await authFinish();
      if(res.state == 200){
        this.$emit("cutState");
      }
    },
    //显示错误信息
    showMsg(msg){
      ELEMENT.Message({message:msg,type:'warning',duration:5000});
    },
    //显示冻结信息
    freezeMsg(){
      this.$emit("isFreezeFunc");
      ELEMENT.MessageBox.confirm("您输入的打款金额有误，实名认证流程已冻结", { 
        closeOnClickModal: false,
        confirmButtonText: "申请人工审核",
        center: true })
          .then(() => { 
            this.artificialFunc()
           })
          .catch(() => {})
    },
    //人工认证弹层
    artificialFunc(){
      ELEMENT.MessageBox.confirm("请联系客服人员：18958077257", { 
        closeOnClickModal: false,
        confirmButtonText: "我知道啦",
        showCancelButton: false,
        center: true })
          .then(() => {})
          .catch(() => {})
    }
  },
  watch: {
    //
    verifyNum: function (val) {
      this.verifyNum = val.replace(/\D/g, "");
      if (this.verifyNum != "") {
        this.disabled = false;
      }else{
        this.disabled = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
  line-height: 1.8;
}
.twoBtn {
  margin-left: 140px;
}
.playVerifyMsg{
  font-size: 13px;
  line-height: 13px;
  color: #999;
  margin-left: 140px;
  margin-bottom: 26px;
}
.msg-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .msg-label{
    width: 140px;
    box-sizing: border-box;
    padding-right: 12px;
    text-align: right;
    color: #606266;
  }
  .msg-main{
    width: 486px;
    height: 88px;
    background: #FBFBFB;
    color: #333;
    box-sizing: border-box;
    padding-left: 33px;
    padding-top: 16px;
    position: relative;
    p{
      line-height: 2;
    }
    span{
      position: absolute;
      right: 25px;
      top: 40px;
      color: #008AFF;
      cursor: pointer;
    }
  }
}
.playVerify-msg{
  .msg-main{
    height: 130px;
    padding-left: 47px;
  }
  .playVerify-text{
    padding-top: 6px;
    margin-bottom: 19px;
    em{
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background: #6DD56E;
      color: #fff;
      text-align: center;
      line-height: 20px;
      margin-right: 6px;
      font-style: normal;
    }
  }
  .playVerify-main{
    display: flex;
    height: 36px;
    align-items: center;
  }
  .playVerify-label{
    margin-right: 10px;
  }
  .playVerify-box{
    width: 260px;
    height: 36px;
    position: relative;
    .playVerify-price{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 36px;
      border-radius: 4px;
      font-size: 13px;
      border: 1px solid #D9D9D9;
      color: #333;
      outline: none;
    }
    b{
      color: #333;
      font-weight: normal;
      position: absolute;
      left: 12px;
      top: 12px;
      font-size: 13px;
    }
  }
}
</style>
