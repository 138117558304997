<template>
  <div class="caseShow-numTea">
    <div class="caseSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>茶企ERP数字化应用整体解决方案</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      基于茶企从茶园到终端全生命周期数字化赋能，利用物联网设备、茶企ERP数字化管理平台、
      产品身份证（合格证）标识、数字画像驾驶舱等服务，为茶企业数字化转型提供一站式解决方案。
    </div>
    <div class="caseCont">
      <div class="caseCont-top">
        <h1>客户案例</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
    </div>
    <div class="caseText antifake">
      <div class="caseText-top">
        <h1 class="caseText-topTitle">
          助力区域政府品牌保护，安吉白茶码上溯源
        </h1>
      </div>
      <div>
        <!-- 项目介绍 -->
        <div>
          <img src="static/images/caseShow/icon1.png" class="icon" />
          <span class="itemIntro">项目介绍</span>
          <div class="introText">
            安吉白茶，浙江省湖州市安吉县特产，国家地理标志产品
          </div>
        </div>
        <!-- 企业诉求 -->
        <div>
          <img src="static/images/caseShow/icon2.png" class="icon" />
          <span>企业诉求</span>
          <div class="introText">
            物联网、移动互联等信息技术及智能农业设备在农业领域的应用，为了更好地加强安吉白茶原产地保护，提升品牌价值，保障安吉白茶经营企业和农户利益
          </div>
        </div>
        <!-- 解决方案 -->
        <div class="caseOk">
          <div>
            <img src="static/images/caseShow/icon2.png" class="icon" />
            <span>解决方案</span>
            <div class="introText">
              超级码科技股份为盈农园安吉白茶搭建的农业大脑模型，运用物联网传感技术和视频监控技术相结合的方式，
              对基地的环境状况进行远程实时监测，辅助企业对基地生产活动进行更加精细化的管理。通过农业智能化
              升级，可实现农业农产品种植、生产加工甚至流通全环节可视化、智能化升级，加快农业数字化转型，
              真正实现农产品从田间地头到餐桌的全程追踪追溯。
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-bottom: 50px">
          <img src="static/images/caseShow/tea1.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseList: [
        {
          bigTitle: "公牛产线数字化，一站式赋码新体验",
          // icon1: "static/images/caseShow/icon2.png",
          // icon2: "static/images/caseShow/icon2.png",
          // icon3: "static/images/caseShow/icon2.png",
          text1:
            "公牛集团股份有限公司创办于1995年，公司专注于以转换器、墙壁开关插座为核心的民用电工产品的研发、生产和销售。",
          text2:
            "国内插座产品整体质量水平偏低，行业内充斥着假冒伪劣产品，高档插座产品与低档产品的价格往往能相差几十倍，受利益驱使，经销商之间互相窜货，缭乱市场价格体系，最严重的情况是质量问题引发的消费者信任度缺失，导致正宗品牌声誉受损。",
          text3:
            "公牛集团数字化管理平台为公牛集团旗下产品的每一个开关插座提供唯一身份码，建立起产品数字化管理，实现从产品生产到消费终端的全生命周期监控和管理，严控假冒伪劣及窜货现象，提高渠道管控能力，增强经销商信心，同时结合企业自身自动化生产、仓储无人化操作，为其定制自动化产品赋码、数据采集、仓储系统无缝对接，实现产品数字化管理，为企业降低生产成本，增加产品的附加值，提升企业软实力，实现互联网企业转型。",
          imgs: "static/images/caseShow/indus1.png",
        },
        {
          bigTitle: "外资品牌本土化管控，岡本码上防窜",
          // icon1: "static/images/caseShow/icon2.png",
          // icon2: "static/images/caseShow/icon2.png",
          // icon3: "static/images/caseShow/icon2.png",
          text1:
            "冈本株式会社创立于昭和九年（1934年），是世界上最早开始生产安全套的厂家之一。公司总部设在日本东京，分别在日本的静冈、茨城、福岛三大城市设有产品研发和生产基地。随着公司的不断发展，冈本先後成立了香港（岡本）有限公司、美国（岡本）有限公司，将业务领域拓展到了亚洲及北美地区。1949年6月，岡本株式会社在日本挂牌上市，经过70余年的不懈努力，现今的岡本株式会社已发展成为日本最大的橡胶乳胶制品生产商。",
          text2:
            "近年来，假冒岡本品牌的伪劣产品充斥市场，且越演越烈。严重影响到了消费者对岡本产品的信任。",
          text3:
            "岡本高度重视，2021年岡本联合超级码科技股份搭建一套基于区块链、一物一码数字化管理平台，对产品进行全流程追溯，并和企业的ERP系统对接，和原有营销系统进行无缝对接，实现大数据整合和可视化管理，构建岡本基于产品一物一码全流程监管体系。",
          imgs: "static/images/caseShow/indus2.png",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@h: 1200px;
.caseShow-numTea {
  h1 {
    position: relative;
    z-index: 9;
  }
  .caseSuper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
    h1 {
      padding: 0 15px;
    }
  }
  .codeText {
    font-size: 18px;
    color: #8e8e8e;
    line-height: 34px;
    text-align: left;
    padding-bottom: 40px;
    width: @h;
    margin: 0 auto;
  }

  .caseCont {
    text-align: center;
    position: relative;
    width: @h;
    margin: 0 auto;
    h1 {
      padding-bottom: 22px;
    }
    .blueBg {
      position: absolute;
      top: 24px;
      left: 518px;
    }
  }
  .caseText {
    width: @h;
    margin: 0 auto;
    .caseText-top {
      background: url("static/images/caseShow/caseTitle.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-content: center;
      box-sizing: border-box;
      padding: 10px;
      h1 {
        margin: 0;
        padding-left: 55px;
      }
      .caseText-topCont {
        display: inline-block;
        padding-top: 12px;
        font-size: 20px;
      }
    }
    .caseText-intro {
      font-size: 18px;
      color: #8e8e8e;
      line-height: 34px;
      text-align: left;
      padding: 44px 0;
    }
    .caseText-bg {
      color: #fff;
      margin-bottom: 40px;
      .caseText-bgTitle {
        font-size: 30px;
        font-weight: bold;
        padding-left: 15px;
        padding-bottom: 35px;
      }
      .iconText {
        display: flex;
        margin-bottom: 30px;
        > img {
          height: 26px;
        }
        > div {
          width: 269px;
          line-height: 60px;
          line-height: 26px;
          padding-left: 20px;
        }
      }
    }
    .icon {
      vertical-align: middle;
      margin-top: -3px;
    }
    .itemIntro {
      font-size: 20px;
      padding-bottom: 10px;
      padding-top: 30px;
      display: inline-block;
    }
    .introText,
    .introText2 {
      color: #949494;
      line-height: 30px;
      padding-top: 15px;
      padding-bottom: 34px;
    }
    .introText2 {
      padding-top: 0;
    }
    .imgs {
      margin-bottom: 60px;
    }
    .caseOk {
      display: flex;
      justify-content: space-between;
      .introText {
        width: 840px;
      }
    }
  }
  .antifake {
    margin-top: 60px;
  }
  .dataShow {
    background: #fff;
  }
  .showImgBot {
    background: #fff;
    text-align: center;
    padding-bottom: 50px;
  }
}
</style>
