const devTestConfig = {
  fileDomain: "https://filetest.jgwcjm.com",
  organizationid: "5c6e1ef7bd8946e29eb0651d2343fa1c",
  funOrganizationId: "bf75c0acbac64704b25f057300964855", //功能定制测试组织Id
  // funOrganizationId: "4d6a1cc0e1684847a8bbf6955b1de8a2", //功能定制正式组织Id
  sysId: "ff4ff73b71c64d04980a6e17bd4df0a5",
};
const masterConfig = {
  fileDomain: "https://file.jgwcjm.com",
  organizationid: "ad01c48fffdc487eb15c8ff5c58d45d2",
  funOrganizationId: "4d6a1cc0e1684847a8bbf6955b1de8a2", //功能定制组织Id正式
  sysId: "93b6c020da474a11b42a418fd5bbbaaf",
};

let config = masterConfig;
let env = 'master';

if(location.href.includes('localhost') || location.href.includes('192.168')) {
  env = 'dev';
} else if(location.href.includes('kf315')) {
  env = 'test';
} else {
  env = 'master';
}

if (
  location.href.indexOf("localhost") >= 0 ||
  location.href.indexOf("kf315.net") >= 0 ||
  location.href.indexOf("192.168") >= 0
) {
  config = devTestConfig;
}

config.env = env;

module.exports = config;
