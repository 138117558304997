<template>
  <div class="renewalSystem">
    <el-form
      :model="form"
      class="demo-form-inline"
      label-position="right"
      label-width="140px"
      :rules="rules"
      ref="form"
    >
      <el-form-item label="开通时长" prop="time">
          <ul class="time">
            <li
              v-for="(item, index) in yearData"
              :class="{ activeHead: activeIndex == index }"
              @click="activeIndex = index,form.time=yearData[index]"
              :key="index"
            >
              {{ item + "年" }}
            </li>
          </ul>
        </el-form-item>
      <!-- <el-form-item label="开通企业数" prop="firmNum" v-if="detail && detail.packageId != 2">
        <el-input
          :maxlength="11"
          v-model="form.firmNum"
          placeholder="请输入大于0的整数"
          class="size"
        />
        <p class="input-msg">2000元/家；当前剩余：{{firmData && firmData.surplus}}</p>
      </el-form-item> -->
      <el-form-item label="赋码量" prop="codeNum">
        <el-input
          :maxlength="6"
          v-model="form.codeNum"
          placeholder="请输入大于500的倍数"
          class="size"
        />
        <p class="input-msg">{{codePrice != 0 ? codePrice * 500 : ' '}}元/500个；当前剩余：{{codeData && codeData.surplus | numberFormatter}}</p>
      </el-form-item>
      <div class="twoBtn">
        <el-button :disabled="disabled" :loading="disabled" type="primary" @click="next('form')">提交</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import {packageDetailWebsite,capacityDetail} from "src/static/services/website";
import { GetUrlParam } from "src/static/utils/getUrl.js";
import { numberFormatter } from "src/static/utils/utils.js";
import { setRenewStorage } from "src/static/utils/orderStorage";
export default {
  data() {
    return {
      checked: false,
      activeIndex: 0,
      detail: null,
      shoppings: null,
      firmData: null,
      codeData: null,
      packagePrice: 0,
      firmPrice: 0,
      codePrice: 0,
      totalPrices: 0,
      disabled: false,
      yearData: [1, 2, 3, 5],
      form: {
        time: 1,
        firmNum: "",
        codeNum: "",
      },
      rules: {
        time: [{ required: true, message: "该项为必填项", trigger: "blur" }],
        firmNum: [{pattern: /^\+?[1-9][0-9]*$/,message: '请填写大于0的整数',trigger: 'blur'}],
        codeNum: [{pattern: /^[1-9]\d*[05]00|500$/,message: '请填写500的倍数',trigger: 'blur'}],
      },
    };
  },
  filters: {
    numberFormatter
  },
  mounted() {
    if(GetUrlParam('super-token') && GetUrlParam('aid')){
      localStorage.setItem('super-token', GetUrlParam('super-token'));
      localStorage.setItem('phone', GetUrlParam('aid'));
    }
    this.getCapacityDetail(3);
    this.getDetail();
  },
  methods: {
    async getDetail(){
      const data = await packageDetailWebsite();
      if (data.state == 200) {
        console.log("续费套餐内容",data.results);
        const results = data.results;
        this.detail = results;
        this.packagePrice = results.unitPrice;
        results.funDetails.map(item => {
          if(item.productType == 2){
            this.firmPrice = item.price;
          }
          if(item.productType == 3){
            this.codePrice = item.price;
          }
        })
      }
    },
    async getCapacityDetail(funTypeOfBill){
      let propsData = {
        funTypeOfBill : funTypeOfBill
      }
      const res = await capacityDetail(propsData);
      if (res.state == 200) {
        this.codeData = res.results;
      }
    },
    validForm(formInline) {
      return new Promise((resolve) => {
        this.$refs[formInline].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async next(formInline) {
      const valid = await this.validForm(formInline);
      if (!valid) {
        return;
      }
      this.totalPrices = 0;
      this.disabled = true;
      setTimeout(()=>{
        this.disabled = false;
      },1000)
      let propsData = {
        packageTime:this.form.time,
      };
      this.totalPrices += this.packagePrice * this.form.time;
      for (let key in this.form) {
        if (this.form[key] && key == 'firmNum'){
          propsData.orgNumber = this.form.openNum
          this.totalPrices += this.firmPrice * this.form[key];
        }
        if (this.form[key] && key == 'codeNum'){
          propsData.codeNumber = this.form.codeNum
          this.totalPrices += this.codePrice * this.form[key];
        }
      }
      const renewalData = {
        propsData:propsData,
        price:this.totalPrices,
        packageId:this.detail.packageId,
        firmPrice:this.firmPrice,
        codePrice:this.codePrice
      }
      console.log('renewalData',renewalData);
      setRenewStorage(renewalData);
    },
  },
  watch: {
    //监听输入
    form:{//深度监听，可监听到对象、数组的变化
        handler(val, oldVal){
          this.form.firmNum = val.firmNum.replace(/\D/g, "");
          if (this.form.firmNum < 0) {
            this.form.firmNum = "";
          } else if (this.form.firmNum > 5000) {
            this.form.firmNum = "5000";
          }
          this.form.codeNum = val.codeNum.replace(/\D/g, "");
          if (this.form.codeNum < 0) {
            this.form.codeNum = "";
          } else if (this.form.codeNum > 10000000000) {
            this.form.codeNum = "10000000000";
          }
        },
        deep:true
    }
  },
};
</script>

<style lang="less" scoped>
.renewalSystem {
  padding-top: 80px;
  width: 700px;
  margin: 0 auto;
  font-size: 14px;
  .twoBtn{
    margin-left: 140px;
  }
  .size{
    width: 500px;
  }
  .input-msg{
    font-size: 14px;
    color: #999;
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
.time{
  display: flex;
  margin: 0;
  padding: 0;
  > li {
    list-style: none;
    width: 82px;
    height: 40px;
    line-height: 38px;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    color: #333;
  }
  .activeHead {
    background-image: url("/static/images/icon/true.png");
    background-repeat: no-repeat;
    background-position-x: 61px;
    background-position-y: 18px;
    border: 1px solid #3296FA !important;
  }
} 

</style>
