$(function () {
  
  const $body = $('body');
  const labels = [];
  
  function getUnBindLabels() {
    const localLabels = $('.el-cascader-node__label:not(.click-binded):visible');
    localLabels.each((index, label) => {
      labels.push(label);
    });
    return localLabels;
  }
  
  function handleLabelClick() {
    const radio = $(this).parent().find('.el-radio').get(0);
    radio && radio.click();
  }
  
  // 把已经从body移除的label 清除出labels
  function clearLabels() {
    for (let i = 0, len = labels.length; i < len; i++) {
      const label = labels[i];
      if ($body.find(label).length === 0) {
        $(label).off('click', handleLabelClick);
        labels[i] = null;
      }
    }
    //
    let nullIndex = labels.indexOf(null);
    while (labels.length > 0 && nullIndex >= 0) {
      labels.splice(nullIndex, 1);
      nullIndex = labels.indexOf(null);
    }
  }
  
  setInterval(() => {
    clearLabels();
    getUnBindLabels().on('click', handleLabelClick).addClass('click-binded');
  }, 100);
});