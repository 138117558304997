$(function () {
  if (window.jgwcjmPackage) {
    const ipcNumber = jgwcjmPackage.copyRight.getIcpNumber();
    const codeLink = jgwcjmPackage.copyRight.getPublicCodeLink();
    let footerContent = '';
    footerContent += jgwcjmPackage.copyRight.getContent();
    if (ipcNumber && ipcNumber.number) {
      footerContent += `&nbsp;&nbsp;<a target="_blank" href="${ipcNumber.link}">${ipcNumber.number}</a>`;
    }
    if (codeLink && ipcNumber.publicCode) {
      footerContent += `&nbsp;&nbsp;<a target="_blank" href="${codeLink}"><img src="https://www.s315.net/static/src/images/footer/police-icon.png" alt=""/> 浙公网安备 ${ipcNumber.publicCode}</a>`;
    }
    $('.public-footer-content').html(footerContent);
  }
});