const axios = require('axios');
const _ = require('lodash');
const api = require('../../api');


const getImageData = (imgUrl) => {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.setAttribute("crossOrigin", 'anonymous');
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const cxt = canvas.getContext('2d');
      cxt.drawImage(img, 0, 0, img.width, img.height);
      const imgData = canvas.toDataURL();
      resolve(imgData);
    };
    img.src = imgUrl;
  });
};

function base64ToBlob(base64Data) {
  let byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(base64Data.split(",")[1]);
  } else {
    byteString = unescape(base64Data.split(",")[1]);
  }
  const mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

module.exports = async function (imageData) {
  const blob = base64ToBlob(imageData);
  const formData = new FormData();
  formData.append('file', blob);
  formData.append('name', 'image.png');
  const result = await axios.post(api.common.FILE_UPLOAD, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  const imgId = _.get(result, 'data.results');
  return imgId;
};
