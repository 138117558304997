<template>
  <div class="product-numIndustry">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>产品生命周期数字化产线应用整体解决方案</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      超级码-以数据洞察为核心驱动，以生产业务为纽带，利用云计算、
      AI机器学习、物联网IOT、标识解析等前沿技术帮助生产制造企业打
      造数字化车间，实现工厂园工区、车间、流水线、设备的逐级可视化，
      从销售接单、原料入厂、到成品出厂，实现生产全链路数字化管理，
      并整合各个流程节点之间的数据及信息对生产过程进行综合监管及全局分析，
      形成智慧数字工厂管理的新模式。
    </div>
    <div class="provideCont">
      <div class="provideCont-top org-top">
        <h1>为生产制造企业提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">
          从原材料采购到商品流通全环节定制化改造，实现生产自动化
        </div>
      </div>
      <img src="static/images/product/num1.png" />
    </div>
    <div class="numCont">
      <div class="numCont-only">
        <img src="static/images/product/numLeft.png" />
        <div>企业数字化应用框架图</div>
        <img src="static/images/product/numRight.png" />
      </div>
      <img src="static/images/product/num2.png" class="orgImgOne" />
      <div class="numCont-only">
        <img src="static/images/product/numLeft.png" />
        <div>企业数字化转型升级的优点</div>
        <img src="static/images/product/numRight.png" />
      </div>
      <img src="static/images/product/num3.png" class="orgImgTwo" />
      <div class="numCont-only">
        <img src="static/images/product/numLeft.png" />
        <div>产品优势</div>
        <img src="static/images/product/numRight.png" />
      </div>
      <img src="static/images/product/num4.png" class="orgImgThree" />
    </div>
    <div class="carShow">
      <div class="carShow-top">
        <h1>可视化驾驶舱展示</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <img src="static/images/product/num5.png" />
      <img src="static/images/product/num6.png" />
      <div class="num-bottom">
        <h1>数字工厂实施场景图</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <div class="imgBottom">
        <img src="static/images/product/num7.png" />
        <img src="static/images/product/num8.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less"></style>
