<template>
  <div class="about-part4-wrap content-width middle about-main">
    <div class="main-block">
      <ul class="item-detail-content updating-dec">
        <li
          :class="num == index ? 'active' : 'updating-li'"
          v-for="(item, index) in nameList"
          @click="showName(item, index)"
          :key="index"
        >
          <div>{{ item }}</div>
        </li>
      </ul>
      <div
        class="updating-block"
        :data-total="data.length"
        v-for="(item, index) in data"
        :key="index"
        v-show="name == item.lx"
      >
        <a
          class="list-item-content-item-btn"
          :href="'/aboutDetail.html?' + item.Id"
        >
          <div class="block-left">
            <img :src="item.fm | imgSrc" class="news-company-bg" />
          </div>
          <div class="block-right">
            <div class="block-right-title">{{ item.seobt }}</div>
            <div class="block-right-content">{{ item.zy }}</div>
            <div class="block-right-foot">
              <span>
                <i></i>发布时间：
                <p>{{ item.fbsj }}</p>
              </span>
              <!-- <span>
                <i></i>&nbsp;
                <p>{{ item.seeCount && item.seeCount }}</p>
              </span> -->
            </div>
          </div>
        </a>
      </div>
      <!-- <div style="text-align: center">
        <div class="pagination"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { functionData, pageData } from "../../../services/website";
import imgSrc from "src/static/utils/imgSrc";
import config from "../../../config";
export default {
  data() {
    return {
      data: "",
      dataNew: "",
      num: 0,
      name: "企业动态",
      nameList: ["企业动态", "行业风向", "原创专栏"],
      newsId: "",
    };
  },
  mounted() {
    this.getFunction();
  },
  filters: {
    imgSrc,
  },
  methods: {
    showName(item, index) {
      this.name = item;
      this.num = index;
      this.getFunction();
    },
    async getFunction() {
      const res = await functionData({
        functionId: "6d599bf40f1b4351aeb8554f2ae96ddb",
        current: 1,
        pageSize: 999,
        organizationId: config.funOrganizationId,
      });
      if (res.state == 200) {
        this.data = res.results.list.filter((item) => this.name == item.lx);
        // this.data.map(async (item1) => {
        //   const params = {};
        //   params.actionType = "";
        //   // params.dataPointId = '';
        //   params.itemDescription = `超级码云官网`;
        //   params.itemId = item1.Id;
        //   params.operator = "";
        //   params.organizationId = config.organizationId;
        //   params.pageType = "chaojimayun";
        //   // params.dataPage = `/news/detail/${id}`;
        //   params.sysId = config.sysId;
        //   const res1 = await pageData(params);
        //   if (res1.state == 200) {
        //     return Object.assign(item1, {
        //       seeCount: res1.results.visitsCount.toString(),
        //     });
        //   }
        // });
        // this.data.map((item0) => {
        //   console.log("item0", item0["seeCount"], item0);
        // });
        // setTimeout(() => {
        //   this.dataNew = this.data;
        // }, 250);
      }
    },
  },
};
</script>

<style lang="less" scoped>
//
.about-part4-wrap {
  margin-top: 102px;
  .pagination {
    display: none;
    margin-top: 30px;
    span,
    a,
    .prev,
    .next {
      margin: 0 10px;
      padding: 5px 10px;
      border: 1px solid #b1abab !important;
      color: #b1abab !important;
      &:hover {
        border: 1px solid #ed6c00;
        background: #fff;
        color: #ed6c00;
        margin: 0 10px;
        padding: 5px 10px;
      }
      &.active {
        border: 1px solid #ed6c00;
        background: #fff;
        color: #ed6c00;
        margin: 0 10px;
        padding: 5px 10px;
      }
    }
  }
  .main-block {
    padding-top: 102px;
    padding-bottom: 50px;
    margin: 0 auto 0;
    width: 1200px;
    text-align: center;
    ul {
      display: flex;
      justify-content: space-around;
      padding-left: 0;
      li {
        cursor: pointer;
        width: 130px;
        font-size: 18px;
        color: #1e1e1e;
        padding-bottom: 16px;
        // &.active {
        //   color: #20a0ff;
        //   border-bottom: 3px solid #20a0ff;
        // }
      }
      .active {
        color: #20a0ff;
        border-bottom: 3px solid #20a0ff;
      }
    }
    .updating-block {
      // display: none;
      width: 1200px;
      .show {
        display: flex !important;
        justify-content: space-around;
      }
      a {
        display: flex !important;
        justify-content: space-around;
        text-decoration: none;
        // display: none;
        padding: 30px;
        border: 1px solid rgba(227, 229, 232, 0.75);
        height: 350px;
        position: relative;
        .block-left {
          width: 540px;
          height: 280px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .block-right {
          width: 50%;
          text-align: left;
          .block-right-title {
            color: #323232;
            font-size: 18px;
            margin-bottom: 30px;
            &:hover {
              color: #20a0ff;
              cursor: pointer;
            }
          }
          .block-right-content {
            font-size: 14px;
            color: #8e8e8e;
            word-break: break-all;
            word-wrap: break-word;
          }
          .block-right-foot {
            position: absolute;
            bottom: 40px;
            display: flex;
            align-items: center;
            span {
              margin-right: 30px;
              font-size: 14px;
              color: #8e8e8e;
              display: flex;
              align-items: center;
              p {
                display: inline-block;
              }
              &:nth-child(1) {
                i {
                  background: url(static/images/about/time-icon.png) no-repeat;
                  width: 21px;
                  height: 21px;
                  margin-right: 10px;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
              &:nth-child(2) {
                i {
                  background: url(static/images/about/view-icon.png) no-repeat;
                  width: 30px;
                  height: 18px;
                  display: inline-block;
                  vertical-align: top;
                }
              }
            }
          }
        }
        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}
.show {
  display: block !important;
}
</style>
