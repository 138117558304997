<template>
    <div class="succeed-box">
      <p class="succeed-msg"><em></em>续费成功!</p>
      <el-button :disabled="disabled" :loading="disabled" type="primary" @click="loginFunc()">进入我的超级码<sup>®</sup>云</el-button>
      <p class="see-others" @click="goHome()">我再看看</p>
    </div>
</template>
<script>
import {openStatus} from "src/static/services/website";
import { skipFunc } from "src/static/utils/webskipUtils.js";
export default {
  data() {
    return {
      disabled: false,
    };
  },
  mounted() {
  },
  methods: {
    async loginFunc(){
      this.disabled = true;
      const res = await openStatus();
      if (res.state == 200) {
        skipFunc(res.results);
      }
      this.disabled = false;
    },
    goHome(){
      window.location.href = "platform.html";
    }
  },
};
</script>

<style lang="less" scoped>
.succeed-box{
  text-align: center;
  padding-top: 150px;
  .succeed-msg{
    font-size: 28px;
    color: #6DD56E;
    font-weight: 600;
    margin-top: 10px;
    em{
      vertical-align: -50%;
      display: inline-block;
      margin-right: 18px;
      width: 61px;
      height: 66px;
      background: url("static/images/icon/succeed_icon.png") center no-repeat;
    }
  }
  .see-others{
    font-size: 14px;
    width: 100px;
    margin: 30px auto;
    color: #B3B3B3;
    cursor: pointer;
  }
}
</style>
