<template>
  <el-cascader
      :key="allIndustrys.length"
      :value="value"
      class="cjm-industry-select"
      @change="onChange"
      clearable
      ref="elIndustry"
      :show-all-levels="true"
      :props="props" />
</template>

<script>
const industry = require('../../services/common/industry');
export default {
  props: {
    value: Array
  },
  data() {
    // window.getVm = () => this;
    return {
      allIndustrys: [],
      props: {
        lazy: true,
        checkStrictly: true,
        lazyLoad: (node, resolve) => this.lazyLoad(node, resolve)
      }
    }
  },
  methods: {
    industry2option(industryItem) {
      return {
        value: industryItem.businessCode,
        label: industryItem.businessName,
        leaf: false
      };
    },
    industry3option(industryItem) {
      return {
        value: industryItem.businessCode,
        label: industryItem.businessName,
        leaf: true
      };
    },
    searchItem(businessCode, source) {
      let result = null;
      source.forEach(item => {
        if (!result) {
          if (item.businessCode === businessCode) {
            result = item;
          } else {
            result = this.searchItem(businessCode, item.childs || []);
          }
        }
      });
      return result;
    },
    lazyLoad(node, resolve) {
      const { level } = node;
      if (level > 0) {
        const item = this.searchItem(node.data.value, this.allIndustrys);
        item.childs == null ? (this.$refs.elIndustry.dropDownVisible = false) : '' ;
        const childs = item.childs || [];
        node.data.leaf = childs.length === 0;
        if(level < 2){
          resolve(childs.map(this.industry2option));
        }else{
          resolve(childs.map(this.industry3option));
        }
      } else {
        resolve(this.allIndustrys.map(this.industry2option))
      }
    },
  
    onChange(value) {
      const newValue = value.map(code => {
        const item = this.searchItem(code, this.allIndustrys);
        return item ? ({ value: code, label: item.businessName }) : null;
      }).filter(Boolean);
      this.$emit('input', newValue);
    }
  },
  async mounted() {
    // setInterval(await function() {
    //   document.querySelectorAll(".el-cascader-node__label").forEach(el => {
    //     el.onclick = function() {
    //       if (this.previousElementSibling) this.previousElementSibling.click();
    //     };
    //   });
    // }, 1000),
    this.allIndustrys = await industry.getallIndustry();
  }
}
</script>

<style scoped>
.cjm-industry-select {
  display: block;
}
</style>