<template>
  <div class="homeLb">
    <el-carousel
      class="el-carousel-box"
      trigger="click"
      height="100%"
      arrow="never"
      interval="3000"
    >
      <el-carousel-item
        class="el-carousel-item"
        v-for="(item, index) in lists"
        :key="index"
      >
        <img class="carousel-img" :src="item.imgs" />
        <div class="carrouselCont">
          <h2>
            {{ item.title }}<sup v-show="index == 1">®</sup
            ><span v-show="index == 1">云</span>
          </h2>
          <h4>{{ item.smallTitle }}</h4>
          <p>{{ item.cont }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="topNum">
      <div class="bannerNum">
        <div class="num">17<span class="unit">年</span></div>
        <div class="text">专注提供数字化+服务</div>
      </div>
      <div class="bannerNum">
        <div class="num">300<span class="add">+</span></div>
        <div class="text">知识产权及专利</div>
      </div>
      <div class="bannerNum">
        <div class="num">30000<span class="add">+</span></div>
        <div class="text">品牌企业客户</div>
      </div>
      <div class="bannerNum">
        <div class="num">
          400<span class="add">+</span><span class="unit">亿件</span>
        </div>
        <div class="text">商品身份标识</div>
      </div>
    </div>
  </div>
</template>

<script>
import { carouselList } from "src/static/services/website";
import imgSrc from "src/static/utils/imgSrc";
import { PVTrack } from "../../../utils/eventTrack";
// import png1 from "src/static/assets/homeBanner.png";
// import png2 from "src/static/assets/homeBanner.png";
module.exports = {
  data() {
    return {
      lists: [
        {
          imgs: "static/images/home/banner3.png",
          title: "",
          smallTitle: "",
          cont: "",
        },
        {
          imgs: "static/images/home/banner1.png",
          title: "超级码",
          smallTitle: "产品一物一码开放PaaS平台",
          cont: "码上链接无限可能",
        },
        {
          imgs: "static/images/home/banner2.png",
          title: "成熟的软件研究能力",
          smallTitle: "",
          cont: "平台核心竞争优势",
        },
      ],
      current: 1,
      pageSize: 999,
      flag: 1,
    };
  },
  filters: {
    imgSrc,
  },
  methods: {
    // async getBannerList() {
    //   let propsData = {
    //     current: this.current,
    //     pageSize: this.pageSize,
    //     flag: this.flag,
    //     useType: "1",
    //   };
    //   const res = await carouselList(propsData);
    //   if (res.state == 200) {
    //     this.lists = res.results.list;
    //   }
    // },
  },
  mounted() {
    // this.getBannerList();
    PVTrack('/platform.html');
  },
};
</script>

<style lang="less" scoped>
.homeLb {
  height: 660px;
  .el-carousel-box {
    width: 100%;
    height: 543px;
    .el-carousel-item {
      .carousel-img {
        position: absolute;
        left: -100%;
        right: -100%;
        top: 0;
        bottom: 0;
        margin: auto;
        min-width: 100%;
        min-height: 100%;
      }
      .carrouselCont {
        position: absolute;
        width: 1200px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 58px;
        font-size: 30px;
        p {
          color: #8c9bad;
          font-size: 26px;
        }
      }
    }
  }
  .topNum {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .bannerNum {
      text-align: center;
    }
    .num {
      font-size: 46px;
      font-weight: bold;
      color: #2469fc;
      padding-top: 27px;
      padding-bottom: 8px;
    }
    .unit {
      font-size: 24px;
    }
    .add {
      position: relative;
      top: -18px;
      font-size: 36px;
    }
  }
}
</style>
