<template>
  <div class="caseShow-platform">
    <div class="caseSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>产品一物一码开放PASS平台</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      超级码®云基于一物一码全场景应用，为渠道带来了新方向、新能力、为品牌企业提供专属区块链产品数字
      身份管理SaaS系统，提升企业客户忠诚度，
      实现商品全生命周期数字化管理，为私域链提供具有高性能、强动力的“新引擎”
    </div>
    <div class="caseCont">
      <div class="caseCont-top">
        <h1>客户案例</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
    </div>
    <!-- 双重循环渲染数据 -->
    <div class="caseText" v-for="(item, index) in caseList" :key="index">
      <div class="caseText-top">
        <h1 class="caseText-topTitle">{{ item.bigTitle }}</h1>
        <span class="caseText-topCont">{{ item.smallTitle }}</span>
      </div>
      <div class="caseText-intro">
        {{ item.cont }}
      </div>
      <div
        class="caseText-bg"
        v-for="(items, indexs) in contList"
        :key="indexs"
      >
        <div class="caseText-bgTitle" v-if="index == indexs">
          {{ items.title }}
        </div>
        <div v-if="index == indexs" class="iconText">
          <img :src="items.icon" />
          <div>{{ items.text1 }}</div>
        </div>
        <div v-show="index != 0" v-if="index == indexs" class="iconText">
          <img :src="items.icon" />
          <div>{{ items.text2 }}</div>
        </div>
        <div v-if="index == indexs" class="iconText">
          <img :src="items.icon" />
          <div>{{ items.text3 }}</div>
        </div>
        <div v-if="index == indexs" class="iconText">
          <img :src="items.icon" />
          <div>{{ items.text4 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseList: [
        {
          bigTitle: "某赋码机、采集设备硬件生产商",
          smallTitle: "（为保护客户隐私，采用匿名方式）",
          cont: "通过超级码云，赋能硬件设备，打造一体化企业数字升级全套解决方案，竞争力远超同行，不仅深度绑定了客户，更带来了销量的显提升；结合售后服务体系，客户已成功打造出多个企业数字化升级的典型样板间！",
        },
        {
          bigTitle: "某标签生产、激光打码包装印刷厂",
          smallTitle: "（为保护客户隐私，采用匿名方式）",
          cont: "通过超级码云，赋能生产设备，产线自动打码，实现数字化转型，完成业务升级，成为一物一码多码合一的数字化综合服务商，竞争力显著提升，垄断当地大部分标签产业；成本低、0开发费用的优势，结合系统使用年费、标签增值费用，让渠道利润暴增！",
        },
        {
          bigTitle: "某标签生产、激光打码包装印刷厂",
          smallTitle: "（为保护客户隐私，采用匿名方式）",
          cont: "通过与超级码云深度融合，打造渠道自身数字化赋码能力，结合一物一码的多码合一功能，实现提供企业整体数字化升级全套解决方案，在增加客户留存的同时，参与多个招投标项目并取得良好效果！",
        },
      ],
      contList: [
        {
          title: "数据提升",
          icon: "static/images/caseShow/true.png",
          text1: "以超级码云为技术底层支持，成功签约两个大型企业招投标项目",
          text2: "",
          text3: "硬件设备销量提升51.3%",
          text4: "除硬件收入外，每年额外码营收费 用可达100余万元",
        },
        {
          title: "数据提升",
          icon: "static/images/caseShow/true.png",
          text1: "客户流失率从5.1%降低至1.2%",
          text2: "新客增长率从0.3%提至10.8%",
          text3: "利润率实现倍数增长",
          text4: "额外系统开通费、年费达到50余万元",
        },
        {
          title: "数据提升",
          icon: "static/images/caseShow/true.png",
          text1: "客户流失率从6.5%降低至2.3%",
          text2: "新客增长率从0.2%提至6.3%",
          text3: "额外系统开通费、年费达到20余万元",
          text4: "通过招投标，成功签约3个企业数字化转型项目",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@h: 1200px;
.caseShow-platform {
  h1 {
    position: relative;
    z-index: 9;
  }
  .caseSuper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
    h1 {
      padding: 0 15px;
    }
  }
  .codeText {
    font-size: 18px;
    color: #8e8e8e;
    line-height: 34px;
    text-align: left;
    padding-bottom: 40px;
    width: @h;
    margin: 0 auto;
  }

  .caseCont {
    text-align: center;
    position: relative;
    width: @h;
    margin: 0 auto;
    h1 {
      padding-bottom: 22px;
    }
    .blueBg {
      position: absolute;
      top: 24px;
      left: 518px;
    }
  }
  .caseText {
    width: @h;
    margin: 0 auto;
    .caseText-top {
      background: url("static/images/caseShow/caseTitle.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-content: center;
      box-sizing: border-box;
      padding: 10px;
      h1 {
        margin: 0;
        padding-left: 55px;
      }
      .caseText-topCont {
        display: inline-block;
        padding-top: 12px;
        font-size: 20px;
      }
    }
    .caseText-intro {
      font-size: 18px;
      color: #8e8e8e;
      line-height: 34px;
      text-align: left;
      padding: 44px 0;
    }
    .caseText-bg {
      color: #fff;
      margin-bottom: 40px;
      .caseText-bgTitle {
        font-size: 30px;
        font-weight: bold;
        padding-left: 15px;
        padding-bottom: 35px;
      }
      .iconText {
        display: flex;
        margin-bottom: 30px;
        > img {
          height: 26px;
        }
        > div {
          width: 269px;
          line-height: 60px;
          line-height: 26px;
          padding-left: 20px;
        }
      }
    }
  }
  .caseText:nth-child(4) {
    .caseText-bg:nth-child(3) {
      background: url("static/images/caseShow/pass1.png") no-repeat;
      background-size: 100% 100%;
      height: 354px;
      padding: 26px 30px;
      box-sizing: border-box;
    }
  }
  .caseText:nth-child(5) {
    .caseText-bg:nth-child(4) {
      background: url("static/images/caseShow/pass2.png") no-repeat;
      background-size: 100% 100%;
      height: 354px;
      padding: 26px 30px;
      box-sizing: border-box;
    }
  }

  .caseText:nth-child(6) {
    .caseText-bg:nth-child(5) {
      background: url("static/images/caseShow/pass3.png") no-repeat;
      background-size: 100% 100%;
      height: 354px;
      padding: 26px 30px;
      box-sizing: border-box;
    }
    .iconText {
      margin-bottom: 23px;
    }
  }
}
</style>
