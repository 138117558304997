<template>
  <div class="productNew">
    <div class="productNew-header">
      <div
        v-show="num == e"
        v-for="(i, e) in bannerList"
        :key="e"
        class="headerCont"
      >
        <div>
          <h1 class="titleAll">{{ i.title }}</h1>
          <div class="cont">{{ i.cont }}</div>
        </div>
        <img src="static/images/product/banner3.png" />
      </div>
    </div>
    <div :class="tabFixed == true ? 'fixedTabMain' : ''" id="productNew-tab">
      <div :class="tabFixed == true ? 'fixedProduct' : 'productNew-tab'">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="num == index ? 'active' : 'tabOnly'"
          @click="handleClick(index)"
        >
          <img :src="item.pic" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="productNew-cont">
      <ProductOne v-show="num == 0"></ProductOne>
      <ProductTwo v-show="num == 1"></ProductTwo>
      <ProductThree v-show="num == 2"></ProductThree>
      <ProductFour v-show="num == 3"></ProductFour>
      <ProductFive v-show="num == 4"></ProductFive>
      <ProductSix v-show="num == 5"></ProductSix>
      <ProductSeven v-show="num == 6"></ProductSeven>
    </div>
  </div>
</template>

<script>
import ProductOne from "./product/productOne.vue";
import ProductTwo from "./product/productTwo.vue";
import ProductThree from "./product/productThree.vue";
import ProductFour from "./product/productFour.vue";
import ProductFive from "./product/productFive.vue";
import ProductSix from "./product/productSix.vue";
import ProductSeven from "./product/productSeven.vue";
export default {
  data() {
    return {
      bannerList: [
        {
          title: "产品身份证监管平台",
          cont: "打造企业数字化升级全流程",
        },
        {
          title: "数字工厂",
          cont: "提高产品质量及生产过程动态性能",
        },
        {
          title: "品牌舆情监控",
          cont: "提供线上维权全风险全代理解决方案",
        },
        {
          title: "数字茶场",
          cont: "基于茶企从茶园到终端生命周期数字化赋能",
        },
        {
          title: "碳标签",
          cont: "以碳标签的形式告知消费者碳信息，实现农产品溢价，提升市场竞争力",
        },
        {
          title: "酒",
          cont: "从一粒粮食到一瓶美酒的全生命周期数字化解决方案",
        },
        {
          title: "产品评价",
          cont: "基于企业、品牌、产品全生命周期信息进行全方位数字化评价",
        },
      ],
      tabList: [
        {
          name: "产品身份证管理平台",
          pic: "static/images/product/icon1.png",
        },
        {
          name: "数字工厂74.0",
          pic: "static/images/product/icon2.png",
        },
        {
          name: "品牌舆情监测",
          pic: "static/images/product/icon3.png",
        },
        {
          name: "数字茶场",
          pic: "static/images/product/icon4.png",
        },
        {
          name: "碳标签",
          pic: "static/images/product/icon5.png",
        },
        {
          name: "酒业供应链数字化",
          pic: "static/images/product/icon6.png",
        },
        {
          name: "产品评价体系",
          pic: "static/images/product/icon7.png",
        },
      ],
      num: 0,
      tabFixed: false,
    };
  },
  components: {
    ProductOne,
    ProductTwo,
    ProductThree,
    ProductFour,
    ProductFive,
    ProductSix,
    ProductSeven,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.ontouchmove = this.handleScroll;
  },
  methods: {
    handleClick(index) {
      this.num = index
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //网页被卷起来的高度
      var offsetTop = document.querySelector("#productNew-tab").offsetTop; //距离顶部的高度
      if (scrollTop > offsetTop) {
        this.tabFixed = true;
      } else {
        this.tabFixed = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less"></style>
