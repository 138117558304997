<template>
  <div class="upload-item" @click="handleClick">
    <i class="x-line"></i>
    <i class="y-line"></i>
  </div>
</template>

<script>
const imageChoose = require('./imageChoose.js');
module.exports = {
  methods: {
    async handleClick() {
      const imgId = await imageChoose();
      this.$emit('success', imgId);
    }
  }
}
</script>

<style scoped lang="less">
.upload-item {
  position: relative;
  width: 86px;
  height: 86px;
  border: 1px dashed #D9D9D9;
  cursor: pointer;
  font-size: 0;
  display: inline-block;
  vertical-align: top;
}

.x-line, .y-line {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #D9D9D9;
}

.x-line {
  width: 30px;
  height: 4px;
  margin-left: -15px;
  margin-top: -2px;
}

.y-line {
  width: 4px;
  height: 30px;
  margin-left: -2px;
  margin-top: -15px;
}
</style>