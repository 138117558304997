<!--
 * @Author: chenqing
 * @Date: 2023-03-16 18:20:58
 * @LastEditors: chenqing
 * @LastEditTime: 2023-05-11 14:07:23
 * @FilePath: /superCodeCloud/src/static/scripts/modules/chooseDeloy/product/productFour.vue
 * @Description: 
-->
<template>
  <div class="product-numTea">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>茶企ERP数字化应用整体解决方案</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      基于茶企从茶园到终端全生命周期数字化赋能，利用物联网设备、茶企ERP数字化管理平台、
      产品身份证（合格证）标识、数字画像驾驶舱等服务，为茶企业数字化转型提供一站式解决方案。
    </div>
    <div class="feelingsOne">
      <div class="provideCont">
        <div class="provideCont-top">
          <h1>为茶叶企业提供</h1>
          <img src="static/images/product/blueBg.png" class="blueBg" />
          <div class="topText">
            从茶叶种植到茶叶销售全流程信息化、数字化、精细化管理，实现降本增效
          </div>
        </div>
      </div>
    </div>
    <div class="provideCont">
      <div class="numCont-only">
        <img src="static/images/product/numLeft.png" />
        <div>数字赋能解决茶企三大痛点</div>
        <img src="static/images/product/numRight.png" />
      </div>
      <img src="static/images/product/numTeaOne.png" />
      <img src="static/images/product/numTea2.png" class="botTri" />
    </div>
    <div class="energy">
      <img src="static/images/product/numTea3.png" class="botImg" />
    </div>
    <div class="dataManage">
      <img src="static/images/product/fn1.png" />
      <img src="static/images/product/fn2.png" />
    </div>
    <div class="allData">
      <div class="production">
        <img src="static/images/product/numTea7.png" />
      </div>
      <div class="feelingsOne">
        <div class="provideCont">
          <div class="provideCont-top">
            <h1 class="numTeaBig">产品架构</h1>
            <img src="static/images/product/blueBg.png" class="blueBg" />
          </div>
          <img src="static/images/product/numTea8.png" />
        </div>
      </div>
    </div>
    <div class="feelingsOne">
      <div class="provideCont">
        <div class="provideCont-top">
          <h1 class="numTeaBig">可视化驾驶舱展示</h1>
          <img src="static/images/product/blueBg.png" class="blueBg2" />
        </div>
        <img src="static/images/product/numTea9.png" class="teaImgOne" />
        <img src="static/images/product/numTea10.png" class="teaImgTwo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        {
          icon: "static/images/home/service1.png",
          title: "数据获取能力",
          cont: "资深大数据团队提供技术支持，确保数据获取的全面性，及时性以及准确性",
        },
        {
          icon: "static/images/home/service2.png",
          title: "数据分析能力",
          cont: "资深算法团队支撑，自研数据分析模型，保障分析结果的可靠性",
        },
        {
          icon: "static/images/home/service3.png",
          title: "处置能力",
          cont: "合作多家专业律所，公证处以及打假团队，提高处置效率，缩短处置周期",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped></style>
