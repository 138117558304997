<template>
  <div class="open-top">
    <div class="logo-box" style="display:none">
      <div class="logo-main">
        <a id="logo" class="logo-btn">
          <img src="static/images/platformLogo.png" />
        </a>
      </div>
    </div>
    <div class="steps-box" v-if="showStep" :style="isInsert ? 'border-top:10px solid #F0F1F5' : ''">
      <div class="stepStyle">
        <el-steps :active="active" align-center>
          <el-step title="开通人员认证" :icon="active > 0 ? 'el-icon-circle-check' : ''"></el-step>
          <el-step title="选择开通内容" :icon="active > 1 ? 'el-icon-circle-check' : ''"></el-step>
          <el-step title="企业认证" :icon="active > 2 ? 'el-icon-circle-check' : ''"></el-step>
          <el-step title="确认订单" :icon="active > 3 ? 'el-icon-circle-check' : ''"></el-step>
          <el-step title="付款" :icon="active > 4 ? 'el-icon-circle-check' : ''"></el-step>
          <el-step title="开通成功" :icon="active > 5 ? 'el-icon-circle-check' : ''"></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isInsert: window.top !== window.self,
      active: -1,
      showStep: false,
    };
  },
  mounted() {
    const search = window.location.search;
    if (search.indexOf("renewal") > 0) {
      this.showStep = false;
      return;
    } else {
      this.showStep = true;
    }
    const url = window.location.pathname;
    if (url.indexOf("openIdentification.html") > 0) {
      this.active = 0;
    } else if (url.indexOf("openContent.html") > 0) {
      this.active = 1;
    } else if (url.indexOf("openCertification.html") > 0) {
      this.active = 2;
    } else if (url.indexOf("confirmOrder.html") > 0) {
      this.active = 3;
    } else if (url.indexOf("payment.html") > 0) {
      this.active = 4;
    } else if (url.indexOf("openSucceed.html") > 0) {
      this.active = 5;
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.open-top {
  width: 100%;
  .logo-box {
    height: 72px;
    border-bottom: 10px solid #f0f1f5;
    .logo-main {
      width: 1200px;
      margin: 0 auto;
      .logo-btn {
        text-indent: -9999px;
        display: block;
        width: 158px;
        height: 40px;
        padding-top: 14px;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
  .steps-box {
    height: 76px;
    border-bottom: 10px solid #f0f1f5;
    .stepStyle {
      width: 680px;
      margin: 0 auto;
      box-sizing: border-box;
      padding-top: 10px;
    }
  }
}
</style>
