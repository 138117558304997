<template>
  <div class="caseShowNew">
    <div class="caseShowNew-header">
      <div
        v-show="num == e"
        v-for="(i, e) in bannerList"
        :key="e"
        class="headerCont"
      >
        <div>
          <h1 class="titleAll">
            {{ i.title }}<sup v-show="e == 0">®</sup
            ><span v-show="e == 0">云</span>
          </h1>
          <h1 v-show="num == 0" class="titleS">{{ i.sTitle }}</h1>
          <div class="cont">
            {{ i.cont }}<sup v-show="e == 0">®</sup
            ><span v-show="e == 0"
              >云基于一物一码全场景应用，为渠道带来了新方向、新能力、为品
              牌企业提供专属区块链产品数字身份管理SaaS系统，提升企业客户忠诚度，实现商品全生命周期
              数字化管理，为私域链提供具有高性能、强动力的“新引擎”</span
            >
          </div>
        </div>
        <img src="static/images/caseShow/caseBanner2.png" />
      </div>
    </div>
    <div :class="tabFixed == true ? 'fixedTabMain' : ''" id="caseShow-tab">
      <div :class="tabFixed == true ? 'fixedProduct' : 'caseShow-tab'">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="num == index ? 'active' : 'tabOnly'"
          @click="num = index"
        >
          <img :src="item.pic" />
          <div>{{ item.name }}</div>
          <div class="boLine" v-show="num == index"></div>
        </div>
      </div>
    </div>
    <div class="caseShow-cont">
      <CaseOne v-show="num == 0"></CaseOne>
      <CaseTwo v-show="num == 1"></CaseTwo>
      <CaseThree v-show="num == 2"></CaseThree>
      <CaseFour v-show="num == 3"></CaseFour>
      <CaseFive v-show="num == 4"></CaseFive>
      <CaseSix v-show="num == 5"></CaseSix>
    </div>
  </div>
</template>

<script>
import CaseOne from "./caseShow/caseShowOne.vue";
import CaseTwo from "./caseShow/caseShowTwo.vue";
import CaseThree from "./caseShow/caseShowThree.vue";
// import CaseFour from "./caseShow/caseShowFour.vue";
import CaseFour from "./caseShow/caseShowFours.vue";
import CaseFive from "./caseShow/caseShowFive.vue";
import CaseSix from "./caseShow/caseShowSixs.vue";
export default {
  data() {
    return {
      bannerList: [
        {
          title: "超级码",
          sTitle: "产品一物一码开放PaaS平台",
          cont: "超级码",
        },
        {
          title: "产品身份证监管平台",
          cont: "产品身份证监管平台为全行业“一物一码”全场景应用赋能，打通企业全流程信息链，提升管理效率、提高智能生产、提供新零售与精准数据营销，最终解决企业在信息管理过程中重复建设的问题，大力降低企业数据管理操作成本，提升企业客户忠诚度，实现商品全生命周期数字化管理，为私域连接配备全新高性能、强动力的“新引擎”",
        },
        {
          title: "数字工厂",
          cont: "对每个产品（单品）在其生命周期内进行数字信息化管理，帮助产品提升掌控能力的智能信息化管理系统；系统以生产管理为基础，溯源管理为平台，营销管理为重点，实现仓储物流和信息流有机统一。",
        },
        {
          title: "数字茶场",
          cont: "基于茶企从茶园到终端全生命周期数字化赋能，利用物联网设备、茶企ER数字化管理平台、产品身份证（合格证）标识、数字画像驾驶舱等服务，为茶企业数字化转型提供一站式解决方案。",
        },
        {
          title: "碳标签",
          cont: "碳标签(Carbon Labelling)是为了缓解气候变化，减少温室气体排放，推广低碳排放技术，把商品在生产过程中所排放的温室气体排放量在产品标签上用量化的指数标示出来，以标签的形式告知消费者产品的碳信息。",
        },
        {
          title: "酒类供应链数字化",
          cont: "该框架以白酒溯源基因为核心脉络，以物联网、区块链、数据支撑平台以及人工智能分析等领先的数字化技术为支撑，完成从一滴美酒向一粒种子的反向追溯，此外，公司对农业、包材、生产、流通四大模块中全流程的信息和数据进行采集，并用信息化手段进行监控、可视化与分析，以点至线，以线及面，对质量溯源进行全方位管控。同时，公司建立企业级溯源可视化门户，实现企业级溯源分析与可视化，同时可提供公众查询服务。",
        },
      ],
      tabList: [
        {
          name: "一物一码开放PaaS平台",
          pic: "static/images/caseShow/caseTab1.png",
        },
        {
          name: "产品身份证管理平台",
          pic: "static/images/product/icon1.png",
        },
        {
          name: "数字工厂4.0",
          pic: "static/images/product/icon2.png",
        },
        {
          name: "数字茶场",
          pic: "static/images/product/icon4.png",
        },
        {
          name: "碳标签",
          pic: "static/images/product/icon5.png",
        },
        {
          name: "酒业供应链数字化",
          pic: "static/images/product/icon6.png",
        },
      ],
      num: 0,
      tabFixed: false,
    };
  },
  components: {
    CaseOne,
    CaseTwo,
    CaseThree,
    CaseFour,
    CaseFive,
    CaseSix,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.ontouchmove = this.handleScroll;
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //网页被卷起来的高度
      var offsetTop = document.querySelector("#caseShow-tab").offsetTop; //距离顶部的高度
      if (scrollTop > offsetTop) {
        this.tabFixed = true;
      } else {
        this.tabFixed = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
@h: 1200px;
.caseShowNew {
  padding-top: 72px;
  .caseShowNew-header {
    height: 450px;
    background: url("static/images/caseShow/caseBanner1.png") no-repeat;
    background-size: 100% 100%;
    color: #fff;
    .headerCont {
      width: @h;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .titleAll {
        padding-top: 120px;
        font-size: 38px;
        margin: 0;
      }
      .titleS {
        font-size: 38px;
        margin: 0;
      }
      .cont {
        width: 584px;
        line-height: 32px;
        padding-top: 30px;
      }
      img {
        margin-top: 30px;
      }
    }
  }

  #caseShow-tab {
    background-color: #fff;
  }
  .caseShow-tab,
  .fixedProduct {
    width: @h;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .tabOnly,
    .active {
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      margin-top: 30px;
      font-size: 14px;
      line-height: 22px;
      color: #666666;
      &:hover {
        color: #2469fc;
        font-weight: bold;
      }
    }
    .boLine {
      width: 60px;
      height: 4px;
      background: #2469fc;
      margin: 24px auto 0;
    }
    .active {
      color: #2469fc;
      font-weight: bold;
    }
  }
  // .fixedTabMain {
  //   width: 100%;
  //   position: fixed;
  //   top: 0;
  //   z-index: 9;
  // }
  // .hrefStyle {
  //   position: absolute;
  //   top: 800px;
  // }
}
</style>
