<template>
  <el-select
      v-model="val"
      filterable
      remote
      reserve-keyword
      placeholder="请填写营业执照上的公司全称"
      :remote-method="remoteMethod"
      @focus="handleFocus"
      :loading="loading">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
const ajax = require('../../utils/ajax');
export default {
  props: {
    value: String,
    label: String,
    remoteUrl: String,
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    searchKey: {
      type: String,
      default: 'search'
    }
  },
  data() {
    return {
      options: [],
      loading: false,
    }
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(setVal) {
        const optionItem = setVal ? (this.options.find(item => item.value === setVal)) : null;
        this.$emit('input', setVal, optionItem ? optionItem.data : null);
      }
    }
  },
  methods: {
    handleFocus() {
      const query = $(this.$el).find('.el-input input').val();
      if (!query) {
        this.remoteMethod('');
      }
    },
    async remoteMethod(query) {
      this.loading = true;
      const params = {};
      params.pageSize = 50;
      params.current = 1;
      params[this.searchKey] = query;
      const { res } = await ajax.get(this.remoteUrl, params);
      const _ = require('lodash');
      const data = _.get(res, 'results.list');
      if (Array.isArray(data)) {
        this.options = data.map(item => {
          return {
            value: item[this.valueKey],
            label: item[this.labelKey],
            data: item
          };
        });
      } else {
        this.options = [];
      }
      this.loading = false;
    }
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>