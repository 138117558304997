<template>
  <div class="caseTop-wrap">
    <div class="caseTop">
      <div class="caseSuper">
        <img src="static/images/product/leftTri.png" />
        <h1>企业“一物一码”应用案例</h1>
        <img src="static/images/product/rightTri.png" />
      </div>
      <div class="codeText">
        产品身份证监管平台为全行业“一物一码”全场景应用赋能，打通企业全流程信息链，提升管理效率、提高智能生产、
        提供新零售与精准数据营销，最终解决企业在信息管理过程中重复建设的问题，大力降低企业数据管理操作成本，
        提升企业客户忠诚度，实现商品全生命周期数字化管理，为私域连接配备全新高性能、强动力的“新引擎
      </div>
      <div class="busniessTop">
        <div>
          <img src="static/images/busniessImg1.png" class="caseOne caseSame" />
          <div class="showCodeOne">
            <img src="static/images/code1.png" class="codeOneImg diffImg" />
            <p class="mentle"></p>
          </div>
          <p>安徽一朵菇</p>
        </div>
        <div>
          <img src="static/images/busniessImg2.png" class="caseTwo caseSame" />
          <div class="showCodeTwo">
            <img src="static/images/code2.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>蒲城葡萄</p>
        </div>
        <div>
          <img
            src="static/images/busniessImg3.png"
            class="caseThree caseSame"
          />
          <div class="showCodeThree">
            <img src="static/images/code3.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>积分活动</p>
        </div>
        <div>
          <img src="static/images/busniessImg4.png" class="caseFour caseSame" />
          <div class="showCodeFour">
            <img src="static/images/code4.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>抵扣券活动</p>
        </div>
        <div>
          <img src="static/images/busniessImg5.png" class="caseFive caseSame" />
          <div class="showCodeFive">
            <img src="static/images/code5.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>张小泉</p>
        </div>
        <div>
          <img src="static/images/busniessImg6.png" class="caseSix caseSame" />
          <div class="showCodeSix">
            <img src="static/images/code6.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>英童乳业</p>
        </div>
        <div>
          <img
            src="static/images/busniessImg7.png"
            class="caseSeven caseSame"
          />
          <div class="showCodeSeven">
            <img src="static/images/code7.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>蜘蛛王儿童健康科技有限公司</p>
        </div>
        <div>
          <img
            src="static/images/busniessImg8.png"
            class="caseEight caseSame"
          />
          <div class="showCodeEight">
            <img src="static/images/code8.png" class="codeOneImg" />
            <p class="mentle"></p>
          </div>
          <p>微信红包活动</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.caseTop-wrap {
  h1 {
    position: relative;
    z-index: 9;
  }
  .busniessTop > div {
    position: relative;
    z-index: 1;
  }

  .caseSuper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 40px;
    h1 {
      padding: 0 15px;
    }
  }
  .codeText {
    font-size: 18px;
    color: #8e8e8e;
    line-height: 34px;
    text-align: left;
    padding-bottom: 40px;
    width: 1200px;
    margin: 0 auto;
  }

  .caseSame {
    width: 276px;
    height: 470px;
  }

  .showCodeOne,
  .showCodeTwo,
  .showCodeThree,
  .showCodeFour,
  .showCodeFive,
  .showCodeSix,
  .showCodeSeven,
  .showCodeEight {
    display: block;
    pointer-events: none;
  }

  .hover {
    .showCodeOne,
    .showCodeTwo,
    .showCodeThree,
    .showCodeFour,
    .showCodeFive,
    .showCodeSix,
    .showCodeSeven,
    .showCodeEight {
      display: none;
    }
  }

  .mentle {
    width: 247px;
    height: 440px;
    position: absolute;
    top: 15px;
    left: 14px;
    background: black;
    opacity: 0.5;
    z-index: 10;
    border-radius: 7px;
  }

  .codeOneImg {
    z-index: 999;
    position: absolute;
    top: 160px;
    left: 60px;
    border-radius: 6px;
  }

  .topCase {
    background-image: url("../images/examplebanner.jpg");
    height: 450px;
    box-sizing: border-box;
    padding-top: 90px;
    font-family: Source Han Sans CN;
    background-size: cover;
    background-position: 50%;
  }
  .caseShowText {
    font-weight: 400;
    color: #ffffff;
    width: 1100px;
    margin: 0 auto;

    p:nth-child(1) {
      font-size: 22px;
      height: 52px;
    }

    span {
      height: 52px;
      font-size: 55px;
      display: inline-block;
      padding-right: 17px;
    }

    p:nth-child(2) {
      font-size: 16px;
      line-height: 35px;
      padding-top: 31px;
    }
  }

  .caseTop-wrap {
    background-color: #f6f7fa;
  }

  .caseTop {
    width: 1200px;
    > p {
      padding-top: 31px;
      padding-bottom: 20px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }

    margin: 0 auto 50px;
    text-align: center;

    .busniessTop {
      display: flex;
      width: 1200px;
      flex-wrap: wrap;
      justify-content: space-around;
      > div:hover > div {
        display: none;
      }
    }
  }

  .channelCase {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    > p:nth-child(1) {
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }

    .channelContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 1200px;
      text-align: center;

      > div {
        position: relative;
        margin-bottom: 15px;
      }

      p {
        position: absolute;
        bottom: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .textOne {
        left: 155px;
      }

      .textTwo {
        left: 151px;
      }

      .textThree {
        left: 142px;
      }

      .textFour {
        left: 176px;
      }

      .textFive {
        left: 141px;
      }

      .textSix {
        left: 176px;
      }

      img {
        width: 380px;
      }
    }
  }
}
</style>
