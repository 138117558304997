<template>
  <div class="product-carbon">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>基于产品一物一码结合碳排放应用的碳标签</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      碳标签(Carbon
      Labelling)是为了缓解气候变化，减少温室气体排放，推广低碳排放技术，
      把商品在生产过程中所排放的温室气体排放量在产品标签上用量化的指数标示出来，以标签的形式告知消费者产品的碳信息。
    </div>
    <div class="provideCont">
      <div class="provideCont-top govenProvide">
        <h1>为政府提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <img src="static/images/product/carbon1.png" />
    </div>
    <div class="provideCont">
      <div class="provideCont-top orgProvide">
        <h1>为企业主体提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <img src="static/images/product/carbon2.png" />
    </div>
    <div class="provideCont">
      <div class="provideCont-top advProvide">
        <h1>能力优势</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <img src="static/images/product/carbon3.png" />
    </div>
    <div class="provideCont">
      <div class="provideCont-top seeData">
        <h1>可视化数据监控驾驶舱</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
      </div>
      <img src="static/images/product/carbon4.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
