<template>
  <div class="login-main">
    <div class="cut-login">
      <span :class="{ 'code-msg': true, active: codeLogin }" @click="cutFrom(true)">短信登录</span>
      <span :class="{ 'passWord-msg': true, active: !codeLogin }" @click="cutFrom(false)"
        >密码登录</span
      >
      <i :class="{ activeStyle: true, towardsRight: !codeLogin }"></i>
    </div>
    <el-alert
      v-if="showError"
      :title="loginError"
      type="error"
      class="errorText"
      :closable="false"
      show-icon
    >
    </el-alert>
    <el-form
      :model="form1"
      class="demo-form-inline"
      :rules="rules1"
      ref="form1"
      key="form1"
      v-if="!codeLogin"
    >
      <el-form-item prop="account">
        <el-input
          :maxlength="11"
          v-model="form1.account"
          @input="clearFunc"
          placeholder="请输入手机号"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          :maxlength="16"
          v-model="form1.password"
          @input="clearFunc"
          placeholder="请输入密码"
          show-password
        />
      </el-form-item>
      <div class="login-btn login-system">
        <el-button type="primary" :loading="bstop" :disabled="bstop" @click="next('form1')"
          >登录</el-button
        >
      </div>
    </el-form>
    <el-form :model="form" class="demo-form-inline" :rules="rules" ref="form" key="form" v-else>
      <el-form-item prop="openTel">
        <el-input :maxlength="11" v-model="form.openTel" @input="clearFunc" placeholder="手机号" />
      </el-form-item>
      <el-form-item prop="openCode" class="send-code">
        <el-input :maxlength="6" v-model="form.openCode" @input="clearFunc" placeholder="验证码" />
        <el-button class="send-btn" :disabled="beingSent" @click="sendCodeFunc()">{{
          sendText
        }}</el-button>
      </el-form-item>
      <div class="login-btn">
        <el-button type="primary" :loading="bstop" :disabled="bstop" @click="next('form')"
          >登录</el-button
        >
      </div>
    </el-form>
    <div class="login-cut">
      <div class="cut-experience">
        <a
          style="cursor: pointer"
          @click="jumpPage('/changePassword.html')"
          class="forget-passWord"
          v-show="!codeLogin"
          >忘记密码</a
        >
        <a style="cursor: pointer" @click="jumpPage('/experienceLogin.html')">免费体验</a>
      </div>
    </div>
  </div>
</template>
<script>
import { sendCode, webLogin, openStatus, cancelOrder } from "src/static/services/website";
import { skipFunc } from "src/static/utils/webskipUtils.js";
import { ClickTrack, PVTrack } from "../../../utils/eventTrack";
import md5 from "md5";
import { jumpPage } from "src/static/utils/utils.js";

export default {
  data() {
    return {
      jumpPage,
      bstop: false,
      codeLogin: true,
      timer: null,
      time: 60,
      sendText: "获取验证码",
      beingSent: false,
      showError: false,
      loginError: "",
      form1: {
        account: "",
        password: "",
      },
      rules1: {
        account: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请填写正确的手机号",
            trigger: "blur",
          },
        ],
        // password: [{ required: true, message: "该项为必填项", trigger: "blur" },
        //   {pattern: /^[0-9a-zA-Z]{6,16}$/,message: '密码格式有误',trigger: 'blur'}],
        password: [{ required: true, message: "该项为必填项", trigger: "blur" }],
      },
      form: {
        openTel: "",
        openCode: "",
      },
      rules: {
        openTel: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请填写正确的手机号",
            trigger: "blur",
          },
        ],
        openCode: [
          { required: true, message: "该项为必填项", trigger: "blur" },
          { pattern: /^.{6}$/, message: "请输入6位验证码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    PVTrack("/login.html");
  },
  methods: {
    clearFunc() {
      this.showError ? (this.showError = false) : "";
    },
    cutFrom(value) {
      this.loginError = "";
      this.showError = false;
      this.codeLogin = value;
      this.bstop = false;
    },
    validForm(formInline) {
      return new Promise((resolve) => {
        this.$refs[formInline].validate((valid) => {
          resolve(valid);
        });
      });
    },
    validItem(formName, itemName) {
      return new Promise((resolve) => {
        this.$refs[formName].validateField(itemName, (valid) => {
          resolve(valid);
        });
      });
    },
    async sendCodeFunc() {
      const valid = await this.validItem("form", "openTel");
      if (valid) {
        return;
      }
      this.beingSent = true;
      this.sendText = "倒计时" + this.time + "s";
      this.timer = setInterval(() => {
        this.time--;
        if (this.time === 0) {
          this.beingSent = false;
          this.time = 60;
          this.sendText = "获取验证码";
          clearInterval(this.timer);
        } else {
          this.sendText = "倒计时" + this.time + "s";
        }
      }, 1000);
      let res = await sendCode({ phoneNumber: this.form.openTel });
      if (res.state != 200) {
        this.beingSent = false;
        this.time = 60;
        this.sendText = "获取验证码";
        clearInterval(this.timer);
      }
    },
    async next(formInline) {
      ClickTrack("登录系统-登录", "点击", "登录");
      const valid = await this.validForm(formInline);
      if (!valid) {
        return;
      }
      this.bstop = true;
      if (formInline == "form") {
        this.loginFunc();
      } else {
        //密码登录
        this.passwordFunc();
      }
    },
    async loginFunc() {
      let propsData = {
        code: this.form.openCode,
        account: this.form.openTel,
      };
      const res = await webLogin(propsData);
      if (res.state == 200) {
        this.showError = false;
        localStorage.setItem('super-token', res.results.token);
        localStorage.setItem('phone', this.form.openTel);
        this.getStatus();
      } else {
        this.loginError = res.msg;
        this.showError = true;
        this.bstop = false;
      }
      this.beingSent = false;
      this.time = 60;
      this.sendText = "获取验证码";
      clearInterval(this.timer);
    },
    async getStatus() {
      const res = await openStatus();
      if (res.state == 200) {
        if (res.results.status == "1000") {
          ELEMENT.MessageBox.confirm("您的手机号已开通系统，可直接进入系统", {
            closeOnClickModal: false,
            cancelButtonText: "更换手机号",
            confirmButtonText: "进入我的超级码®云",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              this.form.openTel = "";
              this.form.openCode = "";
              this.form1.account = "";
              this.form1.password = "";
            });
        } else if (res.results.status == "-1") {
          ELEMENT.MessageBox.confirm("您的账号还未购买正式版系统，是否购买？", {
            closeOnClickModal: false,
            cancelButtonText: "更换手机号",
            confirmButtonText: "购买系统",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              this.form.openTel = "";
              this.form.openCode = "";
              this.form1.account = "";
              this.form1.password = "";
            });
        } else if (res.results.status == "700" || res.results.status == "800") {
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", {
            closeOnClickModal: false,
            cancelButtonText: "重新开始",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              this.cancelOrderFunc(res.results);
            });
        } else if (res.results.status == "999") {
          skipFunc(res.results);
        } else if (res.results.status == "a" || res.results.status == "600") {
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", {
            closeOnClickModal: false,
            confirmButtonText: "继续完成",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {});
        } else if (res.results.status == "900") {
          ELEMENT.MessageBox.confirm("您的系统已过期，是否续费？", {
            closeOnClickModal: false,
            cancelButtonText: "暂时不需要",
            confirmButtonText: "续费",
            showClose: false,
            center: true,
          })
            .then(() => {
              window.location.href = "renewalSystem.html";
            })
            .catch(() => {
              window.location.href = "platform.html";
            });
        } else if (res.results.status == "3") {
          ELEMENT.MessageBox.confirm("实名认证流程已冻结,请联系客服人员：18958077257", {
            closeOnClickModal: false,
            confirmButtonText: "我知道啦",
            showCancelButton: false,
            center: true,
          })
            .then(() => {
              window.location.href = "platform.html";
            })
            .catch(() => {});
        } else {
          ELEMENT.MessageBox.confirm("您的账号还有未完成的订单，是否继续？", {
            closeOnClickModal: false,
            cancelButtonText: "重新开始",
            confirmButtonText: "继续完成",
            showClose: false,
            center: true,
          })
            .then(() => {
              skipFunc(res.results);
            })
            .catch(() => {
              window.location.href = "chooseDeloy.html?type=changePackage";
            });
        }
      }
      this.bstop = false;
    },
    // 取消订单
    async cancelOrderFunc(results) {
      const res = await cancelOrder({ orderNo: results.value });
      if (res.state == 200) {
        window.location.href = "chooseDeloy.html?type=changePackage";
      }
    },
    async passwordFunc() {
      let propsData = {
        password: md5(this.form1.password),
        account: this.form1.account,
      };
      const res = await webLogin(propsData);
      if (res.state == 200) {
        localStorage.setItem('super-token', res.results.token);
        localStorage.setItem('phone', this.form1.account);
        this.getStatus();
      } else {
        this.loginError = res.msg;
        this.showError = true;
        this.bstop = false;
      }
    },
  },
  watch: {
    //监听输入
    form: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        this.form.openTel = val.openTel.replace(/\D/g, "");
        this.form.openCode = val.openCode.replace(/\D/g, "");
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.login-main {
  width: 402px;
  height: 440px;
  box-sizing: border-box;
  padding: 0 33px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 111;
  transform: translate(192px, -50%);
  background: rgba(255, 255, 255, 0.96);
  border-radius: 4px;
  .errorText {
    height: 41px;
    box-sizing: border-box;
    border: 1px solid #f05a3c;
    margin-top: -20px;
    margin-bottom: 16px;
  }
  .cut-login {
    display: flex;
    padding-top: 46px;
    padding-bottom: 22px;
    font-size: 20px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    margin-bottom: 36px;
    span {
      cursor: pointer;
      color: #999;
      margin-right: 40px;
      transition: color 0.3s;
    }
    span.active {
      color: #333;
    }
    .activeStyle {
      position: absolute;
      display: block;
      width: 80px;
      height: 2px;
      background: #008aff;
      left: 0px;
      bottom: -1px;
      transition: all 0.3s;
    }
    .activeStyle.towardsRight {
      left: 120px;
    }
  }
  .send-code {
    position: relative;
    .send-btn {
      border: none;
      position: absolute;
      background: none;
      right: 16px;
      top: 0;
      padding: 0;
      line-height: 40px;
      cursor: pointer;
      color: #008aff;
      user-select: none;
    }
  }
  .login-btn {
    margin: 40px auto 18px;
    button {
      width: 100%;
      height: 50px;
      font-size: 16px;
    }
  }
  .login-cut {
    height: 16px;
    margin-top: 24px;
    font-size: 14px;
    .cut-experience {
      float: right;
    }
    a,
    span {
      text-decoration: none;
      cursor: pointer;
      user-select: none;
    }
    .cut-experience a {
      color: #999;
      transition: color 0.3s;
    }
    .cut-experience a:hover {
      color: #008aff;
    }
    .forget-passWord {
      margin-right: 20px;
      position: relative;
    }
    .forget-passWord::after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      right: -13px;
      width: 1px;
      height: 14px;
      background: #bfbfbf;
    }
  }
}
</style>
