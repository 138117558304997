<template>
  <div class="product-wine">
    <div class="productSuper">
      <img src="static/images/product/leftTri.png" />
      <h1>从一粒粮食到一瓶美酒的全生命周期数字化解决方案</h1>
      <img src="static/images/product/rightTri.png" />
    </div>
    <div class="codeText">
      该框架以白酒溯源基因为核心脉络，以物联网、区块链、数据支撑平台以及人工智能分析等领先的数字化技术为支撑，完成从一滴美酒向一粒种子的反向追溯，
      此外，公司对农业、包材、生产、流通四大模块中全流程的信息和数据进行采集，并用信息化手段进行监控、
      可视化与分析，以点至线，以线及面，对质量溯源进行全方位管控。同时，公司建立企业级溯源可视化门户，实现企业级溯源分析与可视化，同时可提供公众查询服务。
    </div>
    <div class="provideCont provideWine">
      <div class="provideCont-top">
        <h1>为酒类企业提供</h1>
        <img src="static/images/product/blueBg.png" class="blueBg" />
        <div class="topText">“1+1+1+N”模式产品质量可追溯体系</div>
      </div>
      <img src="static/images/product/wine1.png" />
    </div>
    <div class="customer">
      <div class="provideCont">
        <div class="provideCont-top peopleProvide">
          <h1>为消费者提供</h1>
          <img src="static/images/product/blueBg.png" class="blueBg" />
          <div class="topText">
            产品原料、生产、质检、流通、防伪等多维度信息
          </div>
        </div>
        <img src="static/images/product/wine2.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
